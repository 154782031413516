import katex from "katex"
import { extractMath } from "./extractMath"
import { useMemo } from "react"
import { clearLatex } from "../util/clearLatex"
import { Html } from "../util/Html"

interface KatexProps extends React.HTMLAttributes<any> {
    content: string
    htmlRef?: React.RefObject<any>
}

export function Katex({ content, htmlRef, ...elementProps }: KatexProps) {
    const allHtml = useMemo(() => {
        const clearContent = clearLatex(content)
        const segments = extractMath(clearContent)

        return segments
            .map(seg => {
                if (seg.math) {
                    const el = document.createElement("span")
                    el.innerHTML = seg.raw
                    const raw = el.textContent

                    let katexElement
                    try {
                        katexElement = katex.renderToString(raw, {
                            displayMode: seg.type === "display"
                        })
                    } catch (e) {
                        katexElement = raw
                    }

                    return katexElement
                } else {
                    return seg.value
                }
            })
            .reduce((total, current) => {
                return (total += current)
            })
    }, [content])

    return <Html content={allHtml} {...elementProps} htmlRef={htmlRef} />
}
