import { Button, ListItem, ListItemButton } from "@mui/material"
import { styled } from "@mui/material/styles"
import { GridColDef, GridRenderCellParams, GridToolbar, esES } from "@mui/x-data-grid"
import { useClonadorApi } from "@puntaje/clonador/api-services"
import { CUIButton, CUIDataList, CUIDataTable, CUIIconButton, CUILoader, CUITooltip } from "@puntaje/react/shared/cui"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Link } from "react-router-dom"
import { Logged } from "../layouts/Logged"
import VisibilityIcon from "@mui/icons-material/Visibility"
import EditIcon from "@mui/icons-material/Edit"
import UploadIcon from "@mui/icons-material/Upload"
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated"

const BreakSpace = styled("div")(({ theme }) => ({
    whiteSpace: "break-spaces"
}))

export function Configuraciones() {
    const { configuracionesService } = useClonadorApi()
    const { data, isLoading } = useQuery(["configuraciones"], () => configuracionesService.whereWithoutCount())
    const mutation = useMutation((configuracionId: number) => {
        return configuracionesService
            .crearApi(configuracionId)
            .then(res => {
                if (res.api_state !== "En cola") {
                    alert("Hubo un problema al intentar crear la API.")
                } else alert("La API se encuentra en cola para ser creada.")
            })
            .catch(error => {
                alert("Hubo un problema al intentar crear la API.")
            })
    })

    const deployMutation = useMutation((configuracionId: number) => {
        return configuracionesService
            .deployar(configuracionId)
            .then(res => {
                if (res.success) {
                    alert("La aplicación ha sido deployada.")
                } else {
                    alert("Hubo un problema al intentar deployar la aplicación.")
                }
            })
            .catch(error => {
                alert("Hubo un problema al intentar deployar la aplicación.")
            })
    })

    const columns: GridColDef[] = [
        {
            field: "plataforma",
            headerName: "Plataforma",
            width: 220
        },
        {
            field: "configurable",
            headerName: "Configurable",
            type: "boolean"
        },
        {
            field: "pais",
            headerName: "País",
            valueGetter: (params: GridRenderCellParams) => {
                return params.row.configuracion.pais
            }
        },
        {
            field: "tipo_api",
            headerName: "Tipo API",
            valueGetter: (params: GridRenderCellParams) => {
                const pais = params.row["configuracion"]["pais"] ?? "sin pais"

                return params.row["api_state"] ? "Propia" : "Clones (" + pais + ")"
            },
            width: 150
        },
        {
            field: "api_state",
            headerName: "API State"
        },
        {
            field: "created_at",
            headerName: "Creación",
            renderCell: (params: GridRenderCellParams) => {
                const options: any = {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                }

                const localeDate = new Date(params.value).toLocaleDateString("es-ES", options)

                return <BreakSpace>{localeDate}</BreakSpace>
            },
            width: 280
        },
        {
            field: "acciones",
            headerName: "Acciones",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <>
                        <CUITooltip title="Editar">
                            <CUIIconButton component={Link} to={`/configuraciones/${params.row.id}/edit`}>
                                <EditIcon />
                            </CUIIconButton>
                        </CUITooltip>
                        {/* <CUITooltip title="Crear API">
                            <CUIIconButton
                                onClick={e => {
                                    mutation.mutate(params.row.id)
                                }}
                            >
                                <UploadIcon />
                            </CUIIconButton>
                        </CUITooltip> */}
                        <CUITooltip title="Deployar (solo si se cambiaron los favicons o se creó api y estos detonaron un deploy que falló en la APP de deploys)">
                            <CUIIconButton onClick={e => deployMutation.mutate(params.row.id)}>
                                <BrowserUpdatedIcon />
                            </CUIIconButton>
                        </CUITooltip>
                    </>
                )
            },
            flex: 1
        }
    ]

    return (
        <Logged title="Configuraciones">
            <CUIButton component={Link} to={"/configuraciones/new"}>
                Crear nuevo
            </CUIButton>
            <br /> <br />
            <CUILoader
                loading={isLoading}
                render={() => (
                    <CUIDataTable
                        rows={data}
                        columns={columns}
                        autoHeight
                        hideFooter
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        components={{ Toolbar: GridToolbar }}
                    />
                )}
            />
        </Logged>
    )
}
