import { ChangeEvent, KeyboardEvent, MouseEvent, useContext, useState } from "react"
import { Editor, Element as SlateElement, Range, Transforms } from "slate"
import { useSlate } from "slate-react"
import { Stack } from "@mui/material"

import { CUIDialog, CUIDialogActions, CUIDialogContent, CUIDialogTitle } from "../../Feedback"
import CUIButton from "../CUIButton"
import { CUIText } from "../CUIText"
import { EditorContext, FieldType, IEditorContext } from "./EditorProvider"
import { Button, Icon } from "./UI"
import { getElements } from "./utils"

import { CustomEditor, LinkElement } from "../custom-types"

const isLinkActive = (editor: CustomEditor): boolean => {
    const [link] = getElements(editor, "link")
    return !!link
}

const unwrapLink = (editor: CustomEditor) => {
    Transforms.unwrapNodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link"
    })
}

export const wrapLink = (
    editor: CustomEditor,
    url: string,
    {
        text = url,
        edit = false,
        editText = true,
        expand = true
    }: { text?: string; edit?: boolean; editText?: boolean; expand?: boolean }
) => {
    const [linkEntry] = getElements(editor, "link")

    let linkRange: Range

    if (linkEntry) {
        if (expand) {
            const linkPath = linkEntry[1]
            linkRange = Editor.range(editor, linkPath)
            Transforms.select(editor, linkRange)
        }

        if (edit && editText) {
            Transforms.removeNodes(editor, {
                match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link"
            })
        } else {
            unwrapLink(editor)
        }
    }

    const { selection } = editor
    const isCollapsed = selection && Range.isCollapsed(selection)

    const link: LinkElement = {
        type: "link",
        url,
        children: isCollapsed || edit ? [{ text }] : []
    }

    if ((isCollapsed && !edit) || (edit && editText)) {
        Transforms.insertNodes(editor, link)
    } else {
        Transforms.wrapNodes(editor, link, { split: true })
        Transforms.collapse(editor, { edge: "end" })
    }
}

export const LinkButton = (): JSX.Element => {
    const [isTextChanged, setIsTextChanged] = useState<boolean>(false)

    const { state, actions } = useContext<IEditorContext>(EditorContext)
    const { openLinkDialog, closeDialog, setField } = actions

    const { isOpenLinkDialog, linkText, linkUrl, isEditingLink } = state

    const editor = useSlate()

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target } = event
        setField(target.name as FieldType, target.value)
        setIsTextChanged(true)
    }

    const handleOnClose = () => closeDialog("Link")

    const handleAccept = (event: MouseEvent<HTMLSpanElement> | KeyboardEvent<HTMLInputElement>) => {
        event.preventDefault()

        if (linkUrl) {
            let fixedUrl = linkUrl
            if (!fixedUrl.startsWith("http") && !fixedUrl.startsWith("#")) {
                fixedUrl = "http://" + fixedUrl
            }

            wrapLink(editor, fixedUrl, { text: linkText, edit: isEditingLink, editText: isTextChanged })
            handleOnClose()
        }
    }

    return (
        <>
            <Button active={isLinkActive(editor)} onMouseDown={openLinkDialog}>
                <Icon title="Agregar link">link</Icon>
            </Button>
            <CUIDialog onClose={handleOnClose} open={isOpenLinkDialog}>
                <CUIDialogTitle onClose={handleOnClose}>Añadir Link</CUIDialogTitle>
                <CUIDialogContent>
                    <Stack direction="column" sx={{ p: 2 }}>
                        <CUIText
                            autoFocus
                            name="linkText"
                            value={linkText}
                            label="Ingresar texto"
                            placeholder="ej: más información"
                            onChange={handleOnChange}
                            onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                                if (event.key === "Enter") {
                                    handleAccept(event)
                                }
                            }}
                            margin="dense"
                        />
                        <CUIText
                            name="linkUrl"
                            value={linkUrl}
                            placeholder="https://..."
                            label="Ingresar url"
                            onChange={handleOnChange}
                            onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                                if (event.key === "Enter") {
                                    handleAccept(event)
                                }
                            }}
                            margin="dense"
                        />
                    </Stack>
                </CUIDialogContent>
                <CUIDialogActions>
                    <CUIButton onMouseDown={handleOnClose}>Cancelar</CUIButton>
                    <CUIButton onMouseDown={handleAccept}>Aceptar</CUIButton>
                </CUIDialogActions>
            </CUIDialog>
        </>
    )
}

export const EditorLink = ({ children, attributes, baseAttributes = {}, element }) => {
    const { state, actions } = useContext(EditorContext)
    const url = element.url || ""

    return (
        <a {...baseAttributes} {...attributes} href={url} onDoubleClick={actions.openLinkDialog}>
            {children}
        </a>
    )
}

export const RemoveLinkButton = () => {
    const editor = useSlate()

    const handleClick = event => {
        event.preventDefault()

        unwrapLink(editor)
    }

    return (
        <Button active={isLinkActive(editor)} onMouseDown={handleClick}>
            <Icon title="Remover link">link_off</Icon>
        </Button>
    )
}
