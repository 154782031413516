import { MouseEvent } from "react"
import { Editor } from "slate"
import { useSlate } from "slate-react"
import { CustomEditor } from "../custom-types"
import { Button, Icon } from "./UI"

interface IProps {
    format: string
    icon: string
    title: string
}
export const MarkButton = ({ format, icon, title }: IProps) => {
    const editor = useSlate()

    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={(event: MouseEvent<HTMLSpanElement>) => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <Icon title={title}>{icon}</Icon>
        </Button>
    )
}

export const isMarkActive = (editor: CustomEditor, format: string) => {
    const defaultMarkData = {
        fontSize: "16px",
        fontFamily: "Lato, Roboto, Helvetica Neue, Arial, sans-serif"
    }

    const marks = Editor.marks(editor)
    const defaultValue = defaultMarkData[format]
    return marks?.[format] ?? defaultValue
}

export const toggleMark = (editor: CustomEditor, format: string) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}
