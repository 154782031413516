import { SessionData, SessionProvider } from "@puntaje/react/shared/core"
import { useCallback } from "react"
import { ClonadorSession } from "./ClonadorSession"
import { ClonadorTokens, ClonadorUsuarioSession } from "./ClonadorUsuarioSession"

export function ClonadorSessionProvider({ children }: { children: React.ReactNode }) {
    const sessionFactory = useCallback(
        (sessionData: SessionData<ClonadorUsuarioSession, ClonadorTokens>, setSessionData) => {
            return new ClonadorSession(sessionData, setSessionData)
        },
        []
    )

    return <SessionProvider sessionFactory={sessionFactory}>{children}</SessionProvider>
}
