import React, { createContext, useContext } from "react"

export interface CUIContentLayoutOptions {
    boxedContent: boolean
}

export interface CUIContentLayoutProviderProps {
    children: React.ReactNode
    contentLayoutOptions: CUIContentLayoutOptions
}

export const CUIContentLayoutContext = createContext<CUIContentLayoutOptions>(null)

export function useContentLayoutOptions() {
    return useContext(CUIContentLayoutContext)
}

export function CUIContentLayoutProvider(props: CUIContentLayoutProviderProps) {
    return (
        <CUIContentLayoutContext.Provider value={props.contentLayoutOptions}>
            {props.children}
        </CUIContentLayoutContext.Provider>
    )
}
