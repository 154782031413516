import Table, { TableProps } from "@mui/material/Table"
import TableContainer, { TableContainerProps } from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import { CUITableContext } from "./CUITableContext"
import React, { ElementType } from "react"
import { styled } from "@mui/material"
import CUITableHead from "./CUITableHead"

const TableWithResponsive = styled(Table, {
    shouldForwardProp: prop => prop !== "enableResponsive" && prop !== "stripped" && prop !== "containerProps"
})<{
    enableResponsive: boolean
    stripped?: boolean
}>(({ theme, enableResponsive, stripped }) => ({
    ...(enableResponsive && {
        [theme.breakpoints.down("sm")]: {
            "& > thead > tr": {
                display: "none"
            },
            "& > tbody > tr > td": {
                padding: theme.spacing(1, 2),
                display: "block",
                marginLeft: "120px"
            },
            "& > tbody > tr > td::after": {
                display: "block",
                content: '""',
                clear: "both"
            },
            "& > tbody > tr > td > .resp-th": {
                display: "inline-block",
                float: "left",
                width: "104px",
                marginLeft: "-120px",
                fontWeight: "bold",
                textAlign: "left"
            }
        }
    }),
    ...(stripped && {
        "& > tbody > tr:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover
        }
    }),
    "& .resp-th": {
        display: "none"
    }
}))

export interface CUITableProps extends TableProps {
    component?: ElementType<unknown>

    /**
     * Si es true, la tabla va a ser responsive poniendo los headers en cada celda
     *
     * @default true
     */
    enableResponsive?: boolean

    /**
     * Si es true, la tabla va a tener un background gris alternado
     */
    stripped?: boolean

    /** Props que se quieran pasar al TableContainer */
    containerProps?: TableContainerProps

    containerRef?: any
}

export function CUITable(props: CUITableProps) {
    const { children, component = Paper, enableResponsive = true, containerProps, containerRef, ...restProps } = props

    // Esto es para pasarle los headers a los CUITableCell y que sea responsive
    const headers = enableResponsive
        ? React.Children.toArray(
              (
                  React.Children.toArray(
                      (React.Children.toArray(children)[0] as React.ReactElement).props.children
                  )[0] as React.ReactElement
              ).props.children
          ).map(child => {
              return (child as React.ReactElement).props.children
          })
        : []

    return (
        <CUITableContext.Provider value={{ headers }}>
            <TableContainer component={component} {...containerProps} {...{ ref: containerRef }}>
                <TableWithResponsive enableResponsive={enableResponsive} {...restProps}>
                    {children}
                </TableWithResponsive>
            </TableContainer>
        </CUITableContext.Provider>
    )
}

export default CUITable
