import { useTranslateLib } from "../i18n/useTranslateLib"

/**
 * Hook que retorna una función que retorna un mensaje de error si el valor es mayor al máximo de caracteres
 *
 * @returns Objeto con la función `maxLengthError` que retorna un mensaje de error si el valor es mayor al máximo de caracteres
 */
export function useMaxLengthError() {
    const { t } = useTranslateLib("use_max_length_error")

    return {
        maxLengthError: (label: string, max: number | string) => t("max_length_error", { label, max })
    }
}
