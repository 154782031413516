import { ControllerFieldState, ControllerRenderProps, FieldValues } from "react-hook-form"

export const useCUIDatePickerFormProps = (
    {
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
    }: {
        field: ControllerRenderProps<FieldValues, string>
        fieldState: ControllerFieldState
    },
    inputBuilder
) => {
    return {
        ...inputBuilder.componentProps,
        value: value ?? "",
        label: inputBuilder.label,
        onChange,
        id: name,
        inputProps: {
            onBlur
        },
        ref,
        cuiTextProps: {
            ...inputBuilder.componentProps?.cuiTextProps,
            error: error != undefined || undefined,
            helperText:
                error != undefined ? error.message : inputBuilder.componentProps?.cuiTextProps?.helperText ?? undefined
        }
    }
}
