export class Navigations {
    static get alumnos(): any[] {
        return [
            {
                name: "base_topmenu.alumno.blog",
                clonadorName: "Blog",
                route: "/blog",
                icon: "libro2-o",
                selected: true
            },
            {
                name: "base_topmenu.alumno.ejercitar",
                clonadorName: "Ejercitar",
                route: "/pruebas/generar_prueba",
                icon: "editar-prueba",
                selected: true
            },
            {
                name: "base_topmenu.alumno.practica",
                clonadorName: "Practica",
                route: "/evaluaciones/recomendadas",
                icon: "prueba",
                selected: true
            },
            {
                name: "base_topmenu.alumno.resultados",
                clonadorName: "Resultados",
                route: "/pruebas/resultados",
                icon: "resultados",
                selected: true
            },
            {
                name: "base_topmenu.alumno.planes_adaptativos",
                clonadorName: "Planes Adaptativos",
                route: "/planes_personales",
                icon: "planificacion-o",
                selected: true
            },
            {
                name: "base_topmenu.alumno.planes_de_clase",
                clonadorName: "Planes de Clase",
                route: "/plan_clases",
                icon: "planificacion-o",
                selected: true
            },
            {
                name: "base_topmenu.alumno.dudas_curso",
                clonadorName: "Dudas del Curso",
                route: "/dudas",
                icon: "dialogos",
                params: {
                    show_cursos: 1
                },
                selected: true
            },
            {
                name: "base_topmenu.alumno.comunidad",
                clonadorName: "Comunidad",
                route: "/dudas_categorias",
                icon: "dialogos",
                selected: true
            },
            {
                name: "base_topmenu.alumno.centro_ayuda",
                clonadorName: "Centro de Ayuda",
                route: "/ayudas",
                icon: "dialogos",
                selected: true
            },
            {
                name: "base_topmenu.alumno.biblioteca",
                clonadorName: "Biblioteca",
                route: "/biblioteca",
                icon: "dialogos",
                selected: false
            },
            {
                name: "base_topmenu.alumno.carreras",
                clonadorName: "Carreras",
                route: "/mis_carreras",
                icon: "establecimiento-o",
                selected: true
            }
        ]
    }

    static get profesores(): any[] {
        return [
            {
                name: "base_topmenu.profe.blog",
                clonadorName: "Blog",
                route: "/blog",
                icon: "libro2-o",
                selected: true
            },
            {
                name: "base_topmenu.profe.evaluar",
                clonadorName: "Evaluar",
                route: "/pruebas/generar_prueba",
                icon: "editar-prueba",
                selected: true
            },
            {
                name: "base_topmenu.profe.corregir",
                clonadorName: "Corregir",
                route: "/evaluacion_instancias/subir",
                icon: "upload",
                selected: true
            },
            {
                name: "base_topmenu.profe.resultados",
                clonadorName: "Resultados",
                route: "/evaluaciones/resultados",
                icon: "resultados",
                selected: true
            },
            {
                name: "base_topmenu.profe.planes_de_clase",
                clonadorName: "Planes de Clase",
                route: "/plan_clases",
                icon: "planificacion-o",
                selected: true
            },
            {
                name: "base_topmenu.profe.mis_noticias",
                clonadorName: "Mis Noticias",
                route: "/noticias",
                icon: "megafono-o",
                selected: true
            },
            {
                name: "base_topmenu.profe.planes_clase_predefinido",
                clonadorName: "Planes de clase predefinidos",
                route: "/plan_clases_predefinidos",
                icon: "planificacion-o",
                selected: true
            },
            {
                name: "base_topmenu.profe.comunidad",
                clonadorName: "Comunidad",
                route: "/dudas_categorias",
                icon: "dialogos",
                selected: true
            },
            {
                name: "base_topmenu.profe.centro_ayuda",
                clonadorName: "Centro de Ayuda",
                route: "/ayudas",
                icon: "dialogos",
                selected: true
            },
            {
                name: "base_topmenu.profe.resumen_uso",
                clonadorName: "Resumen de Uso",
                route: "/estadisticas/resumen_uso",
                icon: "resultados",
                selected: true
            }
        ]
    }
}
