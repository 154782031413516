import { ApiOptions } from "../api/ApiInterfaces"
import { BasicApi } from "../api/BasicApi"

export type S3PolicyType =
    | {
          AWSAccessKeyId: string
          "Content-Type": string
          acl: string
          key: string
          policy: string
          signature: string
      }
    | {
          error: string
      }

export interface S3PolicyParams {
    key: string
    model_name: string
    extra_params?: any
}

export class S3Policy extends BasicApi<S3PolicyType> {
    constructor(baseUrl: string, token: string, options: ApiOptions = {}) {
        super(baseUrl, token, "s3_policy", "s3_policy", options)
    }

    public getPolicy(params: S3PolicyParams) {
        return this.all().get(params)
    }
}
