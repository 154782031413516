import { CUISelect } from "./CUISelect"
import { useMemo, useRef, useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import { useOptionsForm } from "./useOptionsForm"
import { ControllerFieldState, ControllerRenderProps, FieldValues } from "react-hook-form"

/**
 * Función que devuelve los props para el componente CUIAutocomplete basado en los props de react-hook-form.
 *
 * @param param0 @type {field: ControllerRenderProps<FieldValues, string>, fieldState: ControllerFieldState} Objeto con los props de react-hook-form
 * @param inputBuilder Objeto que representa la forma en que se construye el input
 * @returns Props de CUIAutocomplete
 */
export const useCUIAutocompleteFormProps = (
    {
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
    }: {
        field: ControllerRenderProps<FieldValues, string>
        fieldState: ControllerFieldState
    },
    inputBuilder
) => {
    const { query, setInputValue, selectedOption, setSelectedOption } = useOptionsForm(name, value, inputBuilder, false)

    const options = query
        ? query.data
            ? selectedOption
                ? [selectedOption, ...query.data.filter(option => option.id !== selectedOption.id)]
                : query.data
            : selectedOption
            ? [selectedOption]
            : []
        : inputBuilder.options
        ? inputBuilder.options
        : []

    const loading = query ? query.isLoading : false

    const timeoutIdRef = useRef<any>()

    return {
        ...inputBuilder.componentProps,
        cuiTextProps: {
            ...inputBuilder.componentProps?.cuiTextProps,
            label: inputBuilder.label,
            onBlur,
            ref,
            id: name,
            error: error != undefined || undefined,
            helperText: error != undefined ? error.message : undefined
        },
        cuiTextPropsFunc: props => {
            const originalFuncProps = inputBuilder.componentProps?.cuiTextPropsFunc?.(props)

            return {
                ...originalFuncProps,
                InputProps: {
                    ...props.InputProps,
                    ...originalFuncProps?.InputProps,
                    endAdornment: (
                        <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {props.InputProps.endAdornment}
                        </>
                    )
                }
            }
        },
        onChange: (event, newValue) => {
            setSelectedOption(newValue)
            onChange(
                inputBuilder.mapOption
                    ? inputBuilder.mapOption(newValue, value)
                    : inputBuilder.emitId
                    ? newValue?.id ?? null
                    : newValue
            )
        },
        onInputChange: query
            ? (event, newInputValue, reason) => {
                  if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)

                  timeoutIdRef.current = setTimeout(() => {
                      setInputValue(newInputValue)
                  }, 500)
              }
            : undefined,
        ...(!inputBuilder.componentProps?.filterOptions &&
            query &&
            !inputBuilder.simpleQueryKey && {
                filterOptions: x => x
            }),
        value: inputBuilder.emitId
            ? options.find(option => option.id === value) ?? ""
            : inputBuilder.mapOption && value
            ? options.find(option =>
                  inputBuilder.componentProps?.isOptionEqualToValue
                      ? inputBuilder.componentProps?.isOptionEqualToValue(option, value)
                      : option.id === value.id
              ) ?? ""
            : value ?? (inputBuilder.componentProps.multiple ? [] : ""),
        options,
        loading
    }
}
