import { useId } from "react"
import { ControllerFieldState, ControllerRenderProps, FieldValues } from "react-hook-form"

export const useCUIFileFormProps = (
    {
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
    }: {
        field: ControllerRenderProps<FieldValues, string>
        fieldState: ControllerFieldState
    },
    inputBuilder
) => {
    const labelId = useId()

    return {
        ...inputBuilder.componentProps,
        labelProps: {
            ...inputBuilder.componentProps.labelProps,
            label: inputBuilder.label,
            InputLabelProps: {
                htmlFor: name
            },
            id: labelId,
            helperText: error?.message ?? inputBuilder.componentProps?.labelProps?.helperText,
            error: error != undefined || undefined
        },
        filePath: value ?? "",
        onChange,
        id: name,
        ref
    }
}
