import styled from "@emotion/styled"

const BaseTopmenu = styled.div`
    ul {
        list-style: none;
        padding-left: 0;
        background-color: var(--base-topmenu_background_color);
        font-size: 18px;
        cursor: pointer;
        min-width: 300px;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    }
    li {
        padding: 10px 16px;
        transition: all ease 0.3s;
        color: var(--base-topmenu_font_color);
        &:hover {
            background-color: var(--base-topmenu_background_color_hover);
            color: var(--base-topmenu_font_color_hover);
        }
    }
`

/* eslint-disable-next-line */
export interface BaseTopmenuPreviewProps {}

export function BaseTopmenuPreview(props: BaseTopmenuPreviewProps) {
    return (
        <BaseTopmenu>
            <ul>
                <li>Home</li>
                <li>Evaluaciones</li>
                <li>Otra cosa</li>
            </ul>
        </BaseTopmenu>
    )
}

export default BaseTopmenuPreview
