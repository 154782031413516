import { useClonadorApi } from "@puntaje/clonador/api-services"
import { cleanFileNameForUpload, uploadFileS3Builder } from "@puntaje/react/shared/core"
import { CUIFileUpload, CUIFileUploadProps } from "@puntaje/react/shared/cui"
import { forwardRef } from "react"

type FileS3Props = Omit<CUIFileUploadProps, "onFileChange"> & {
    /**
     * Nombre del modelo al que pertenece el archivo.
     *
     * Ejemplo: `Pregunta`, `Material`
     */
    modelName: string

    /**
     * Parámetros extras que pueden ser necesarios en la API para subir el archivo.
     */
    extraParams?: any
}

interface FileS3Type {
    (props: FileS3Props, ref: React.Ref<HTMLInputElement>): JSX.Element

    cuiComponentName: "CUIFileUpload"
}

/**
 * Wrapper de CUIFileUpload para subir un archivo a S3.
 */
const FileS3Internal = forwardRef(
    ({ modelName, extraParams, ...cuiFileUploadProps }: FileS3Props, ref: React.Ref<HTMLInputElement>) => {
        const { s3PolicyService } = useClonadorApi()

        const onFileChange = uploadFileS3Builder({
            s3BucketUrl: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded",
            getS3Policy: file => {
                return s3PolicyService.getPolicy({
                    model_name: modelName,
                    key: cleanFileNameForUpload(file.name),
                    extra_params: extraParams
                })
            }
        })

        return <CUIFileUpload {...cuiFileUploadProps} onFileChange={onFileChange} ref={ref} />
    }
)

;(FileS3Internal as any).cuiComponentName = "CUIFileUpload"

export const FileS3: FileS3Type = FileS3Internal as unknown as FileS3Type
