export const useCUICheckboxFormProps = ({ field: { onChange, onBlur, value, name, ref } }, inputBuilder) => {
    return {
        ...inputBuilder.componentProps,
        checked: value ?? false,
        controlLabelProps: {
            label: inputBuilder.label
        },
        onChange,
        id: name,
        inputProps: {
            onBlur
        },
        ref
    }
}
