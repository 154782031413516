import styled from "@emotion/styled"

import { Route, Routes, Link } from "react-router-dom"
import { Login } from "./routes/Login"

import { environment } from "../environments/environment"
import { ConfigProvider, EnvironmentProvider } from "@puntaje/react/shared/core"
import { ClonadorAuthGuard, ClonadorSessionProvider } from "@puntaje/clonador/core"
import { ConfiguracionNew } from "./routes/ConfiguracionNew"
import { Configuraciones } from "./routes/Configuraciones"
import { ConfiguracionEdit } from "./routes/ConfiguracionEdit"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { createTheme, ThemeProvider } from "@mui/material"
import { GoogleOAuthProvider } from "@react-oauth/google"

const queryClient = new QueryClient()

const StyledApp = styled.div`
    // Your style here
`

const theme = createTheme({
    palette: {
        neutral: {
            main: "#64748B",
            contrastText: "#fff"
        }
    }
})

const config = {
    plataforma: {
        locale: "es"
    }
}

export function App() {
    return (
        <GoogleOAuthProvider clientId={environment.googleAuthClientId}>
            <QueryClientProvider client={queryClient}>
                <EnvironmentProvider environment={environment}>
                    <ClonadorSessionProvider>
                        <ConfigProvider config={config}>
                            <ThemeProvider theme={theme}>
                                <StyledApp>
                                    {/* START: routes */}
                                    {/* These routes and navigation have been generated for you */}
                                    {/* Feel free to move and update them to fit your needs */}

                                    <Routes>
                                        <Route path="/" element={<Login />} />
                                        <Route
                                            path="/configuraciones"
                                            element={<ClonadorAuthGuard component={Configuraciones} />}
                                        />
                                        <Route
                                            path="/configuraciones/new"
                                            element={<ClonadorAuthGuard component={ConfiguracionNew} />}
                                        />
                                        <Route
                                            path="/configuraciones/:id/edit"
                                            element={<ClonadorAuthGuard component={ConfiguracionEdit} />}
                                        />
                                    </Routes>
                                    {/* END: routes */}
                                </StyledApp>
                            </ThemeProvider>
                        </ConfigProvider>
                    </ClonadorSessionProvider>
                </EnvironmentProvider>
            </QueryClientProvider>
        </GoogleOAuthProvider>
    )
}

export default App
