import styled from "@emotion/styled"
import { ReactComponent as Logo } from "../light.svg"
import YouTubeIcon from "@mui/icons-material/YouTube"
import InstagramIcon from "@mui/icons-material/Instagram"

/* eslint-disable-next-line */
export interface BaseFooterPreviewProps {}

let Footer = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-height: 100px;
    background-color: var(--base-footer_background_color);
    color: var(--base-footer_font_color);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
        margin: 0 0 10px;
    }
    & .logo {
        width: 60px;
        height: auto;
    }
`

let SocialMedia = styled.ul`
    display: flex;
    list-style: none;
    padding-left: 0;
    margin: 0;
    li {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 8px;
        background-color: var(--base-footer_social_media_background_color);
        transition: all ease 0.3s;
        &:hover {
            background-color: var(--base-footer_social_media_background_color_hover);
        }
    }
`

const HoverableH4 = styled.h4`
    &:hover {
        cursor: pointer;
        color: var(--base-footer_font_color_hover);
    }
`

export function BaseFooterPreview(props: BaseFooterPreviewProps) {
    return (
        <Footer>
            <div className="logo">
                <Logo />
            </div>

            <div>
                <HoverableH4>Contáctanos</HoverableH4>
                <SocialMedia>
                    <li>
                        <YouTubeIcon />
                    </li>
                    <li>
                        <InstagramIcon />
                    </li>
                </SocialMedia>
            </div>
        </Footer>
    )
}

export default BaseFooterPreview
