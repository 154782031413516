import axios, { AxiosInstance } from "axios"
import { ApiOptions } from "./ApiInterfaces"
import { ApiRequest } from "./ApiRequest"

/**
 * Clase que representa un endpoint de una API.
 * Usada para ser extendida sin necesidad de implementar los métodos básicos.
 */
export class BasicApi<T> {
    private axiosInstance: AxiosInstance

    constructor(
        protected baseUrl: string,
        protected token: string,
        protected resource: string,
        protected singularResource: string,
        protected options: ApiOptions = {}
    ) {
        const axiosConfig: any = {
            baseURL: this.baseUrl,
            paramsSerializer: params => {
                return this.toQueryString(params)
            }
        }

        if (token) {
            axiosConfig.headers = { Authorization: token }
        }

        this.axiosInstance = axios.create(axiosConfig)
    }

    protected all<U = T>() {
        return new ApiRequest<U>(this.singularResource, this.axiosInstance, this.options).collection(this.resource)
    }

    protected one<U = T>(id: number) {
        return new ApiRequest<U>(this.singularResource, this.axiosInstance, this.options).member(this.resource, id)
    }

    protected empty<U = T>() {
        return new ApiRequest<U>(this.singularResource, this.axiosInstance, this.options)
    }

    private toQueryString = (object: any, nested: string = ""): string => {
        return Object.getOwnPropertyNames(object)
            .map(key => {
                if (object[key] instanceof Function) return ""

                if (object[key] instanceof Array) {
                    if (object[key].length == 0) object[key] = [null]

                    return object[key]
                        .map(o => {
                            if (!(o instanceof Object))
                                return nested ? nested + "[" + key + "][]=" + o : key + "[]=" + o

                            return this.toQueryString(o, nested ? nested + "[" + key + "][]" : key + "[]")
                        })
                        .filter(x => x)
                        .join("&")
                } else if (object[key] instanceof Object && !(object[key] instanceof Date)) {
                    return this.toQueryString(object[key], nested ? nested + "[" + key + "]" : key)
                } else {
                    const value = ("" + object[key]).replace("+", "%2B")

                    return nested ? nested + "[" + key + "]=" + value : key + "=" + value
                }
            })
            .filter(x => x)
            .join("&")
    }
}
