import { Transforms, Element as SlateElement, Editor } from "slate"
import { useSlate } from "slate-react"
import { CustomEditor } from "../custom-types"
import { Button, Icon } from "./UI"

interface IProps {
    action: string
    title: string
}

const handleMargin = (editor: CustomEditor, action: string) => {
    let marginLeft: number

    const [node] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, editor.selection),
            match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && typeof n["marginLeft"] === "number"
        })
    )

    if (node) {
        const isBorder = node[0]["marginLeft"] === 0 && action === "decrease"
        const marginValue = action === "decrease" ? node[0]["marginLeft"] - 24 : node[0]["marginLeft"] + 24
        marginLeft = isBorder ? 0 : marginValue
    } else {
        marginLeft = action === "decrease" ? 0 : 24
    }

    Transforms.setNodes<SlateElement>(editor, {
        marginLeft
    })
}

export const AlignButton = ({ action, title }: IProps) => {
    const editor = useSlate()

    return (
        <Button
            active={false}
            onMouseDown={event => {
                event.preventDefault()
                handleMargin(editor, action)
            }}
        >
            <Icon title={title}>{`format_indent_${action}`}</Icon>
        </Button>
    )
}
