import styled from "@emotion/styled"

/* eslint-disable-next-line */
export interface BaseSubtitlePreviewProps {
    children?: any
}

const BaseSubTitle = styled.h2`
    color: var(--base-subtitle_color);
    border-bottom: 2px solid var(--base-subtitle_underline_color);
    font-size: var(--base-subtitle_size);
`

export function BaseSubtitlePreview(props: BaseSubtitlePreviewProps) {
    return <BaseSubTitle>{props.children}</BaseSubTitle>
}

export default BaseSubtitlePreview
