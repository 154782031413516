import { Alert, CardContent, Paper } from "@mui/material"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { CUIAutocomplete, CUIContentBox, CUIFile, CUIFormInput } from "@puntaje/react/shared/cui"
import { FileS3 } from "../../util/components/FileS3"
import { useWatch } from "react-hook-form"
import { ImgPreview } from "../../util/components/ImgPreview"

interface LogosProps {
    /**
     * Valor que permite distinguir al componente si se está creando o editando un clon.
     * editing es true cuando estoy editando el clon, y false cuando estoy creando un clon nuevo
     */
    editing: boolean
}

export default function Logos({ editing }: LogosProps) {
    return (
        <>
            <Alert severity="info" sx={{ mb: 2, mt: 2 }}>
                Los logos se diferencian por su tamaño (Small, Medium, Large) y su contraste (Normal, Light). Deben ser
                subidos en formato PNG.
            </Alert>
            <Paper sx={{ marginBottom: 3, padding: 2 }}>
                <Typography variant="h5" component="div" mb={2}>
                    Logos normales
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Large
                            <ImgPreview field="configuracion.app.assets.logoLarge" />
                        </Typography>
                        <CUIFormInput
                            inputBuilder={{
                                component: FileS3,
                                componentProps: {
                                    modelName: "Configuracion"
                                },
                                name: "configuracion.app.assets.logoLarge",
                                label: "Logo large (PNG)"
                            }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Medium
                            <ImgPreview field="configuracion.app.assets.logoMedium" />
                        </Typography>
                        <CUIFormInput
                            inputBuilder={{
                                component: FileS3,
                                componentProps: {
                                    modelName: "Configuracion"
                                },
                                name: "configuracion.app.assets.logoMedium",
                                label: "Logo medium (PNG)"
                            }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Small
                            <ImgPreview field="configuracion.app.assets.logoSmall" />
                        </Typography>
                        <CUIFormInput
                            inputBuilder={{
                                component: FileS3,
                                componentProps: {
                                    modelName: "Configuracion"
                                },
                                name: "configuracion.app.assets.logoSmall",
                                label: "Logo small (PNG)"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ marginBottom: 3, padding: 2 }}>
                <Typography variant="h5" component="div" mb={2}>
                    Logos light
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Large
                            <ImgPreview field="configuracion.app.assets.logoLargeLight" />
                        </Typography>
                        <CUIFormInput
                            inputBuilder={{
                                component: FileS3,
                                componentProps: {
                                    modelName: "Configuracion"
                                },
                                name: "configuracion.app.assets.logoLargeLight",
                                label: "Logo large light (PNG)"
                            }}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Medium
                            <ImgPreview field="configuracion.app.assets.logoMediumLight" />
                        </Typography>
                        <CUIFormInput
                            inputBuilder={{
                                component: FileS3,
                                componentProps: {
                                    modelName: "Configuracion"
                                },
                                name: "configuracion.app.assets.logoMediumLight",
                                label: "Logo medium light (PNG)"
                            }}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Small
                            <ImgPreview field="configuracion.app.assets.logoSmallLight" />
                        </Typography>
                        <CUIFormInput
                            inputBuilder={{
                                component: FileS3,
                                componentProps: {
                                    modelName: "Configuracion"
                                },
                                name: "configuracion.app.assets.logoSmallLight",
                                label: "Logo small light (PNG)"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ marginBottom: 3, padding: 2 }}>
                <Typography variant="h5" component="div" mb={2}>
                    Logos square
                </Typography>
                <Grid container spacing={2}>
                    <Grid item sm={4} xs={12}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Square
                            <ImgPreview field="configuracion.app.assets.logoSquare" />
                        </Typography>
                        <CUIFormInput
                            inputBuilder={{
                                component: FileS3,
                                componentProps: {
                                    modelName: "Configuracion"
                                },
                                name: "configuracion.app.assets.logoSquare",
                                label: "Logo square (PNG)"
                            }}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Square light
                            <ImgPreview field="configuracion.app.assets.logoSquareLight" />
                        </Typography>
                        <CUIFormInput
                            inputBuilder={{
                                component: FileS3,
                                componentProps: {
                                    modelName: "Configuracion"
                                },
                                name: "configuracion.app.assets.logoSquareLight",
                                label: "Logo square light (PNG)"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ marginBottom: 3, padding: 2 }}>
                <Typography variant="h5" component="div" mb={2}>
                    Favicon (.ico o .png)
                </Typography>
                <Alert severity="info" style={{ marginBottom: "6px" }}>
                    Se debe subir el .zip que genera las imagenes con el tamaño adecuado para los favicon. <br />
                    <br />
                    Puedes ingresar a{" "}
                    <a href="https://realfavicongenerator.net/" target="_blank">
                        Generar Favicon
                    </a>{" "}
                    y seguir los pasos para obtener un archivo comprimido (.zip) con los tamaños correspondientes del
                    favicon
                </Alert>
                {editing && (
                    <Alert severity="warning" style={{ marginBottom: "6px" }}>
                        Advertencia: al subir un nuevo archivo de favicons y guardar los cambios, será necesario
                        deployear nuevamente la plataforma. Esto se realizará de manera automática, pero puede tomar
                        algunos minutos.
                    </Alert>
                )}
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                        <Typography variant="h6">Favicon (.zip)</Typography>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIFile,
                                componentProps: {
                                    accept: ".zip,application/octetStream,application/zip,application/x-zip,application/x-zip-compressed",
                                    transformFile: file => {
                                        return new Promise<string>((resolve, reject) => {
                                            const reader = new FileReader()
                                            reader.readAsDataURL(file)
                                            reader.onload = () => resolve(reader.result as string)
                                            reader.onerror = error => reject(error)
                                        })
                                    },
                                    disableSetValueOnPropChange: true
                                },
                                name: "attachments",
                                label: "Favicon"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
