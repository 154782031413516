import styled from "@emotion/styled"
import { ReactComponent as Logo } from "../logo.svg"
import PersonIcon from "@mui/icons-material/Person"
import MenuIcon from "@mui/icons-material/Menu"

export interface BaseNavbarPreviewProps {}

const BaseNavbar = styled.div`
    box-sizing: border-box;
    height: var(--base-navbar_height);
    background: var(--base-navbar_background_color);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 15px;
    & .logo {
        height: var(--base-navbar_logo_height);
        width: auto;
        margin-left: 15px;
    }
`

const LeftSide = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
`

const Avatar = styled.div`
    height: var(--base-navbar_avatar_size);
    width: var(--base-navbar_avatar_size);
    border-radius: 50%;
    background: var(--base-navbar_avatar_background_color);
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    overflow: hidden;
`

const Username = styled.span(props => ({
    height: "100%",
    display: "flex",
    alignItems: "center",
    color: "var(--base-navbar_font_color)",

    "&:hover": {
        cursor: "pointer",
        color: "var(--base-navbar_font_color_hover)",
        backgroundColor: "var(--base-navbar_background_color_hover)"
    }
}))

export function BaseNavbarPreview(props: BaseNavbarPreviewProps) {
    return (
        <BaseNavbar>
            <LeftSide>
                <MenuIcon
                    sx={{
                        fontSize: 30,
                        color: "var(--base-navbar_font_color_menu_icon)",
                        "&:hover": {
                            color: "var(--base-navbar_font_color_menu_icon_hover)",
                            background: "var(--base-navbar_menu_btn_background_color_hover)"
                        },
                        background: "var(--base-navbar_menu_btn_background_color)"
                    }}
                />
                <Logo className="logo" />
            </LeftSide>
            <Username>Usuario</Username>
            <Avatar>
                <PersonIcon sx={{ fontSize: 30 }} />
            </Avatar>
        </BaseNavbar>
    )
}

export default BaseNavbarPreview
