import React, { ReactNode } from "react"
import Box, { BoxProps } from "@mui/material/Box"

export interface CUIContentBoxProps extends BoxProps {
    children?: ReactNode
    boxed?: boolean
}

const defaultBoxed = {
    mb: 3,
    mt: 2,
    mx: "auto",
    p: 2,
    pb: 3,
    backgroundColor: "background.paper",
    borderRadius: 0,
    boxShadow: 8
}

export function CUIContentBox({ boxed, children, sx, ...props }: CUIContentBoxProps) {
    return (
        <Box sx={boxed ? { ...defaultBoxed, ...sx } : sx} {...props}>
            {children}
        </Box>
    )
}

export default CUIContentBox
