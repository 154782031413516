import { useConfig } from "../config/ConfigContext"
import { makeUseTranslateLib } from "./makeUseTranslateLib"
import { sharedCoreTranslations } from "./translations"

const internalUseTranslateLib = makeUseTranslateLib<typeof sharedCoreTranslations["es"]>({
    dictionaries: sharedCoreTranslations as unknown as { [locale: string]: typeof sharedCoreTranslations["es"] },
    dictionariesDependencies: {
        "es-pn-co": "es-co",
        "es-cl": "es",
        "es-co": "es",
        "es-mx": "es",
        "es-gen": "es",
        "pt-br": "pt"
    }
})

export function useTranslateLib<V extends undefined | keyof typeof sharedCoreTranslations["es"]>(prefix?: V) {
    const config = useConfig<{ plataforma: { locale: string } }>()

    return internalUseTranslateLib<V>(prefix, config.plataforma.locale)
}