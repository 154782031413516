import { ChangeEvent, useState } from "react"
import { Transforms } from "slate"
import { HistoryEditor } from "slate-history"
import { useSlate } from "slate-react"
import { css } from "@emotion/css"
import { CUIDialog, CUIDialogActions, CUIDialogContent, CUIDialogTitle } from "../../Feedback"
import CUIButton from "../CUIButton"
import { CUIText } from "../CUIText"
import { Button, Icon } from "./UI"
import { getElements } from "./utils"
import { EquationElement } from "../custom-types"
import { Katex } from "@puntaje/react/shared/core"

export const Equation = ({ attributes, math, children }) => {
    return (
        <span
            {...attributes}
            className={css`
                display: inline-block;
            `}
        >
            <Katex content={`$${math}$`} />
            {children}
        </span>
    )
}

export const EquationButton = (): JSX.Element => {
    const [math, setMath] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const editor = useSlate()

    const handleOnClose = () => {
        setIsOpen(false)
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target } = event
        setMath(target.value)
    }

    const handleClick = () => {
        const [equationEntry] = getElements(editor, "equation")
        const equation = equationEntry ? equationEntry[0]["math"] : ""

        setMath(equation)
        setIsOpen(true)
    }

    const handleAddEquation = () => {
        if (!math) return

        const text = { text: "" }
        const equation: EquationElement = { type: "equation", math, children: [text] }
        Transforms.insertNodes(editor, equation)

        HistoryEditor.withoutMerging(editor, () => {
            Transforms.move(editor)
        })

        handleOnClose()
    }
    return (
        <>
            <Button active={false} onMouseDown={handleClick}>
                <Icon title="Equación">functions</Icon>
            </Button>
            <CUIDialog onClose={handleOnClose} open={isOpen}>
                <CUIDialogTitle onClose={handleOnClose}>Escribe tu TeX</CUIDialogTitle>
                <CUIDialogContent>
                    <CUIText
                        autoFocus
                        label="Ingresa una formula"
                        placeholder="ej: x = {-b \pm \sqrt{b^2-4ac} \over 2a}"
                        value={math}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                    />
                </CUIDialogContent>
                <CUIDialogActions>
                    <CUIButton onMouseDown={handleOnClose}>Cancelar</CUIButton>
                    <CUIButton onMouseDown={handleAddEquation}>Aceptar</CUIButton>
                </CUIDialogActions>
            </CUIDialog>
        </>
    )
}
