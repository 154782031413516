import Checkbox, { CheckboxProps } from "@mui/material/Checkbox"
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel"
import { forwardRef } from "react"

export interface CUICheckboxProps<T extends "control" | "label" = "control"> extends CheckboxProps {
    color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "default"
    controlLabelProps?: Omit<FormControlLabelProps, T>
}

export const CUICheckbox = forwardRef(({ controlLabelProps, ...checkboxProps }: CUICheckboxProps, ref: any) => {
    return controlLabelProps ? (
        <FormControlLabel control={<Checkbox {...checkboxProps} ref={ref} />} {...controlLabelProps} />
    ) : (
        <Checkbox ref={ref} {...checkboxProps} />
    )
})

export default CUICheckbox
