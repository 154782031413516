import styled from "@emotion/styled"

/* eslint-disable-next-line */
export interface BaseTitlePreviewProps {
    children?: any
    subtitle?: string
}

const BaseTitle = styled.h1`
    color: var(--base-titlebar_color);
    font-size: var(--base-titlebar_size);
`

const SubTitle = styled.small`
    color: var(--base-titlebar_subtitle_color);
    font-size: var(--base-titlebar_subtitle_size);
    margin-left: 10px;
`

export function BaseTitlePreview(props: BaseTitlePreviewProps) {
    return (
        <BaseTitle>
            {props.children}
            <SubTitle>{props.subtitle}</SubTitle>
        </BaseTitle>
    )
}

export default BaseTitlePreview
