import { Alert, Grid, Paper, Typography } from "@mui/material"
import { styled } from "@mui/system"
import { useEffect, useState } from "react"
import { Control, useFormContext, useWatch } from "react-hook-form"
import axios from "axios"

interface AspectRatioPreviewProps {
    /**
     * Nombre del campo a mostrar en el título del componente
     */
    imageLabel: string

    /**
     * Nombre del campo en el objeto formulario
     */
    field: string

    /**
     * Control del formulario de react-hook-form para manejar el estado del formulario.
     * @see https://react-hook-form.com/api/useform/control
     *
     * Si no viene se usa el del contexto.
     */
    control?: Control
}

const ImagenRatio = styled("img")({
    height: "100%",
    width: "100%",
    objectFit: "cover",
    display: "block"
})

export function AspectRatioPreview({ imageLabel, field, control }: AspectRatioPreviewProps) {
    const [base64Img, setBase64Img] = useState("")

    const methods = useFormContext()
    control = control ?? methods.control

    const img = useWatch({
        control,
        name: field
    })

    useEffect(() => {
        if (img) {
            setBase64Img("")
            axios.get(img, { responseType: "blob" }).then(async imgResponse => {
                const blob = imgResponse.data as Blob
                const reader = new FileReader()
                reader.readAsDataURL(blob)
                reader.onloadend = () => {
                    setBase64Img(reader.result as string)
                }
            })
        }
    }, [img])

    const aspectRatios = [
        "16/9",
        "4/3",
        "5/9",
        "16/10"
        // "3/2",
        // "1/1",
        // "9/20",
        // "9/19.5"
    ]

    return (
        <>
            <Typography mb={2}>Previsualización de imagen {imageLabel}</Typography>
            <Alert severity="info" sx={{ mb: 2 }}>
                A continuación se muestra cómo se verá la imagen del {imageLabel} ingresada en distintos ratios de
                pantalla
            </Alert>
            <Grid container spacing={2} mb={2}>
                {aspectRatios.map((ar, index) => {
                    return (
                        <Grid item xs={3} key={index}>
                            <Paper
                                sx={{
                                    p: 2,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    verticalAlign: "middle"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                        minHeight: 130
                                    }}
                                >
                                    <div
                                        style={{
                                            borderStyle: "solid",
                                            borderColor: "black",
                                            backgroundSize: "fill",
                                            aspectRatio: ar,
                                            height: "100px"
                                        }}
                                        role={"preview_div_" + ar}
                                    >
                                        {base64Img ? (
                                            <ImagenRatio
                                                src={base64Img}
                                                alt={`Preview ${ar}`}
                                                role={"preview_img_" + ar}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <Typography sx={{ display: "block" }}>
                                    {ar.replace("/", ":")} <br />
                                </Typography>
                            </Paper>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}
