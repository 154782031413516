import { Alert, Paper, Typography } from "@mui/material"
import { CUICheckbox, CUIFormInput } from "@puntaje/react/shared/cui"
import { Control } from "react-hook-form"

interface AccionesAdicionalesProps {
    /**
     * Valor que permite distinguir al componente si se está creando o editando un clon.
     * editing es true cuando estoy editando el clon, y false cuando estoy creando un clon nuevo
     */
    editing: boolean
}

export function AccionesAdicionales({ editing }: AccionesAdicionalesProps) {
    return (
        <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h4" component="div" mb={2}>
                Opciones Generadores
            </Typography>
            <Alert severity="info" sx={{ mb: 1 }}>
                Todos los generadores son opcionales, y sólo aplican si la asignatura no tiene generadores del tipo.
                <br />
                <br />
                Las casillas marcadas se desmarcarán después de hacer el deploy.
            </Alert>
            <CUIFormInput
                inputBuilder={{
                    component: CUICheckbox,
                    componentProps: {},
                    name: "accionesAdicionales.copiarGeneradores",
                    label: "Copiar generadores Chile",
                    tooltip: "Sólo aplica para ensayo, curricular y prueba de transición"
                }}
            />
            <br />
            <CUIFormInput
                inputBuilder={{
                    component: CUICheckbox,
                    componentProps: {},
                    name: "accionesAdicionales.copiarGeneradoresCurricularColombia",
                    label: "Copiar generadores curricular Colombia",
                    tooltip:
                        "Opcional, solo aplica para curricular Colombia, si la asignatura no tiene generadores del tipo."
                }}
            />
            <br />
            <CUIFormInput
                inputBuilder={{
                    component: CUICheckbox,
                    componentProps: {},
                    name: "accionesAdicionales.copiarGeneradoresCurricularMexico",
                    label: "Copiar generadores curricular México",
                    tooltip:
                        "Opcional, solo aplica para curricular México, si la asignatura no tiene generadores del tipo."
                }}
            />
            <br />
            <CUIFormInput
                inputBuilder={{
                    component: CUICheckbox,
                    componentProps: {},
                    name: "accionesAdicionales.copiarGeneradoresCurricularSaber11",
                    label: "Copiar generadores Saber 11",
                    tooltip: "Opcional, solo aplica para Saber 11, si la asignatura no tiene generadores del tipo."
                }}
            />
            <br />
            <CUIFormInput
                inputBuilder={{
                    component: CUICheckbox,
                    componentProps: {},
                    name: "accionesAdicionales.copiarGeneradoresNivelacionEstudios",
                    label: "Copiar generadores nivelación estudios",
                    tooltip:
                        "Opcional, solo aplica para nivelación estudios, si la asignatura no tiene generadores del tipo."
                }}
            />
            <br />
            <CUIFormInput
                inputBuilder={{
                    component: CUICheckbox,
                    componentProps: {},
                    name: "accionesAdicionales.copiarGeneradoresCurricularEnem",
                    label: "Copiar generadores ENEM",
                    tooltip: "Opcional, solo aplica para ENEM, si la asignatura no tiene generadores del tipo."
                }}
            />
        </Paper>
    )
}
