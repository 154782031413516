import { styled } from "@mui/material/styles"
import CircularProgress from "@mui/material/CircularProgress"
import { LinearProgress, SxProps } from "@mui/material"
import { useCallback } from "react"

const LoaderContainer = styled("div", { shouldForwardProp: prop => prop != "loaderType" && prop != "sx" })<{
    loaderType: string
}>(({ loaderType }) => ({
    display: loaderType === "circular" ? "flex" : "block",
    alignItems: "center",
    justifyContent: "center"
}))

const AbsoluteContainer = styled("div")(() => ({
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
}))

const AbsoluteLoaderContainer = LoaderContainer.withComponent(AbsoluteContainer)

const RelativeContainer = styled("div")(() => ({
    position: "relative"
}))

const OpaqueContainer = styled("div", { shouldForwardProp: prop => prop != "loading" })<{ loading: boolean }>(
    ({ loading }) => ({
        opacity: loading ? 0.2 : 1
    })
)

export interface CUILoaderProps {
    loading: boolean
    opaque?: boolean
    loaderType?: "circular" | "linear"
    LoaderComponentProps?: any
    render?: () => JSX.Element
    loaderContainerSx?: SxProps
}

export function CUILoader({
    loading,
    opaque,
    LoaderComponentProps,
    loaderType = "circular",
    render = () => null,
    loaderContainerSx
}: CUILoaderProps) {
    const LoaderComponent = loaderType === "circular" ? CircularProgress : LinearProgress
    const CustomLoaderContainer = opaque ? AbsoluteLoaderContainer : LoaderContainer

    const Loading = useCallback(
        () => (
            <CustomLoaderContainer sx={loaderContainerSx} loaderType={loaderType}>
                <LoaderComponent {...LoaderComponentProps} />
            </CustomLoaderContainer>
        ),
        [loaderType, LoaderComponentProps, loaderContainerSx]
    )

    const OpaqueContent = useCallback(
        ({ render, loading }) => (
            <RelativeContainer>
                <OpaqueContainer loading={loading}>{render()}</OpaqueContainer>
                {loading && <Loading />}
            </RelativeContainer>
        ),
        []
    )

    const DefaultContent = useCallback(({ render, loading }) => (loading ? <Loading /> : render()), [])

    return opaque ? (
        <OpaqueContent render={render} loading={loading} />
    ) : (
        <DefaultContent loading={loading} render={render} />
    )
}
