import { LocaleType } from "./types"

export const es: LocaleType = {
    use_validate_email: {
        email_error: "El correo ingresado es inválido."
    },
    use_validate_phone: {
        phone_error: "El teléfono ingresado es inválido."
    },
    use_validate_rut: {
        rut_error: "El RUT es inválido. RUT debe ser ingresado con guión y sin puntos."
    },
    use_validate_ti_o_cc: {
        ti_o_cc_error: "El Número Único de Identificación Personal es inválido. NUIP debe ser ingresado sin puntos."
    },
    use_validate_curp: {
        curp_error: "El CURP es inválido. Debe tener 18 caracteres."
    },
    use_validate_cpf: {
        cpf_error: "El CPF es inválido."
    },
    use_pattern_error: {
        pattern_error: "El campo %{label} no tiene un formato válido."
    },
    use_max_error: {
        max_error: "El campo %{label} no puede ser mayor a %{max}."
    },
    use_min_error: {
        min_error: "El campo %{label} no puede ser menor a %{min}."
    },
    use_min_length_error: {
        min_length_error: "El campo %{label} no puede tener menos de %{min} caracteres."
    },
    use_max_length_error: {
        max_length_error: "El campo %{label} no puede tener más de %{max} caracteres."
    },
    use_required_error: {
        required_error: "El campo %{label} es requerido."
    },
    use_inicio_less_than_termino: {
        inicio_less_than_termino_error: "La fecha de término debe ser posterior a la fecha de inicio"
    },
    password_strength_bar: {
        muy_debil: "Muy débil",
        debil: "Débil",
        moderada: "Moderada",
        segura: "Segura",
        muy_segura: "Muy segura"
    },
    use_validate_str_array_numbers: {
        error: "El valor ingresado no tiene un formato válido."
    },
    use_fecha_atras: {
        ayer: "hace 1 día",
        hace_ano: "hace más de %{smart_count} año |||| hace más de %{smart_count} años",
        hace_dia: "hace %{smart_count} día |||| hace %{smart_count} días",
        hace_mes: "hace más de %{smart_count} mes |||| hace más de %{smart_count} meses",
        hace_semana: "hace más de %{smart_count} semana |||| hace más de %{smart_count} semanas",
        hoy: "hoy"
    }
}
