import { FormHelperText } from "@mui/material"
import FormControl, { FormControlProps } from "@mui/material/FormControl"
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel"
import Select, { SelectProps } from "@mui/material/Select"
import { forwardRef, useId } from "react"

export interface CUISelectProps extends SelectProps {
    formControlProps?: FormControlProps
    labelProps?: InputLabelProps
    helpertext?: React.ReactNode
}

export const CUISelect = forwardRef((props: CUISelectProps, ref) => {
    const labelId = useId()
    const { formControlProps, label, error, labelProps, required, ...restProps } = props

    const fullWidth = formControlProps?.fullWidth || restProps?.fullWidth
    const size = formControlProps?.size || restProps?.size

    return (
        <FormControl
            {...formControlProps}
            size={size ?? "medium"}
            fullWidth={fullWidth}
            sx={fullWidth ? { ...formControlProps?.sx } : { width: 300, ...formControlProps?.sx }}
            required={required}
        >
            <InputLabel {...labelProps} id={labelId}>
                {label}
            </InputLabel>
            <Select {...restProps} label={label} labelId={labelId} ref={ref}></Select>
            {props.helpertext && <FormHelperText error={error}> {props.helpertext} </FormHelperText>}
        </FormControl>
    )
})

export default CUISelect
