import Stack from "@mui/material/Stack"
import CUIButton from "./CUIButton"
import { styled, SxProps } from "@mui/material/styles"
import { CUIText } from "./CUIText"
import React, { forwardRef, useCallback, useEffect, useState, useId } from "react"
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined"

const Input = styled("input")({
    display: "none"
})

const Label = styled("label")({
    flexShrink: 0
})

export interface CUIFileProps<T = File> extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    labelComponent?: React.ElementType
    labelProps?: any
    buttonComponent?: React.ElementType
    buttonProps?: any
    onChange?: (value: T) => void
    transformFile?: (file: File) => T | Promise<T>
    filePath?: string
    buttonContent?: React.ReactNode
    labelRef?: React.Ref<HTMLElement>
    disableSetValueOnChange?: boolean
    disableSetValueOnPropChange?: boolean
    sx?: SxProps
}

/**
 * Componente para subir archivos
 * @todo revisar el comportamiento con la prop value={undefined}
 * @param props
 */
export const CUIFile = forwardRef(
    <T extends unknown = File>(
        {
            labelComponent: LabelComponent = CUIText,
            labelProps: {
                disabled = true,
                variant = "outlined",
                fullWidth = true,
                size = "small",
                ...restLabelProps
            } = {},
            labelRef,
            buttonComponent: ButtonComponent = CUIButton,
            buttonProps: { variant: buttonVariant = "contained", component = "span", ...restButtonProps } = {},
            onChange,
            transformFile,
            filePath,
            placeholder = "Seleccione archivo",
            buttonContent = "Escoger archivo",
            disableSetValueOnChange,
            disableSetValueOnPropChange,
            sx,
            ...props
        }: CUIFileProps<T>,
        ref: React.Ref<HTMLInputElement>
    ) => {
        const [value, setValue] = useState(filePath ?? "")

        const fileOnChange = useCallback(
            async (event: React.ChangeEvent<HTMLInputElement>) => {
                const file = event.target.files?.[0]

                if (file) {
                    if (!disableSetValueOnChange) setValue(file.name)
                    onChange?.(transformFile ? await transformFile(file) : (file as any))
                } else {
                    if (!disableSetValueOnChange) setValue("")
                    onChange?.(null)
                }
            },
            [onChange]
        )

        useEffect(() => {
            if (filePath != value && !disableSetValueOnPropChange) {
                setValue(filePath ?? "")
            }
        }, [filePath])

        const handleClickClear = () => {
            setValue("")
        }

        const handleMouseDownClear = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault()
        }

        const labelId = useId()

        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems={size == "small" ? "flex-start" : "baseline"}
                spacing={1}
                sx={sx}
            >
                <LabelComponent
                    size={size}
                    disabled={disabled}
                    aria-disabled={disabled}
                    variant={variant}
                    placeholder={placeholder}
                    fullWidth={fullWidth}
                    {...restLabelProps}
                    InputLabelProps={{
                        ...restLabelProps.InputLabelProps,
                        htmlFor: undefined,
                        id: labelId
                    }}
                    value={value}
                    ref={labelRef}
                    InputProps={{
                        endAdornment:
                            value != "" ? (
                                <IconButton
                                    size="small"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickClear}
                                    onMouseDown={handleMouseDownClear}
                                    edge="end"
                                >
                                    {<ClearOutlinedIcon />}
                                </IconButton>
                            ) : (
                                <> </>
                            )
                    }}
                />
                <Label htmlFor={props.id}>
                    <Input
                        {...props}
                        type="file"
                        onChange={fileOnChange}
                        aria-labelledby={labelId}
                        ref={ref}
                        value={undefined}
                    />
                    <ButtonComponent
                        startIcon={<FileOpenOutlinedIcon />}
                        variant={buttonVariant}
                        component={component}
                        {...restButtonProps}
                    >
                        {buttonContent}
                    </ButtonComponent>
                </Label>
            </Stack>
        )
    }
)
