import { Alert, Box, Paper } from "@mui/material"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { CUIContentBox, CUIFile, CUIFormInput } from "@puntaje/react/shared/cui"
import { CUIText } from "@puntaje/react/shared/cui"
import { CUISelect } from "@puntaje/react/shared/cui"
import { CUICheckbox } from "@puntaje/react/shared/cui"
import { FileS3 } from "../../util/components/FileS3"
import { ImgPreview } from "../../util/components/ImgPreview"
import { AspectRatioPreview } from "../../util/components/AspectRatioPreview"
import {
    BaseButtonPreview,
    BaseShowcaseboxPreview,
    BaseSubtitlePreview,
    BaseTitlePreview,
    Showcase
} from "@puntaje/clonador/previewer"
import { ConfigToTheme } from "./ConfigToTheme/ConfigToTheme"
import { SeccionesLanding } from "./SeccionesLanding/SeccionesLanding"

export default function MasEstilo({ editing }) {
    return (
        <>
            <Typography variant="h4" mb={2}>
                Fuentes
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Alert severity="info" style={{ marginBottom: "6px" }}>
                    Esta es la tipografía que se usará en la plataforma. Se puede usar por ejemplo{" "}
                    <a href="https://fonts.google.com/" target="_blank">
                        Google Fonts
                    </a>
                    .
                </Alert>
                <Grid container spacing={2} mb={2}>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.fuente1Nombre",
                                label: "Fuente 1 nombre"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.fuente1Link",
                                label: "Fuente 1 link",
                                rules: {
                                    pattern: {
                                        value: /\bhttps?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)\b/,
                                        message: "El valor de este campo debe ser una dirección URL"
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.fuente2Nombre",
                                label: "Fuente 2 nombre"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.fuente2Link",
                                label: "Fuente 2 link",
                                rules: {
                                    pattern: {
                                        value: /\bhttps?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)\b/,
                                        message: "El valor de este campo debe ser una dirección URL"
                                    }
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Typography variant="h4" mb={2}>
                Títulos
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" mb={2}>
                    Preview
                </Typography>

                <Alert severity="info" sx={{ mb: 2 }}>
                    Preview de los titulos (tamaños y colores). Por ahora la fuente no se previsualiza.
                </Alert>

                <ConfigToTheme>
                    <BaseTitlePreview subtitle="Con subtítulo">Titulo principal</BaseTitlePreview>
                    <BaseSubtitlePreview>Título secundario</BaseSubtitlePreview>
                </ConfigToTheme>
            </Paper>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" mb={2}>
                    Colores y Tamaños
                </Typography>
                <Grid container spacing={2} mb={2}>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.tituloColor",
                                label: "Color título"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.tituloTamano",
                                label: "Tamaño fuente título",
                                tooltip: "En px. Ej: 14px",
                                rules: {
                                    pattern: {
                                        value: /\b(\d+)px\b/,
                                        message: 'El valor debe ser un número seguido de "px", por ejemplo "14px"'
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.subtituloTituloColor",
                                label: "Color subtítulo del título"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.subtituloTituloTamano",
                                label: "Tamaño fuente subtítulo del título",
                                tooltip: "En px. Ej: 14px",
                                rules: {
                                    pattern: {
                                        value: /\b(\d+)px\b/,
                                        message: 'El valor debe ser un número seguido de "px", por ejemplo "14px"'
                                    }
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.subtituloColor",
                                label: "Color subtítulo"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.subtituloTamano",
                                label: "Tamaño fuente subtítulo",
                                tooltip: "En px. Ej: 14px",
                                rules: {
                                    pattern: {
                                        value: /\b(\d+)px\b/,
                                        message: 'El valor debe ser un número seguido de "px", por ejemplo "14px"'
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.subtituloColorSubrayado",
                                label: "Color subrayado subtítulo"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Typography variant="h4" mb={2}>
                Contenido
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" mb={2}>
                    Preview
                </Typography>

                <Alert severity="info" sx={{ mb: 2 }}>
                    Preview del contenido, en particular de los botones. Por ahora no se previsualiza el color de la
                    letra en la página.
                </Alert>

                <ConfigToTheme>
                    <Box
                        sx={{
                            display: "flex",
                            "& > *": {
                                m: 1
                            }
                        }}
                    >
                        <BaseButtonPreview primary>Primary</BaseButtonPreview>
                        <BaseButtonPreview cancel>Cancel</BaseButtonPreview>
                        <BaseButtonPreview secondary>Secondary</BaseButtonPreview>
                    </Box>
                </ConfigToTheme>
            </Paper>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" mb={2}>
                    Color Contenido
                </Typography>
                <Grid container spacing={2} mb={2}>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoColorFuente",
                                label: "Color texto"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" mb={2}>
                    Botón principal
                </Typography>
                <Alert severity="info" sx={{ mb: 2 }}>
                    Este botón es el que se encarga de aceptar, enviar, etc en la plataforma.
                </Alert>
                <Grid container spacing={2} mb={2} mt={1}>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoBotonColor",
                                label: "Color fondo botón"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoBotonColorHover",
                                label: "Color fondo botón al poner el mouse encima"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoBotonColorFuente",
                                label: "Color texto botón"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoBotonColorFuenteHover",
                                label: "Color texto botón al poner el mouse encima"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" mb={2}>
                    Botón cancelar
                </Typography>
                <Alert severity="info" sx={{ mb: 2 }}>
                    Este botón es el que se encarga de cancelar, deshacer y eliminar cosas en la plataforma.
                </Alert>
                <Grid container spacing={2} mb={2} mt={1}>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoBotonCancelarColor",
                                label: "Color fondo botón"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoBotonCancelarColorHover",
                                label: "Color fondo botón al poner el mouse encima"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoBotonCancelarColorFuente",
                                label: "Color texto botón"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoBotonCancelarColorFuenteHover",
                                label: "Color texto botón al poner el mouse encima"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" mb={2}>
                    Botón secundario
                </Typography>
                <Alert severity="info" sx={{ mb: 2 }}>
                    Este botón suele aparecer acompañando al boton principal con la mision añadir mas funciones a la
                    ventana pero que no tengan que ver con funciones como las del boton cancelar. Por ejemplo: detallar.
                </Alert>
                <Grid container spacing={2} mb={2} mt={1}>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoBotonSecundarioColor",
                                label: "Color fondo botón"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoBotonSecundarioColorHover",
                                label: "Color fondo botón al poner el mouse encima"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoBotonSecundarioColorFuente",
                                label: "Color texto botón"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.contenidoBotonSecundarioColorFuenteHover",
                                label: "Color texto botón al poner el mouse encima"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Typography variant="h4" mb={2}>
                Calugas
            </Typography>
            <Alert severity="info" sx={{ mb: 2 }}>
                Estos son los modulos que aparecen en las secciones. ejemplo: Prueba Curricular -{">"} Historial
                Curricular
            </Alert>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" mb={2}>
                    Colores
                </Typography>
                <Grid container spacing={2} mb={2}>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.calugasColorFondoIzq",
                                label: "Color fondo izquierdo"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.calugasColorIconoIzq",
                                label: "Color icono izquierdo"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.calugasColorTitulo",
                                label: "Color título"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.calugasColorSubrayado",
                                label: "Color subrayado"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Typography variant="h4" mb={2}>
                Showcaseboxes
            </Typography>
            <Alert severity="info" sx={{ mb: 2 }}>
                Estas son las cajitas que aparecen en el Home. ejemplo: Entrenador Virtual, Ranking de Prueba, Dudas
                recientes
            </Alert>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" mb={2}>
                    Preview
                </Typography>

                <ConfigToTheme>
                    <BaseShowcaseboxPreview></BaseShowcaseboxPreview>
                </ConfigToTheme>
            </Paper>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" mb={2}>
                    Colores
                </Typography>
                <Grid container spacing={2} mb={2} mt={1}>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.showcaseboxColorIcono",
                                label: "Color icono"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.showcaseboxColorTitulo",
                                label: "Color título"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.showcaseboxColorFondoTitulo",
                                label: "Color fondo título"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Typography variant="h4" mb={2}>
                Landing
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={2} mb={2} alignItems="flex-end">
                    <Grid item sm={4} xs={12}>
                        <Typography variant="caption">
                            Imagen de fondo (al menos 2000x2000, PNG)
                            <ImgPreview field="configuracion.app.baseStyle.landing.backgroundImage" />
                        </Typography>
                        <CUIFormInput
                            inputBuilder={{
                                component: FileS3,
                                componentProps: {
                                    modelName: "Configuracion"
                                },
                                name: "configuracion.app.baseStyle.landing.backgroundImage",
                                label: "Imagen de fondo landing"
                            }}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <AspectRatioPreview
                            imageLabel="landing"
                            field="configuracion.app.baseStyle.landing.backgroundImage"
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {
                                    formControlProps: {
                                        fullWidth: true
                                    }
                                },
                                name: "configuracion.app.baseStyle.landing.logo",
                                label: "Tipo de logo",
                                options: ["logoLarge", "logoLargeLight"],

                                tooltip: "Para que contraste con el color o imágen de fondo."
                            }}
                        />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: "configuracion.app.baseStyle.landing.subtitle",
                                label: "Texto bajada título"
                            }}
                        />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUICheckbox,
                                componentProps: {},
                                name: "configuracion.app.baseStyle.landing.subtitleHasShadow",
                                label: "Bajada de título con sombra"
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={2}>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.landingTituloBajadaColor",
                                label: "Color bajada título"
                            }}
                        />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.landingColorMiniFooter",
                                label: "Color mini footer"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {
                                    formControlProps: {
                                        fullWidth: true,
                                        sx: {
                                            minWidth: 0
                                        }
                                    },
                                    sx: {
                                        minWidth: 0
                                    }
                                },
                                name: "configuracion.app.baseStyle.landing.logoFooter",
                                label: "Tipo de logo mini footer",
                                options: ["logoSmall", "logoSmallLight"]
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <SeccionesLanding />

            <Typography variant="h4" mb={2}>
                Registro
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item sm={4} xs={12}>
                        <Typography variant="caption">
                            Imagen fondo registro (opcional, al menos 2000x2000, PNG)
                            <ImgPreview field="configuracion.app.baseStyle.sharedLayout.backgroundImage" />
                        </Typography>
                        <CUIFormInput
                            inputBuilder={{
                                component: FileS3,
                                componentProps: {
                                    modelName: "Configuracion"
                                },
                                name: "configuracion.app.baseStyle.sharedLayout.backgroundImage",
                                label: "Imagen de fondo registro"
                            }}
                        />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {},
                                name: "configuracion.app.baseStyle.landing.logo",
                                label: "Tipo de logo",
                                options: ["logoLarge", "logoLargeLight"],
                                tooltip: "Elige un logo que pueda verse bien sobre la imagen de fondo."
                            }}
                        />
                    </Grid>

                    <Grid item sm={12} xs={12}>
                        <AspectRatioPreview
                            imageLabel="registro"
                            field="configuracion.app.baseStyle.sharedLayout.backgroundImage"
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
