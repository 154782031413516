import styled from "@emotion/styled"

let BaseButton = styled.button`
    background-color: var(--base-primary_button_background_color);
    color: var(--base-primary_button_font_color);
    padding: 8px 16px;
    border-radius: 2px;
    transition: all 0.2s ease-in;
    border: none;
    font-family: Raleway, Sans-Serif;
    box-shadow: 2px 2px 8px rgb(0 0 0 / 10%);
    cursor: pointer;
    &.block {
        width: 100%;
    }
    &:active {
        transform: scale(0.9);
        box-shadow: none;
    }
    &:hover {
        background-color: var(--base-primary_button_background_color_hover);
        color: var(--base-primary_button_font_color_hover);
    }
    &.primary {
        background-color: var(--base-primary_button_background_color);
        color: var(--base-primary_button_font_color);
        &:hover {
            background-color: var(--base-primary_button_background_color_hover);
            color: var(--base-primary_button_font_color_hover);
        }
    }
    &.cancel {
        background-color: var(--base-cancel_button_background_color);
        color: var(--base-cancel_button_font_color);
        &:hover {
            background-color: var(--base-cancel_button_background_color_hover);
            color: var(--base-cancel_button_font_color_hover);
        }
    }
    &.secondary {
        background-color: var(--base-secondary_button_background_color);
        color: var(--base-secondary_button_font_color);
        &:hover {
            background-color: var(--base-secondary_button_background_color_hover);
            color: var(--base-secondary_button_font_color_hover);
        }
    }
`

/* eslint-disable-next-line */
export interface BaseButtonPreviewProps {
    children: any
    primary?: boolean
    cancel?: boolean
    secondary?: boolean
    block?: boolean
}

export function BaseButtonPreview(props: BaseButtonPreviewProps) {
    return (
        <BaseButton
            className={
                (props.primary ? "primary" : "") +
                (props.cancel ? " cancel" : "") +
                (props.secondary ? " secondary" : "") +
                (props.block ? " block" : "")
            }
        >
            {props.children}
        </BaseButton>
    )
}

export default BaseButtonPreview
