import { Api, ApiOptions } from "@puntaje/react/shared/core"
import { Configuracion } from "./Configuracion"

export class Configuraciones extends Api<Configuracion> {
    constructor(baseUrl: string, token: string, options: ApiOptions = {}) {
        super(baseUrl, token, "configuraciones", "configuracion", options)
    }

    crearApi(id: number) {
        return this.one<{ api_state: string }>(id).collection("crear_api").post(null, { notConcat: true })
    }

    deployar(id: number) {
        return this.one<{ success: boolean }>(id).collection("deployar").post(null, { notConcat: true })
    }
}
