import Button from "@mui/material/Button"
import { ButtonProps } from "@mui/material/Button"
import { forwardRef } from "react"

/* eslint-disable-next-line */
export type CUIButtonProps<T extends React.ElementType = "button"> = Omit<ButtonProps<T>, "href"> & { href?: any }

export const CUIButton = forwardRef(function <T extends React.ElementType>(
    props: CUIButtonProps<T>,
    ref: React.Ref<HTMLButtonElement>
) {
    const variant = props.variant ? props.variant : "contained"
    return <Button {...props} variant={variant} ref={ref}></Button>
})

if (process.env.NODE_ENV !== "production") {
    const { href, ...propTypes } = (Button as any).propTypes

    ;(Button as any).propTypes = propTypes
}

export default CUIButton
