import { Alert, Grid, Paper } from "@mui/material"
import Typography from "@mui/material/Typography"
import { CUIAutocomplete } from "@puntaje/react/shared/cui"
import { CUIFormInput } from "@puntaje/react/shared/cui"
import { CUICheckbox } from "@puntaje/react/shared/cui"
import { Universidad, useCarrerasApi } from "@puntaje/react/carreras/api-services"
import { useQuery } from "@tanstack/react-query"
import { useWatch } from "react-hook-form"

/**
 *
 * @param editing
 * valor que permite determinar si se está creando o editando un clon
 */

export default function Registro({ editing }) {
    /**
     * Watchers para valores de configuracion del formulario
     */
    const enableCarreras = useWatch({
        name: "configuracion.registro.enableCarreras"
    })
    const enableTelefono = useWatch({
        name: "configuracion.registro.enableTelefono"
    })
    const enableNivel = useWatch({
        name: "configuracion.registro.enableNivel"
    })
    const enableColegio = useWatch({
        name: "configuracion.registro.enableColegio"
    })
    const enableFechaNacimiento = useWatch({
        name: "configuracion.registro.enableFechaNacimiento"
    })

    const { universidadesService } = useCarrerasApi()

    /**
     * Query para obtener todas las universidades de la api de carreras
     */
    const { data, isLoading } = useQuery(["universidades"], () => universidadesService.whereWithoutCount())

    /**
     * Filtra solo las universidades en data que contienen el string inputValue
     */
    const filtrarUniversidades = (inputValue: string) => {
        if (!isLoading) {
            const regExp = new RegExp(inputValue.toLowerCase().concat(".*"))
            return data.filter(u => regExp.test(u.universidad.toLowerCase()))
        } else return new Array<Universidad>()
    }

    return (
        <>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item sm={12}>
                        <Typography variant="h5" mb={2}>
                            Campos de registro
                        </Typography>
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIAutocomplete,
                                componentProps: { cuiTextProps: { label: "Platform" } },
                                name: "configuracion.registro.universidades[0]",
                                label: "Universidad registro",
                                optionsParams: {
                                    queryMethod: inputValue =>
                                        Promise.resolve(filtrarUniversidades(inputValue)).then(universidades =>
                                            universidades.map(u => u.universidad)
                                        ),
                                    queryName: `universidades`
                                },
                                tooltip:
                                    "Universidad que se usa para buscar las carreras en el registro de usuario. Solo para plataformas cuyo registro se pueda configurar para seleccionar carreras de preferencia. "
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUICheckbox,
                                componentProps: {},
                                name: "configuracion.registro.enableNivel",
                                label: "Habilitar curso / nivel",
                                tooltip:
                                    "Si se habilita, se le pedirá al usuario que seleccione su curso / nivel en el registro. Solo para plataformas cuyo registro se pueda habilitar el curso / nivel."
                            }}
                        />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUICheckbox,
                                componentProps: {},
                                name: "configuracion.registro.enableCarreras",
                                label: "Habilitar preferencia carreras",
                                tooltip:
                                    "Si se habilita, se le pedirá al usuario que seleccione sus carreras de preferencia de la universidad especificada antes en el registro. Importante: Plataformas que tienen un registro más personalizado y menos estándar puede que no consideren esta opción. P. ej: Puntaje Nacional"
                            }}
                        />
                        <CUIFormInput
                            inputBuilder={{
                                component: CUICheckbox,
                                componentProps: {},
                                name: "configuracion.registro.enableColegio",
                                label: "Habilitar colegio",
                                tooltip:
                                    "Si se habilita, se le pedirá al usuario que ingrese su colegio al que pertenece en el registro. Importante: Plataformas que tienen un registro más personalizado y menos estándar puede que no consideren esta opción. P. ej: Puntaje Nacional"
                            }}
                        />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUICheckbox,
                                componentProps: {},
                                name: "configuracion.registro.enableTelefono",
                                label: "Habilitar teléfono",
                                tooltip:
                                    "Si se habilita, se le pedirá al usuario que ingrese su teléfono en el registro. Importante: Plataformas que tienen un registro más personalizado y menos estándar puede que no consideren esta opción. P. ej: Puntaje Nacional"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUICheckbox,
                                componentProps: {},
                                name: "configuracion.registro.enableFechaNacimiento",
                                label: "Habilitar fecha de nacimiento",
                                tooltip:
                                    "Si se habilita, se le pedirá al usuario que ingrese su fecha de nacimiento en el registro. Importante: Plataformas que tienen un registro más personalizado y menos estándar puede que no consideren esta opción. P. ej: Puntaje Nacional"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={2} mb={2}>
                    <Grid item sm={12}>
                        <Typography variant="h5" mb={2}>
                            Campos obligatorios registro
                        </Typography>
                        <Alert severity="info">
                            Marca los campos que se considerarán obligatorios en el momento del registro. Los checkboxes
                            deshabilitados están siempre habilitados, y no se pueden deshabilitar.
                        </Alert>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={
                                {
                                    component: CUICheckbox,
                                    componentProps: {},
                                    name: "configuracion.registro.nombresObligatorio",
                                    label: "Nombres"
                                } as any
                            }
                        />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={
                                {
                                    component: CUICheckbox,
                                    componentProps: {},
                                    name: "configuracion.registro.apellidoPaternoObligatorio",
                                    label: "Apellido paterno"
                                } as any
                            }
                        />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={
                                {
                                    component: CUICheckbox,
                                    componentProps: {},
                                    name: "configuracion.registro.apellidoMaternoObligatorio",
                                    label: "Apellido materno"
                                } as any
                            }
                        />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={
                                {
                                    component: CUICheckbox,
                                    componentProps: {
                                        disabled: true
                                    },
                                    name: "configuracion.registro.idObligatorio",
                                    label: "ID Pais (RUT en Chile / TI,CC en Colombia / CURP en México)"
                                } as any
                            }
                        />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={
                                {
                                    component: CUICheckbox,
                                    componentProps: {
                                        disabled: true
                                    },
                                    name: "configuracion.registro.emailObligatorio",
                                    label: "Email"
                                } as any
                            }
                        />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={
                                {
                                    component: CUICheckbox,
                                    componentProps: {
                                        disabled: true
                                    },
                                    name: "configuracion.registro.contrasenaObligatorio",
                                    label: "Contraseña"
                                } as any
                            }
                        />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={
                                {
                                    component: CUICheckbox,
                                    componentProps: {
                                        disabled: true
                                    },
                                    name: "configuracion.registro.confirmacionContrasenaObligatorio",
                                    label: "Confirmación contraseña"
                                } as any
                            }
                        />
                    </Grid>
                    {enableCarreras && (
                        <Grid item sm={4} xs={6}>
                            <CUIFormInput
                                inputBuilder={
                                    {
                                        component: CUICheckbox,
                                        componentProps: {},
                                        name: "configuracion.registro.carrerasObligatorio",
                                        label: "Carrera"
                                    } as any
                                }
                            />
                        </Grid>
                    )}
                    {enableTelefono && (
                        <Grid item sm={4} xs={6}>
                            <CUIFormInput
                                inputBuilder={
                                    {
                                        component: CUICheckbox,
                                        componentProps: {},
                                        name: "configuracion.registro.telefonoObligatorio",
                                        label: "Teléfono"
                                    } as any
                                }
                            />
                        </Grid>
                    )}
                    {enableNivel && (
                        <Grid item sm={4} xs={6}>
                            <CUIFormInput
                                inputBuilder={
                                    {
                                        component: CUICheckbox,
                                        componentProps: {},
                                        name: "configuracion.registro.nivelObligatorio",
                                        label: "Curso / nivel"
                                    } as any
                                }
                            />
                        </Grid>
                    )}
                    {enableColegio && (
                        <Grid item sm={4} xs={6}>
                            <CUIFormInput
                                inputBuilder={
                                    {
                                        component: CUICheckbox,
                                        componentProps: {},
                                        name: "configuracion.registro.colegioObligatorio",
                                        label: "Colegio"
                                    } as any
                                }
                            />
                        </Grid>
                    )}
                    {enableFechaNacimiento && (
                        <Grid item sm={4} xs={6}>
                            <CUIFormInput
                                inputBuilder={
                                    {
                                        component: CUICheckbox,
                                        componentProps: {},
                                        name: "configuracion.registro.fechaNacimientoObligatorio",
                                        label: "Fecha de nacimiento"
                                    } as any
                                }
                            />
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </>
    )
}
