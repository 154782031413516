import { SxProps, styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { useContentLayoutOptions } from "./CUIContentLayoutProvider"
import React from "react"

export interface CUIHeadlineProps {
    children?: React.ReactNode
    sx?: SxProps
}

export function CUIHeadline(props: CUIHeadlineProps) {
    const { boxedContent } = useContentLayoutOptions()
    return (
        <Typography component="h1" variant="h2" sx={{ mb: 2, mt: boxedContent ? 0 : 2, ...props.sx }}>
            {props.children}
        </Typography>
    )
}

export default CUIHeadline
