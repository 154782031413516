import Polyglot from "node-polyglot"

type Join<K, P> = K extends string | number
    ? P extends string | number
        ? P extends ""
            ? `${K}`
            : `${K}.${P}`
        : never
    : never

type IsAny<T> = 0 extends 1 & T ? true : false

/**
 * Dado un tipo (como diccionario), devuelve el listado de paths posibles para ese diccionario
 *
 * Ejemplo:
 * type T = {
 *   a: {
 *    b: string
 *    c: {
 *      d: number
 *    }
 *   }
 * }
 *
 * Devuelve: "a" | "a.b" | "a.c" | "a.c.d"
 */
type PartialPaths<T, Full extends boolean = false> = IsAny<T> extends true
    ? string
    : T extends object
    ? {
          [K in keyof T]-?: K extends string | number
              ? (Full extends true ? never : `${K}`) | Join<K, PartialPaths<T[K]>>
              : never
      }[keyof T]
    : ""

/**
 * Dado un tipo (como diccionario), devuelve el listado de paths finales para ese diccionario
 *
 * Ejemplo:
 * type T = {
 *   a: {
 *    b: string
 *    c: {
 *      d: number
 *    }
 *   }
 * }
 *
 * Devuelve: "a.b" | "a.c.d"
 */
type FullPaths<T> = PartialPaths<T, true>

function deepMerge(...sources) {
    let acc = {}
    for (const source of sources) {
        if (source instanceof Array) {
            if (!(acc instanceof Array)) {
                acc = []
            }
            acc = { ...acc, ...source }
        } else if (source instanceof Object) {
            for (let [key, value] of Object.entries(source)) {
                if (value instanceof Object && key in acc) {
                    value = deepMerge(acc[key], value)
                }
                acc[key] = value
            }
        }
    }
    return acc
}

export interface CreateTranslatorOptions<T extends Record<string | number, any | string>> {
    dictionaries: {
        [locale: string]: T
    }
    dictionariesDependencies?: {
        [locale: string]: string
    }
    locale?: string
}

export function createTranslator<T extends Record<string | number, any | string>>({
    dictionaries,
    dictionariesDependencies = {},
    locale: defaultLocale = "es"
}: CreateTranslatorOptions<T>) {
    const arrayDependencies = locale => {
        const localeDependency = dictionariesDependencies[locale]
        if (localeDependency) {
            return [locale].concat(arrayDependencies(localeDependency))
        } else if (dictionaries[locale]) {
            return [locale]
        } else {
            return []
        }
    }

    const phrasesByLocale = locale => {
        let localeDependencies = arrayDependencies(locale).reverse()
        if (localeDependencies.length === 0) {
            localeDependencies = arrayDependencies(Object.keys(dictionaries)[0]).reverse()
        }
        const phrasesDependencies = localeDependencies.map(localeD => dictionaries[localeD])
        return deepMerge(...phrasesDependencies)
    }

    return function translate<V extends undefined | keyof T = undefined>(
        phrase: FullPaths<V extends undefined ? T : T[V]>,
        options = {},
        locale = defaultLocale,
        prefix?: V
    ): string {
        const phrases = phrasesByLocale(locale)
        const polyglot = new Polyglot({ locale, phrases, allowMissing: true })
        //TOdo: ronate ver el tipo del prefix
        // @ts-ignore
        return polyglot.t(((prefix as any) ? `${prefix as string}.${phrase}` : phrase) as any, options)
    }
}
