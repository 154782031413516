export interface ShowcaseBox {
    nombre: string
    identificador: string
    columna?: number
    orden?: number
    inputs?: { [inputName: string]: any }
}

export const showcaseBoxesAlumnos: ShowcaseBox[] = [
    {
        nombre: "Datos Usuario",
        identificador: "usuario-showcasebox"
    },
    {
        nombre: "Entrenador",
        identificador: "entrenador-showcasebox"
    },
    {
        nombre: "Menu box",
        identificador: "base-menu-showcasebox"
    },
    {
        nombre: "Notificaciones",
        identificador: "notificaciones-showcasebox"
    },
    {
        nombre: "Dudas",
        identificador: "dudas-showcase"
    },
    {
        nombre: "Noticias",
        identificador: "noticias-alumnos-showcasebox"
    },
    {
        nombre: "Banners",
        identificador: "arte-home-showcasebox"
    },
    {
        nombre: "Ensayos Mensuales",
        identificador: "ensayos-mensuales"
    }
]

export const showcaseBoxesProfesores: ShowcaseBox[] = [
    {
        nombre: "Datos Usuario",
        identificador: "usuario-showcasebox"
    },
    {
        nombre: "Noticias profesor",
        identificador: "noticias-showcasebox"
    },
    {
        nombre: "Menu box",
        identificador: "base-menu-showcasebox"
    },
    {
        nombre: "Cursos profesor",
        identificador: "grupo-usuarios-profesor-showcase"
    },
    {
        nombre: "Lista establecimientos",
        identificador: "lista-establecimientos-profesor"
    }
]
