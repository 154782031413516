import { createTranslator, CreateTranslatorOptions } from "@puntaje/shared/utils"

type Join<K, P> = K extends string | number
    ? P extends string | number
        ? P extends ""
            ? `${K}`
            : `${K}.${P}`
        : never
    : never

type IsAny<T> = 0 extends 1 & T ? true : false

/**
 * Dado un tipo (como diccionario), devuelve el listado de paths posibles para ese diccionario
 *
 * Ejemplo:
 * type T = {
 *   a: {
 *    b: string
 *    c: {
 *      d: number
 *    }
 *   }
 * }
 *
 * Devuelve: "a" | "a.b" | "a.c" | "a.c.d"
 */
type PartialPaths<T, Full extends boolean = false> = IsAny<T> extends true
    ? string
    : T extends object
    ? {
          [K in keyof T]-?: K extends string | number
              ? (Full extends true ? never : `${K}`) | Join<K, PartialPaths<T[K]>>
              : never
      }[keyof T]
    : ""

/**
 * Dado un tipo (como diccionario), devuelve el listado de paths finales para ese diccionario
 *
 * Ejemplo:
 * type T = {
 *   a: {
 *    b: string
 *    c: {
 *      d: number
 *    }
 *   }
 * }
 *
 * Devuelve: "a.b" | "a.c.d"
 */
type FullPaths<T> = PartialPaths<T, true>

export function makeUseTranslateLib<T extends Record<string | number, any | string>>({
    dictionaries,
    dictionariesDependencies = {},
    locale
}: CreateTranslatorOptions<T>) {
    // Create a translator instance using the new utility
    const translator = createTranslator<T>({
        dictionaries,
        dictionariesDependencies,
        locale: locale
    }) as any

    return function useTranslateLib<V extends undefined | keyof T = undefined>(prefix?: V, localeLib?: string) {
        return {
            t(phrase: FullPaths<V extends undefined ? T : T[V]>, options = {}): string {
                //TODO: ronate. Ver el tipo del prefix
                return translator(phrase, options, localeLib ?? locale, prefix)
            }
        }
    }
}
