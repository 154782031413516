import styled from "@emotion/styled"
import ArticleIcon from "@mui/icons-material/Article"

const BaseShowcasebox = styled.div`
    min-width: 350px;
    max-width: 300px;
`

const BaseShowcaseboxTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    background-color: var(--base-showcasebox_title_background_color);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    h2 {
        color: var(--base-showcasebox_title_color);
        margin: 0;
    }
`

const BaseShowcaseboxTitleIcon = styled.div`
    color: var(--base-showcasebox_icon_color);
    margin-right: 15px;
    font-size: 30px;
    display: flex;
    align-items: center;
`

const BaseShowcaseboxContent = styled.div`
    background-color: white;
    color: #333333;
    padding: 8px 15px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
`

export interface BaseShowcaseboxPreviewProps {}

export function BaseShowcaseboxPreview(props: BaseShowcaseboxPreviewProps) {
    return (
        <BaseShowcasebox>
            <BaseShowcaseboxTitle>
                <BaseShowcaseboxTitleIcon>
                    <ArticleIcon sx={{ fontSize: 30 }} />
                </BaseShowcaseboxTitleIcon>
                <h2>Showcase box</h2>
            </BaseShowcaseboxTitle>
            <BaseShowcaseboxContent>
                <p>
                    Aquí va el contenido. Este es el estilo de las cajitas con información que aparecen en el landing de
                    la página.
                </p>
            </BaseShowcaseboxContent>
        </BaseShowcasebox>
    )
}

export default BaseShowcaseboxPreview
