import { AxiosInstance, AxiosResponse } from "axios"
import { ApiOptions, ApiRequestOptions } from "./ApiInterfaces"

export class ApiRequest<T> {
    private resourceUrl: string = ""
    private onError401?: (error) => void
    private onTokenChange?: (token: string) => void

    constructor(private singularResource: string, private axiosInstance: AxiosInstance, options: ApiOptions) {
        Object.assign(this, options)
    }

    /**
     * Concatena `/resource` a la url actual
     *
     * @param resource String que representa el recurso
     * @returns Mismo objeto para encadenar llamadas
     */
    public collection(resource: string) {
        this.resourceUrl += `/${resource}`

        return this
    }

    /**
     *
     * @param resource String que representa el recurso
     * @param id Id del recurso
     * @returns Mismo objeto para encadenar llamadas
     */
    public member(resource: string, id: number) {
        this.resourceUrl += `/${resource}/${id}`

        return this
    }

    public get(params: any = {}, options: ApiRequestOptions = {}) {
        const { disableOnError401, ...axiosOptions } = options

        const axiosConfig = {
            ...axiosOptions,
            params
        }

        return this.createPromise(this.axiosInstance.get<T>(this.resourceUrl, axiosConfig), disableOnError401)
    }

    public post(params?: any, options: ApiRequestOptions = {}) {
        let { disableOnError401, notConcat, singularTableWrap = true, ...axiosOptions } = options
        notConcat = notConcat ?? params instanceof FormData

        let data = notConcat ? params : this.concatAttributeToChilds(params)

        if (!notConcat && singularTableWrap) {
            data = { [this.singularResource]: data }
        }

        const axiosConfig = {
            ...axiosOptions
        }

        return this.createPromise(this.axiosInstance.post<T>(this.resourceUrl, data, axiosConfig), disableOnError401)
    }

    public patch(params: any, options: ApiRequestOptions = {}) {
        let { disableOnError401, notConcat, singularTableWrap = true, ...axiosOptions } = options
        notConcat = notConcat ?? params instanceof FormData

        let data = notConcat ? params : this.concatAttributeToChilds(params)

        if (!notConcat && singularTableWrap) {
            data = { [this.singularResource]: data }
        }

        const axiosConfig = {
            ...axiosOptions
        }

        return this.createPromise(this.axiosInstance.patch<T>(this.resourceUrl, data, axiosConfig), disableOnError401)
    }

    public put(params: any, options: ApiRequestOptions = {}) {
        let { disableOnError401, notConcat, singularTableWrap = true, ...axiosOptions } = options
        notConcat = notConcat ?? params instanceof FormData

        let data = notConcat ? params : this.concatAttributeToChilds(params)

        if (!notConcat && singularTableWrap) {
            data = { [this.singularResource]: data }
        }

        const axiosConfig = {
            ...axiosOptions
        }

        return this.createPromise(this.axiosInstance.put<T>(this.resourceUrl, data, axiosConfig), disableOnError401)
    }

    public delete(params: any, options: ApiRequestOptions = {}) {
        const { disableOnError401, notConcat, ...axiosOptions } = options

        const axiosConfig = {
            ...axiosOptions,
            params
        }

        return this.createPromise(this.axiosInstance.delete<T>(this.resourceUrl, axiosConfig), disableOnError401)
    }

    private createPromise(promise: Promise<AxiosResponse<T>>, disableOnError401: boolean) {
        return new Promise<T>((resolve, reject) => {
            promise
                .then(response => {
                    const data = this.processResponse(response)

                    resolve(data)
                })
                .catch(error => {
                    if (!disableOnError401 && error.response && error.response.status == 401 && this.onError401) {
                        return this.onError401(error)
                    }

                    reject(error)
                })
        })
    }

    private processResponse(response: AxiosResponse<T>): T {
        if (response.headers["authorization"] && this.onTokenChange) {
            this.onTokenChange(response.headers["authorization"])
        }

        return response.data
    }

    private concatAttributeToChilds(object: any) {
        if (Object.prototype.toString.call(object) != "[object Object]") {
            return object
        }

        const objToReturn = {}

        for (const key in object) {
            if (Object.prototype.toString.call(object[key]) == "[object Object]") {
                if (!object[key]._notConcat) {
                    let model: any = object[key]
                    model = this.concatAttributeToChilds(model)

                    if (!object[key]._notConcatOnlySelf) {
                        objToReturn[key + "_attributes"] = model
                    } else {
                        objToReturn[key] = model
                    }
                } else {
                    objToReturn[key] = { ...object[key] }
                    delete objToReturn[key]._notConcat
                }
            } else if (Array.isArray(object[key])) {
                let isPrimitiveArray = true
                let arr: any = object[key].map(l => {
                    const lc = this.concatAttributeToChilds(l)

                    if (lc != l) {
                        isPrimitiveArray = false
                    }

                    return lc
                })

                if (!isPrimitiveArray && !object[key]._notConcatOnlySelf) {
                    objToReturn[key + "_attributes"] = arr
                } else {
                    objToReturn[key] = arr
                }
            } else if (key !== "_notConcat" && key !== "_notConcatOnlySelf") {
                objToReturn[key] = object[key]
            }
        }

        return objToReturn
    }
}
