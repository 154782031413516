import {
    useMaxError,
    useMaxLengthError,
    useMinError,
    useMinLengthError,
    usePatternError,
    useRequiredError
} from "@puntaje/react/shared/core"
import { RegisterOptions } from "react-hook-form"

export function useAddMessagesToRules() {
    const { requiredError } = useRequiredError()
    const { patternError } = usePatternError()
    const { minError } = useMinError()
    const { maxError } = useMaxError()
    const { maxLengthError } = useMaxLengthError()
    const { minLengthError } = useMinLengthError()

    return {
        addMessagesToRules: (
            rules: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">,
            label: string
        ) => {
            if (!rules) return rules

            return {
                ...rules,
                ...(rules.required === true && {
                    required: requiredError(label)
                }),
                ...(rules.pattern instanceof RegExp && {
                    pattern: {
                        value: rules.pattern,
                        message: patternError(label)
                    }
                }),
                ...(typeof rules.min !== "object" &&
                    rules.min !== undefined && {
                        min: {
                            value: rules.min,
                            message: minError(label, rules.min)
                        }
                    }),
                ...(typeof rules.max !== "object" &&
                    rules.max !== undefined && {
                        max: {
                            value: rules.max,
                            message: maxError(label, rules.max)
                        }
                    }),
                ...(typeof rules.minLength !== "object" &&
                    rules.minLength !== undefined && {
                        minLength: {
                            value: rules.minLength,
                            message: minLengthError(label, rules.minLength)
                        }
                    }),
                ...(typeof rules.maxLength !== "object" &&
                    rules.maxLength !== undefined && {
                        maxLength: {
                            value: rules.maxLength,
                            message: maxLengthError(label, rules.maxLength)
                        }
                    })
            }
        }
    }
}
