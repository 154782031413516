import { Areas } from "./Areas"
import { Audiovisuales } from "./Audiovisuales"
import { Carreras } from "./Carreras"
import { CarreraSedes } from "./CarreraSedes"
import { InfoAdicionales } from "./InfoAdicionales"
import { Lugares } from "./Lugares"
import { Ponderables } from "./Ponderables"
import { PreferenciaAreas } from "./PreferenciaAreas"
import { PreferenciaCarreraSedes } from "./PreferenciaCarreraSedes"
import { PreferenciaUniversidades } from "./PreferenciaUniversidades"
import { Sedes } from "./Sedes"
import { SolicitudInformaciones } from "./SolicitudInformaciones"
import { TipoAudiovisuales } from "./TipoAudiovisuales"
import { UniversidadRecomendadas } from "./UniversidadRecomendadas"
import { Universidades } from "./Universidades"

export const resources = {
    Areas,
    Audiovisuales,
    Carreras,
    CarreraSedes,
    InfoAdicionales,
    Lugares,
    Ponderables,
    PreferenciaAreas,
    PreferenciaCarreraSedes,
    PreferenciaUniversidades,
    Sedes,
    SolicitudInformaciones,
    TipoAudiovisuales,
    UniversidadRecomendadas,
    Universidades
}
