import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker"
import { forwardRef } from "react"
import { CUILocalizationProvider } from "../Utils"
import { CUIText, CUIStandardTextProps, CUIFilledTextProps, CUIOutlinedTextProps } from "./CUIText"

export interface CUIDatePickerProps extends Omit<DatePickerProps<Date, Date>, "renderInput"> {
    cuiTextProps?: CUIStandardTextProps | CUIFilledTextProps | CUIOutlinedTextProps
    disableTextInput?: boolean
    fullWidth?: boolean
    size?: "small" | "medium"
}

export const CUIDatePicker = forwardRef((props: CUIDatePickerProps, ref: any) => {
    const onKeyDown = props.disableTextInput ? event => event.preventDefault() : undefined
    const { cuiTextProps, size, fullWidth, ...restProps } = props

    return (
        <CUILocalizationProvider locale="es">
            <DatePicker
                renderInput={params => {
                    return (
                        <CUIText
                            onKeyDown={onKeyDown}
                            {...params}
                            {...cuiTextProps}
                            {...(size && { size })}
                            {...(fullWidth && { fullWidth })}
                        />
                    )
                }}
                {...restProps}
                ref={ref}
            />
        </CUILocalizationProvider>
    )
})
