import { useMemo, useRef, useState } from "react"
import { useQuery } from "@tanstack/react-query"

interface UseOptionsReturn {
    /**
     * Función para setear el valor del input en un autocomplete.
     *
     * @param value Valor del input en el autocomplete
     * @returns {void}
     */
    setInputValue: (value: string) => void

    /**
     * Lo que retorna `useQuery` de `react-query` al obtener las opciones.
     *
     * TODO(efrias): Mejorar el tipado de esto.
     */
    query: any

    /**
     * Si el campo tiene parametros para buscar opciones o no.
     *
     * Si tiene entonces usa `useQuery` de `react-query` para obtener las opciones.
     */
    hasOptionsParams: boolean

    /**
     * Opción seleccionada actualmente, para concatenarla al resto de las opciones.
     *
     * TODO(efrias): Mejorar el tipado de esto.
     */
    selectedOption: any

    /**
     * Función para setear la opción seleccionada.
     *
     * @param value Opción seleccionada
     * @returns {void}
     */
    setSelectedOption: (value: any) => void
}

/**
 * Hook usado internamente por `useCUIAutocompleteFormProps` y `useCUISelectFormProps` para manejar las opciones disponibles en el campo.
 * Útil para selects y autocompletes.
 * Usa hooks condicionales pero gracias a `useMemo` siempre se ejecuta la misma cantidad de hooks por render.
 *
 * TODO(efrias): Sacar los hooks condicionales
 *
 * @param name Nombre del campo de `useForm` de `react-hook-form`
 * @param value Valor actual del campo
 * @param inputBuilder Opciones del campo
 * @param simpleQueryKey Si es `false`, el listado de opciones a traer dependen del valor del input en el campo (para autocompletes). Si es `true` solo lo busca una vez
 * @returns {UseOptionsReturn} Objeto con el estado actual de las opciones.
 */
export function useOptionsForm(name, value, inputBuilder, simpleQueryKey = true): UseOptionsReturn {
    const firstFetch = useRef(true)
    const { optionsParams } = inputBuilder

    const hasOptionsParams = useMemo(() => !!optionsParams, [])

    // En caso de que cambie de no tener a tener optionsParams (o viceversa) se tira un error, para evitar posibles bugs.
    if (hasOptionsParams != !!optionsParams) {
        throw new Error(
            `useOptionsForm: Se pasó de ${hasOptionsParams ? "tener" : "no tener"} optionsParams a ${
                hasOptionsParams ? "no tener" : "tener"
            }. Esto no está soportado actualmente y `
        )
    }

    const [inputValue, setInputValue] = hasOptionsParams
        ? useState(() => {
              return typeof value === "string"
                  ? value
                  : value?.label
                  ? value.label
                  : inputBuilder.componentProps?.getOptionLabel?.(value) ?? value ?? ""
          })
        : []
    const [selectedOption, setSelectedOption] = useState(null)

    let query

    if (hasOptionsParams) {
        const queryKey =
            simpleQueryKey || inputBuilder.simpleQueryKey
                ? [optionsParams.queryName ?? name]
                : [optionsParams.queryName ?? name, inputValue]

        query = useQuery(queryKey, async () => {
            let initialOptions = []
            if (firstFetch.current && inputBuilder.fetchFirstValue && value) {
                initialOptions = await inputBuilder.fetchFirstValue(value)
                if (initialOptions) {
                    setSelectedOption(initialOptions)
                }
                initialOptions = initialOptions ?? []
                initialOptions = Array.isArray(initialOptions) ? initialOptions : [initialOptions]
            }

            firstFetch.current = false

            const options = await optionsParams.queryMethod(inputValue)

            return [...initialOptions, ...options]
        })
    }

    return {
        setInputValue,
        query,
        hasOptionsParams,
        selectedOption,
        setSelectedOption
    }
}
