import { MouseEvent } from "react"
import { useSlate } from "slate-react"
import { Button, Icon } from "./UI"
import { copyRichText } from "./utils"

export const CopyButton = () => {
    const editor = useSlate()
    const fragment = editor.getFragment()

    const activeIcon = fragment?.[0]?.["children"]?.[0]?.["text"] !== ""

    const handleCopy = (e: MouseEvent<HTMLInputElement>) => {
        e.preventDefault()
        copyRichText(editor)
    }

    return (
        <Button active={Boolean(activeIcon)} onClick={handleCopy}>
            <Icon title="Copiar">content_copy</Icon>
        </Button>
    )
}
