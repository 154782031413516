import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { CUIButton, CUICheckbox, CUIContentBox, CUIFormInput } from "@puntaje/react/shared/cui"
import { CUIText } from "@puntaje/react/shared/cui"
import { CUISelect } from "@puntaje/react/shared/cui"
import { Alert, Box, Paper } from "@mui/material"
import { useFieldArray } from "react-hook-form"
import { BaseFooterPreview, BaseNavbarPreview, BaseTopmenuPreview, Showcase } from "@puntaje/clonador/previewer"
import { ConfigToTheme } from "./ConfigToTheme/ConfigToTheme"
/**
 * Arreglo con lista de clases css para el select de clases
 */
const classes = [
    "matematica",
    "lenguaje",
    "historia",
    "ciencias",
    "biologia",
    "fisica",
    "quimica",
    "ciencias-ciudadania",
    "educacion-ciudadana",
    "matematicas",
    "lectura_critica",
    "competencias_ciudadanas",
    "ciencias_naturales",
    "ingles",
    "mate",
    "lectura",
    "redaccion",
    "asignatura1",
    "asignatura2",
    "asignatura3",
    "asignatura4",
    "asignatura5",
    "Mat",
    "Len",
    "historia-y-ciencias-sociales",
    "cs-para-la-ciudadania",
    "ed-ciudadana"
]

/**
 * Componente que renderea el tab de colores y estilo
 *
 * @param editing valor que permite determinar si se está creando o editando un clon
 */

export default function ColoresEstilo({ editing }) {
    const {
        fields: clasesConfig,
        append: appendClaseConfig,
        remove: removeClaseConfig
    } = useFieldArray({
        name: "configuracion.cssVariables.clasesConfig"
    })

    return (
        <>
            <Typography variant="h4" mb={2}>
                Colores de clases
            </Typography>
            <Paper sx={{ mb: 3, p: 2 }}>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12}>
                        <Alert severity="info">
                            Las clases sirven para asociarla a una asignatura en el administrador de la plataforma en la
                            sección de asignaturas. Por consiguiente las asignaturas van a tener un color de fondo y de
                            fuente asociado dependiente de la clase. No es necesario que el nombre de la clase sea igual
                            a la asignatura que se asigna, pero es recomendado que lo sea para que esté ordenado y sea
                            de fácil lectura.
                        </Alert>
                    </Grid>

                    <Grid item xs={12}>
                        <CUIButton onClick={() => appendClaseConfig({ clase: "", colorFondo: "", colorFuente: "" })}>
                            Agregar
                        </CUIButton>
                    </Grid>

                    <Grid item xs={12}>
                        {clasesConfig.map((claseConfig, index) => (
                            <Grid key={claseConfig.id} container spacing={2} mb={2} alignItems="center">
                                <Grid item md={4}>
                                    <CUIFormInput
                                        inputBuilder={{
                                            component: CUISelect,
                                            componentProps: {
                                                formControlProps: {
                                                    fullWidth: true
                                                }
                                            },
                                            name: `configuracion.cssVariables.clasesConfig[${index}].clase`,
                                            label: "Clase",
                                            options: classes,
                                            rules: {
                                                required: "Este campo es requerido"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <CUIFormInput
                                        inputBuilder={{
                                            component: CUIText,
                                            componentProps: {
                                                type: "color",
                                                fullWidth: true
                                            },
                                            name: `configuracion.cssVariables.clasesConfig[${index}].colorFondo`,
                                            label: "Color de fondo"
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <CUIFormInput
                                        inputBuilder={{
                                            component: CUIText,
                                            componentProps: {
                                                type: "color",
                                                fullWidth: true
                                            },
                                            name: `configuracion.cssVariables.clasesConfig[${index}].colorFuente`,
                                            label: "Color de fuente"
                                        }}
                                    />
                                </Grid>
                                <Grid item md={2}>
                                    <CUIButton onClick={() => removeClaseConfig(index)}>Eliminar</CUIButton>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
            <Typography variant="h4" mb={2}>
                Navbar
            </Typography>

            <Paper sx={{ mb: 3, p: 2 }}>
                <Typography variant="h5" mb={2}>
                    Preview
                </Typography>
                <Alert severity="info" sx={{ mb: 2 }}>
                    Preview del navbar a modo general. Puede que no todos los atributos se vean reflejados en el
                    preview.
                </Alert>

                <ConfigToTheme>
                    <BaseNavbarPreview />
                </ConfigToTheme>
            </Paper>
            <Paper sx={{ mb: 3, p: 2 }}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" mb={2}>
                            Colores
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true,
                                    sx: {
                                        mb: 2
                                    }
                                },
                                name: "configuracion.cssVariables.navbarColorFondo",
                                label: "Color de fondo"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true,
                                    sx: {
                                        mb: 2
                                    }
                                },
                                name: "configuracion.cssVariables.navbarColorFuenteHover",
                                label: "Color de fuente al poner el mouse encima"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.navbarColorIconoBotonMenu",
                                label: "Color de icono del menú"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true,
                                    sx: {
                                        mb: 2
                                    }
                                },
                                name: "configuracion.cssVariables.navbarColorFondoHover",
                                label: "Color de fondo al poner el mouse encima"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true,
                                    sx: {
                                        mb: 2
                                    }
                                },
                                name: "configuracion.cssVariables.navbarColorFondoBotonMenu",
                                label: "Color de fondo del menú"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.navbarColorIconoBotonMenuHover",
                                label: "Color de icono del menú al poner el mouse encima"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true,
                                    sx: {
                                        mb: 2
                                    }
                                },
                                name: "configuracion.cssVariables.navbarColorFuente",
                                label: "Color de fuente"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true,
                                    sx: {
                                        mb: 2
                                    }
                                },
                                name: "configuracion.cssVariables.navbarColorFondoBotonMenuHover",
                                label: "Color de fondo del menú al poner el mouse encima"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.navbarColorFondoAvatar",
                                label: "Color de fondo avatar"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ mb: 3, p: 2 }}>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" mb={2}>
                            Tamaños
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.navbarAlto",
                                label: "Alto",
                                tooltip: "En px. Ej: 50px",
                                rules: {
                                    pattern: {
                                        value: /\b(\d+)px\b/,
                                        message: 'El valor debe ser un número seguido de "px", por ejemplo "50px"'
                                    }
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.navbarAltoAvatar",
                                label: "Alto del avatar",
                                tooltip: "En px. Ej: 50px",
                                rules: {
                                    pattern: {
                                        value: /\b(\d+)px\b/,
                                        message: 'El valor debe ser un número seguido de "px", por ejemplo "50px"'
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.navbarAltoLogo",
                                label: "Alto del logo",
                                tooltip: "En px. Ej: 50px",
                                rules: {
                                    pattern: {
                                        value: /\b(\d+)px\b/,
                                        message: 'El valor debe ser un número seguido de "px", por ejemplo "50px"'
                                    }
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ mb: 3, p: 2 }}>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" mb={2}>
                            Otras variables
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {
                                    formControlProps: {
                                        fullWidth: true
                                    }
                                },
                                name: "configuracion.app.baseStyle.navbar.logo",
                                label: "Tipo de logo",
                                options: ["logoSmall", "logoSmallLight"],
                                tooltip: "Para poder contrastar el color del logo con el fondo"
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {
                                    formControlProps: {
                                        fullWidth: true
                                    }
                                },
                                name: "configuracion.app.baseStyle.navbar.logoMobile",
                                label: "Tipo de logo responsive",
                                options: ["logoSquare", "logoSquareLight"],
                                tooltip: "Para poder contrastar el color del logo con el fondo"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Typography variant="h4" mb={2}>
                Menú
            </Typography>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" mb={2}>
                    Preview
                </Typography>

                <Alert severity="info" sx={{ mb: 2 }}>
                    Preview del menú superior izquierdo. Puede que no todos los atributos se vean reflejados en el
                    preview.
                </Alert>

                <Box maxWidth="300px">
                    <ConfigToTheme>
                        <BaseTopmenuPreview />
                    </ConfigToTheme>
                </Box>
            </Paper>
            <Paper sx={{ mb: 3, p: 2 }}>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" mb={2}>
                            Tipo de menú
                        </Typography>
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {
                                    formControlProps: {
                                        fullWidth: true
                                    }
                                },
                                name: "configuracion.app.menuConfig.tipo",
                                label: "Tipo menú",
                                options: ["showcasebox", "horizontal"],
                                tooltip:
                                    "Si es horizontal, se mostrará en la parte superior de la página. Si es showcasebox, se mostrará un botón en la parte superior izquierda que muestra el menú."
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ mb: 3, p: 2 }}>
                <Grid container spacing={2} mb={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" mb={2}>
                            Colores
                        </Typography>
                    </Grid>

                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.menuColorFondo",
                                label: "Color de fondo"
                            }}
                        />
                    </Grid>

                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.menuColorFondoHover",
                                label: "Color de fondo al poner el mouse encima"
                            }}
                        />
                    </Grid>

                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.menuColorFuente",
                                label: "Color de fuente"
                            }}
                        />
                    </Grid>

                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.menuColorFuenteHover",
                                label: "Color de fuente al poner el mouse encima"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Typography variant="h4" mb={2}>
                Menú horizontal
            </Typography>
            <Paper sx={{ mb: 3, p: 2 }}>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" mb={2}>
                            Colores
                        </Typography>
                    </Grid>

                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.menuHorizontalColorFondo",
                                label: "Color de fondo"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.menuHorizontalColorFondoHover",
                                label: "Color de fondo al poner el mouse encima"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.menuHorizontalColorFuente",
                                label: "Color de fuente"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.menuHorizontalColorFuenteHover",
                                label: "Color de fuente al poner el mouse encima"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ mb: 3, p: 2 }}>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" mb={2}>
                            Otras variables
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {
                                    formControlProps: {
                                        fullWidth: true
                                    }
                                },
                                name: "configuracion.cssVariables.menuHorizontalMarcadorLinea",
                                label: "Marca menú actual con una línea",
                                options: ["true", "false"],
                                getOptionLabel: o => (o == "true" ? "Sí" : "No")
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {
                                    formControlProps: {
                                        fullWidth: true
                                    }
                                },
                                name: "configuracion.cssVariables.menuHorizontalLineaAbajo",
                                label: "Menú con línea de color abajo",
                                options: ["true", "false"],
                                getOptionLabel: o => (o == "true" ? "Sí" : "No")
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {
                                    formControlProps: {
                                        fullWidth: true
                                    }
                                },
                                name: "configuracion.cssVariables.menuHorizontalSinIcono",
                                label: "Menú horizontal con icono",
                                options: ["block", "none"],
                                getOptionLabel: o => (o == "block" ? "Sí" : "No")
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Typography variant="h4" mb={2}>
                Menú showcasebox
            </Typography>

            <Paper sx={{ mb: 3, p: 2 }}>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" mb={2}>
                            Colores y variables
                        </Typography>
                    </Grid>

                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true,
                                    sx: {
                                        mb: 2
                                    }
                                },
                                name: "configuracion.cssVariables.menuShowcaseboxColorFondo",
                                label: "Color de fondo"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.menuShowcaseboxIconoColorFuente",
                                label: "Color de icono"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true,
                                    sx: {
                                        mb: 2
                                    }
                                },
                                name: "configuracion.cssVariables.menuShowcaseboxColorFondoHover",
                                label: "Color de fondo al poner el mouse encima"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.menuShowcaseboxIconoColorFondo",
                                label: "Color de fondo donde va el ícono"
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true,
                                    sx: {
                                        mb: 2
                                    }
                                },
                                name: "configuracion.cssVariables.menuShowcaseboxColorFuente",
                                label: "Color de fuente"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {
                                    formControlProps: {
                                        fullWidth: true
                                    }
                                },
                                name: "configuracion.cssVariables.menuShowcaseboxConFlecha",
                                label: "Flecha menú",
                                options: ["block", "none"],
                                getOptionLabel: o => (o == "block" ? "Sí" : "No")
                            }}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true,
                                    sx: {
                                        mb: 2
                                    }
                                },
                                name: "configuracion.cssVariables.menuShowcaseboxColorFuenteHover",
                                label: "Color de fuente al poner el mouse encima"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {
                                    formControlProps: {
                                        fullWidth: true
                                    }
                                },
                                name: "configuracion.cssVariables.menuShowcaseboxRedondeado",
                                label: "Redondeado",
                                options: ["true", "false"],
                                getOptionLabel: o => (o == "true" ? "Sí" : "No")
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Typography variant="h4" mb={2}>
                Footer interno
            </Typography>

            <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" mb={2}>
                    Preview
                </Typography>

                <Alert severity="info" sx={{ mb: 2 }}>
                    Preview a modo general del footer. Puede que no todos los atributos se vean reflejados.
                </Alert>

                <ConfigToTheme>
                    <BaseFooterPreview />
                </ConfigToTheme>
            </Paper>
            <Paper sx={{ mb: 3, p: 2 }}>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" mb={2}>
                            Colores
                        </Typography>
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true,
                                    sx: {
                                        mb: 2
                                    }
                                },
                                name: "configuracion.cssVariables.footerColor",
                                label: "Color"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.footerColorSocial",
                                label: "Color fondo social media"
                            }}
                        />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true,
                                    sx: {
                                        mb: 2
                                    }
                                },
                                name: "configuracion.cssVariables.footerColorFuente",
                                label: "Color fuente"
                            }}
                        />

                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.footerColorSocialHover",
                                label: "Color fondo social al poner el mouse encima"
                            }}
                        />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "color",
                                    fullWidth: true
                                },
                                name: "configuracion.cssVariables.footerColorFuenteHover",
                                label: "Color fuente hover"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper sx={{ mb: 3, p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" mb={2}>
                            Otras variables
                        </Typography>
                    </Grid>

                    <Grid item sm={4} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {
                                    formControlProps: {
                                        fullWidth: true
                                    }
                                },
                                name: "configuracion.app.baseStyle.footer.logo",
                                label: "Tipo de logo",
                                options: ["logoSmall", "logoSmallLight"],
                                tooltip: "Para contrastar el logo con el color de fondo del footer."
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
