import { useTranslateLib } from "../i18n/useTranslateLib"

/**
 * Hook que retorna una función que retorna un mensaje de error si el valor es mayor al máximo
 *
 * @returns Objeto con la función `maxError` que retorna un mensaje de error si el valor es mayor al máximo
 */
export function useMaxError() {
    const { t } = useTranslateLib("use_max_error")

    return {
        maxError: (label: string, max: number | string) => t("max_error", { label, max })
    }
}
