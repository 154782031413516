import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider, LocalizationProviderProps } from "@mui/x-date-pickers/LocalizationProvider"

import enLocale from "date-fns/locale/en-US"
import ptLocale from "date-fns/locale/pt"
import esLocale from "date-fns/locale/es"

const localeMap = {
    en: enLocale,
    es: esLocale,
    pt: ptLocale
}

interface CUILocalizationProvider extends Omit<LocalizationProviderProps, "dateAdapter"> {}

export function CUILocalizationProvider({ locale, ...props }: CUILocalizationProvider) {
    locale ??= "es"

    if (typeof locale === "string") {
        locale = localeMap[locale.substring(0, 2)] ?? esLocale
    }

    return <LocalizationProvider adapterLocale={locale} {...props} dateAdapter={AdapterDateFns} />
}
