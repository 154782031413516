import { Logged } from "../layouts/Logged"
import { DetalleClon } from "../detalleclon/DetalleClon"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CUILoader } from "@puntaje/react/shared/cui"
import { useClonadorApi } from "@puntaje/clonador/api-services"
import { useQuery } from "@tanstack/react-query"
import { ClonConf } from "../types/configuration.types"
import { NuevoClonDefaultConfig } from "../detalleclon/NuevoClonDefaultConfig"
import { Navigations } from "../detalleclon/Navigations"

export function ConfiguracionEdit({}) {
    const { id } = useParams()
    const { configuracionesService } = useClonadorApi()
    const navigate = useNavigate()
    const { data, isLoading, remove } = useQuery([`configuraciones/${id}`], () =>
        configuracionesService.find(parseInt(id))
    )

    const [isSaving, setIsSaving] = useState(false)
    const [loadedConfig, setLoadedConfig] = useState(undefined)

    useEffect(() => {
        if (data) {
            // Aquí se carga la configuración del clon a editar en el formulario
            let config = { ...data } as any

            const loadedConfig = JSON.parse(JSON.stringify(config))
            loadedConfig.configuracion.registro ||= {}
            loadedConfig.configuracion.registro.universidades = loadedConfig.configuracion.registro?.universidades || []

            const processNavigations = (baseNavigations, loadedNavigations) => {
                const comparable = o =>
                    typeof o != "object" || !o
                        ? o
                        : Object.keys(o)
                              .sort()
                              .reduce((c, key) => ((c[key] = comparable(o[key])), c), {})

                const checkIfNavigationsEqual = (n1, n2) =>
                    n1.name == n2.name &&
                    n1.route == n2.route &&
                    n1.icon == n2.icon &&
                    (!n1.params ||
                        !n2.params ||
                        JSON.stringify(comparable(n1.params)) == JSON.stringify(comparable(n2.params)))

                const navigationsFromBase = baseNavigations.map(baseNavigation => ({
                    ...baseNavigation,
                    clonadorName: `${baseNavigation.clonadorName} (Base)`,
                    selected: loadedNavigations.some(loadedNavigation =>
                        checkIfNavigationsEqual(loadedNavigation, baseNavigation)
                    )
                }))

                const navigationsFromLoaded = loadedNavigations
                    .filter(
                        loadedNavigation =>
                            !baseNavigations.some(baseNavigation =>
                                checkIfNavigationsEqual(loadedNavigation, baseNavigation)
                            )
                    )
                    .map(loadedNavigation => ({
                        ...loadedNavigation,
                        clonadorName: `${loadedNavigation.name} (Personalizado)`,
                        selected: true
                    }))

                return [...navigationsFromBase, ...navigationsFromLoaded]
            }

            loadedConfig.configuracion.app.navigationByApp = loadedConfig.configuracion.app.navigationByApp || {}
            loadedConfig.configuracion.app.navigationByApp.alumnos =
                loadedConfig.configuracion.app.navigationByApp.alumnos || []
            loadedConfig.configuracion.app.navigationByApp.profesores =
                loadedConfig.configuracion.app.navigationByApp.profesores || []

            loadedConfig.configuracion.app.navigationByApp.alumnos = processNavigations(
                Navigations.alumnos,
                loadedConfig.configuracion.app.navigationByApp.alumnos
            )
            loadedConfig.configuracion.app.navigationByApp.profesores = processNavigations(
                Navigations.profesores,
                loadedConfig.configuracion.app.navigationByApp.profesores
            )

            // Acá desde la API viene un objeto donde cada clave es un evaluacionTipo, pero yo en el form manejo
            // un arreglo con los evaluacionTipo.
            // (este preprocesamiento del objeto está feo)
            const evaluacionTipos = loadedConfig.configuracion?.evaluaciones || {}
            let arrEvaluacionTipos = []
            Object.entries(evaluacionTipos).map((elem, index) => {
                let evaluacionTipo = elem[1] as any
                // El objeto evaluacionTipo tiene un miembro "evaluacionTipo" que uso para saber el nombre del evaluacionTipo
                evaluacionTipo.evaluacionTipo = elem[0]

                // Este objeto no lo puedo manejar de esta manera en react-hook-form,
                // así que lo transformo en un arreglo con elementos key y value
                let objReferencesTo = evaluacionTipo.clasificaciones?.referencesTo || {}
                let arrReferencesTo = []
                Object.entries(objReferencesTo).map((oElem, oIndex) => {
                    arrReferencesTo.push({
                        key: oElem[0],
                        value: oElem[1]
                    })
                })
                evaluacionTipo.clasificaciones.referencesTo = arrReferencesTo

                // Estos objetos necesitan ser arreglos para manejarlos en el multi-select,
                // pero vienen de la API como strings separados por comas
                if (evaluacionTipo.clasificaciones.estadisticasEvolutivas != undefined) {
                    if (typeof evaluacionTipo.clasificaciones?.estadisticasEvolutivas == "string") {
                        evaluacionTipo.clasificaciones.estadisticasEvolutivas = [
                            evaluacionTipo.clasificaciones.estadisticasEvolutivas
                        ]
                    }
                } else {
                    evaluacionTipo.clasificaciones.estadisticasEvolutivas = []
                }

                if (evaluacionTipo.clasificaciones.desempenoEvaluacion != undefined) {
                    if (typeof evaluacionTipo.clasificaciones.desempenoEvaluacion == "string") {
                        evaluacionTipo.clasificaciones.desempenoEvaluacion =
                            evaluacionTipo.clasificaciones.desempenoEvaluacion.split(",")
                    }
                } else {
                    evaluacionTipo.clasificaciones.desempenoEvaluacion = []
                }

                // Este campo viene desde la api como un arreglo de strings separados por comas,
                // pero en el form lo manejo como un arreglo bidimensional
                evaluacionTipo.clasificaciones.groupBy = (evaluacionTipo.clasificaciones?.groupBy || []).map(gb =>
                    gb.split(",")
                )

                evaluacionTipo.clasificaciones.planPersonalClasificacionMateriales =
                    evaluacionTipo.clasificaciones.planPersonalClasificacionMateriales || []

                arrEvaluacionTipos.push(evaluacionTipo as any)
            })
            loadedConfig.configuracion.evaluaciones = arrEvaluacionTipos

            setLoadedConfig(loadedConfig)
        }
    }, [data])

    /**
     * Esta función convierte una configuración de clon en el formato antiguo al formato nuevo.
     * No debería usarse puesto que la conversión se hace desde la API.
     * @param config La configuración del clon en el formato antiguo
     */
    const configurationConvert = (config): ClonConf => {
        const app = {
            assets: {
                logoLarge: config.configuracion?.logoLarge,
                logoMedium: config.configuracion?.logoMedium,
                logoSmall: config.configuracion?.logoSmall,
                logoLargeLight: config.configuracion?.logoLargeLight,
                logoMediumLight: config.configuracion?.logoMediumLight,
                logoSmallLight: config.configuracion?.logoSmallLight,
                logoSquare: config.configuracion?.logoSquare,
                logoSquareLight: config.configuracion?.logoSquareLight,
                streamingOffline: config.configuracion?.streamingOffline
            },
            name: config.plataforma,
            paths: {
                landing: undefined,
                pendingInfo: undefined
            },
            routeUser: undefined,
            routeUserInfo: undefined,
            baseStyle: {
                navbar: {
                    logo: undefined,
                    logoMobile: undefined
                },
                footer: {
                    logo: undefined,
                    logosExtras: [],
                    enableLogo: undefined
                },
                landing: {
                    backgroundImage: undefined,
                    logo: undefined,
                    subtitle: undefined,
                    subtitleHasShadow: undefined,
                    logoFooter: undefined
                },
                sharedLayout: {
                    backgroundImage: undefined,
                    logo: undefined
                },
                ayuda: {
                    showUploadDate: undefined,
                    showYoutubeButton: undefined
                }
            },
            navigationByApp: config.configuracion.appConfig.navigationByApp,
            showcasesByApp: config.configuracion.appConfig.showcasesByApp,
            menuConfig: {
                tipo: undefined,
                enableShowcaseMenuHomeDocentes: undefined,
                enableShowcaseMenuHomeAlumnos: undefined
            },
            informacionContacto: [],
            googleAnalyticsPropertyId: {
                alumnos: undefined,
                profesores: undefined,
                admin: undefined
            },
            facebookAnalyticsPropertyId: {
                alumnos: undefined,
                profesores: undefined,
                admin: undefined
            },
            googleTagManagerPropertyId: {
                alumnos: undefined,
                profesores: undefined,
                admin: undefined
            },
            globalSiteTag: {
                alumnos: {
                    propertyId: undefined,
                    sendTo: undefined
                },
                profesores: {
                    propertyId: undefined,
                    sendTo: undefined
                },
                admin: {
                    propertyId: undefined,
                    sendTo: undefined
                }
            },
            domain: undefined,
            facebook: undefined,
            recaptcha: undefined
        }

        const plataforma = {
            enableCURPestudiantes: undefined,
            hideReforzamientos: undefined,
            hideBarraAvanceEstimado: undefined,
            showMisCarreras: undefined,
            disableNivelCalugas: undefined,
            disableTodosLosEstablecimientos: undefined,
            hideReplicarPlan: undefined,
            showTextListado: undefined,
            showLinkAlumnoAlCompartir: undefined,
            disableButtonDuda: undefined,
            usuarioPaa: undefined,
            añoInicial: undefined,
            evaluacionDefault: config.configuracion.plataformaConfig.evaluacionTipoDefault,
            evaluacionResponder: config.configuracion.plataformaConfig.evaluacionTipoResponder,
            evaluacionInstanciasRoute: undefined,
            evaluacionTiposMenu: [...config.configuracion.plataformaConfig.evaluacionTipos],
            evaluacionTipoAdaptiveDefault: config.configuracion.plataformaConfig.evaluacionTipoAdaptiveDefault,
            evaluacionTiposMultiples: [],
            evaluacionesMultiplesRoute: undefined,
            grupoUsuarioAlias: undefined,
            clasificacionTipoNivel: undefined,
            numSesionesPlan: undefined,
            iconSet: {
                noticiasShowcase: undefined,
                establecimientosShowcase: undefined,
                cursosShowcase: undefined
            },
            cursosDefault: [],
            id: config.id,
            identificadorEstablecimiento: [],
            identificadorUsuario: [],
            identificadorUsuarioAlias: {},
            aliasIdentificadorHojaRespuesta: undefined,
            styleTheme: undefined,
            disableContactInfo: undefined,
            hojaRespuestaEjemploLink: undefined,
            info: { ...config.configuracion.plataformaConfig.info },
            instrumentoMensualTipo: undefined,
            lugarLabel: undefined,
            lugarTipoColegio: undefined,
            name: undefined,
            pais: undefined,
            periodos: [],
            resumenGeneral: {
                curriculum: undefined,
                generadores: []
            },
            streamingDefault: undefined,
            streamingDisableEnVivo: [],
            tiposInstrumentoInformeUso: [],
            tiposNotificacion: [],
            canTextHighlight: undefined,
            disableWarningOmitidas: undefined,
            nombreColumnaResultados: undefined,
            menuSesiones: undefined,
            canRecoverPasswordBySMS: undefined,
            afterUploadResultGoToViewPath: undefined,
            disableCompartirPresencial: undefined,
            disableZopim: undefined,
            sesionAlias: undefined,
            nombreSesion: undefined,
            nombreSesiones: undefined,
            planClasesCountInWeeks: undefined,
            planClasesRoute: undefined,
            planClasesUseNiveles: undefined,
            planClasesRouteDocentes: undefined,
            orderFiltroClasificaciones: undefined,
            enableCodigosLibros: undefined,
            disableSentimentMaterial: undefined,
            disableCalificacionPlanPersonalSession: undefined,
            disableTipoEvaluacionCompartir: undefined,
            showUserId: undefined,
            hideVolverARealizar: undefined,
            hideImprimirEvaluacion: undefined,
            showAyuda: undefined,
            hideCompartirImprimirEvaluacionMulti: undefined,
            hideOpcionesImpresionEvaluacionMulti: undefined,
            hideMessageSugerencia: undefined,
            pauta_imagen: undefined,
            textConPrueba: undefined,
            customErrorMessage: undefined,
            sortClasificacionesFiltro: undefined,
            hidePersonalizarByAsignatura: undefined,
            urlHojaRespuesta: undefined,
            loginGoogle: undefined,
            userIdTablaGrupoUsuarios: undefined,
            cursosStreaming: [],
            ordenAlternativasAleatorias: undefined,
            enableAchievements: undefined,
            evaluacionSecuencial: undefined,
            smowl: undefined,
            enableTipoEscala: undefined,
            enableNotaAprobacionEscala: undefined,
            locale: undefined,
            hideGuardarSinConfigurar: undefined,
            showFilterCET: undefined,
            planPersonalesDisableNivel: undefined,
            realizarButton: undefined,
            descargarButton: undefined,
            customGenerateTitle: undefined,
            title: {
                prefix: undefined,
                suffix: undefined
            },
            meta: {
                ogDescription: config.configuracion.metaTags["og:description"]
            }
        }

        let confConvertida = NuevoClonDefaultConfig.defaultConf
        confConvertida = {
            ...confConvertida,
            plataforma: config.plataforma,
            abreviatura_plataforma: config.abreviatura_plataforma,
            clonPNCL: config.configuracion?.clon && config.configuracion?.clon["puntaje-angular"],
            configuracion: {
                ...confConvertida.configuracion,
                app: app,
                evaluaciones: { ...config.configuracion.evaluacionTipoConfig },
                plataforma: plataforma,
                registro: { ...config.configuracion.registroConfig },
                generar: { ...config.configuracion.appConfig.generarConfig }
            }
        }
        return confConvertida
    }

    /**
     * Guarda los cambios del formulario en el clon
     * @param data Los datos de la configuración del clon
     * @param e
     */
    const onSubmit = (data, e) => {
        setIsSaving(true)
        configuracionesService.update(parseInt(id), data, { notConcat: true }).then(result => {
            remove()
            navigate("/configuraciones")
        })
    }
    const onError = e => console.log(e)

    return (
        <Logged title="Editar plataforma">
            <CUILoader
                loading={isLoading || isSaving}
                render={() => {
                    return <DetalleClon editing={true} loadedConfig={loadedConfig} onSubmit={onSubmit} />
                }}
            />
        </Logged>
    )
}
