import { ChangeEvent, useContext, useState } from "react"
import { ReactEditor, useFocused, useSelected, useSlate, useSlateStatic } from "slate-react"
import { Transforms } from "slate"
import isUrl from "is-url"
import { css } from "@emotion/css"

import { EditorContext, IEditorContext } from "./EditorProvider"
import { getElements } from "./utils"
import { Button, Icon } from "./UI"
import { CUIDialog, CUIDialogActions, CUIDialogContent } from "../../Feedback"
import { CUIText } from "../CUIText"
import CUIButton from "../CUIButton"
import { ImageElement } from "../custom-types"
import { Box, Grid, MenuItem, styled, Typography } from "@mui/material"
import { CUITab, CUITabPanel, CUITabs } from "../../Navigation"
import CUISelect from "../CUISelect"

const TabsContainer = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.neutral.main}`
}))

interface IImageProps {
    onUpload: ((value: File) => Promise<string>) | undefined
}

export const ImageButton = ({ onUpload }: IImageProps): JSX.Element => {
    const editor = useSlate()
    const { state, actions } = useContext<IEditorContext>(EditorContext)
    const {
        imageUrl: url,
        imageHeight,
        imageWidth,
        isOpenImageDialog,
        imageAlt,
        imageBorder,
        imgMarginHorizontal,
        imgMarginVertical,
        imgFloat
    } = state

    const { openImageDialog, closeDialog, setField } = actions

    const [currentTab, setCurrentTab] = useState(1)
    const [file, setFile] = useState<File>()
    const [imgEntry] = getElements(editor, "image")

    const handleTabs = (event: React.SyntheticEvent, currentValue: number) => {
        setCurrentTab(currentValue)
    }

    const handleOnClose = () => {
        if (file) {
            setFile(null)
        }
        closeDialog("Image")
    }

    const handleAccept = async () => {
        const fixedUrl = url.includes("http") ? url : url ? "http://" + url : url
        const validDimensions = imageHeight > 0 && imageWidth > 0

        const extraOptions = {
            width: imageWidth,
            height: imageHeight,
            alt: imageAlt,
            border: imageBorder,
            float: imgFloat,
            marginHorizontal: imgMarginHorizontal,
            marginVertical: imgMarginVertical
        }

        const defaultImageData: ImageElement = {
            type: "image",
            url,
            children: [{ text: "" }],
            ...extraOptions
        }

        const insertNewImage = () => {
            Transforms.insertNodes(editor, defaultImageData)
        }

        const updateImage = url => {
            Transforms.setNodes(
                editor,
                {
                    url,
                    ...extraOptions
                },
                { at: imgEntry[1] }
            )
        }

        if (fixedUrl && isUrl(fixedUrl) && validDimensions && currentTab === 1) {
            if (imgEntry) {
                updateImage(fixedUrl)
            } else {
                insertNewImage()
            }

            handleOnClose()
        }

        if (file && currentTab === 2) {
            const newImage = await onUpload(file)
            defaultImageData.url = newImage

            if (imgEntry) {
                updateImage(newImage)
            } else {
                insertNewImage()
            }
            handleOnClose()
        }
    }

    const handleImageSelection = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file: File = event.target.files[0]
            setFile(file)
        }
    }

    return (
        <>
            <Button active={!!imgEntry} onMouseDown={openImageDialog}>
                <Icon title="Insertar Imagen">image</Icon>
            </Button>
            <CUIDialog onClose={handleOnClose} open={isOpenImageDialog}>
                <TabsContainer>
                    <CUITabs value={currentTab} onChange={handleTabs}>
                        <CUITab value={1} label="Añadir link" />
                        <CUITab value={2} label="Subir imagen" />
                    </CUITabs>
                </TabsContainer>
                <CUIDialogContent sx={{ height: "300px" }}>
                    <CUITabPanel value={currentTab} index={1}>
                        <CUIText
                            label="Imagen URL"
                            value={url}
                            onChange={e => setField("imageUrl", e.target.value)}
                            margin="dense"
                            fullWidth
                            size="small"
                            required
                        />
                        <Grid container columnSpacing={2}>
                            <Grid item xs={6}>
                                <CUIText
                                    label="Ancho"
                                    type="number"
                                    value={imageWidth}
                                    onChange={e => setField("imageWidth", e.target.value)}
                                    margin="dense"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CUIText
                                    label="Alto"
                                    type="number"
                                    value={imageHeight}
                                    onChange={e => setField("imageHeight", e.target.value)}
                                    margin="dense"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" marginY={2}>
                            <Grid item xs={3}>
                                <Typography variant="overline" sx={{ marginTop: "56" }}>
                                    Campos opcionales
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <hr />
                            </Grid>
                        </Grid>
                        <CUIText
                            label="Texto alternativo"
                            value={imageAlt}
                            onChange={e => setField("imageAlt", e.target.value)}
                            margin="dense"
                            fullWidth
                            size="small"
                        />
                        <Grid container columnSpacing={2}>
                            <Grid item xs={6}>
                                <CUIText
                                    label="Margen Vertical"
                                    value={imgMarginVertical}
                                    onChange={e => setField("imgMarginVertical", e.target.value)}
                                    type="number"
                                    margin="dense"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CUIText
                                    label="Margen Horizontal"
                                    value={imgMarginHorizontal}
                                    onChange={e => setField("imgMarginHorizontal", e.target.value)}
                                    type="number"
                                    margin="dense"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={2} justifyContent="center">
                            <Grid item xs={6}>
                                <CUIText
                                    label="Borde"
                                    value={imageBorder}
                                    onChange={e => setField("imageBorder", e.target.value)}
                                    type="number"
                                    margin="dense"
                                    fullWidth
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CUISelect
                                    variant="outlined"
                                    value={imgFloat}
                                    onChange={e => setField("imgFloat", e.target.value as string)}
                                    size="small"
                                    label="Posición"
                                    formControlProps={{ style: { marginTop: "8px" } }}
                                >
                                    <MenuItem value={"none"}>Sin posición definida</MenuItem>
                                    <MenuItem value={"left"}>Izquierda</MenuItem>
                                    <MenuItem value={"right"}>Derecha</MenuItem>
                                </CUISelect>
                            </Grid>
                        </Grid>
                    </CUITabPanel>
                    <CUITabPanel value={currentTab} index={2}>
                        <Grid container gap={1} alignItems="center">
                            <Grid item>
                                <CUIButton variant="outlined" component="label">
                                    Seleccionar Imagen
                                    <input
                                        onChange={handleImageSelection}
                                        accept="image/*"
                                        style={{ display: "none", visibility: "hidden" }}
                                        type="file"
                                    />
                                </CUIButton>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{file?.name || "Sin archivos seleccionados"}</Typography>
                            </Grid>
                        </Grid>
                    </CUITabPanel>
                </CUIDialogContent>
                <CUIDialogActions>
                    <CUIButton onMouseDown={handleOnClose}>Cancelar</CUIButton>
                    <CUIButton onMouseDown={handleAccept}>Aceptar</CUIButton>
                </CUIDialogActions>
            </CUIDialog>
        </>
    )
}

export const Image = ({ attributes, children, element }): JSX.Element => {
    const { actions } = useContext(EditorContext)
    const editor = useSlateStatic()
    const path = ReactEditor.findPath(editor, element)
    const selected = useSelected()
    const focused = useFocused()

    // TODO: (Sandra) Lo del vertical margin y horizontal margin no está funcionando y debería
    // deprecarse y usarse style en su lugar
    // también falta que se muestren otros estilos que pueda tener la imagen, y que se pueda
    // editar el estilo en el popup y se guarde dentro del estilo, no como atributos separados del element

    return (
        <span {...attributes} onDoubleClick={actions.openImageDialog}>
            {children}
            <span
                contentEditable={false}
                style={{
                    display: "inline-block",
                    position: "relative",
                    float: element.float
                }}
            >
                <img
                    src={element.url}
                    alt={element.alt}
                    height={element.height}
                    width={element.width}
                    style={{
                        float: element.float,
                        border: `${element.border}px solid black`,
                        margin: `${element.marginVertical}px ${element.marginHorizontal}px`,
                        boxShadow: `${selected && focused ? "0 0 0 3px #B4D5FF" : "none"}`
                    }}
                />
                {selected && focused && (
                    <Button
                        active
                        onClick={() => Transforms.removeNodes(editor, { at: path })}
                        className={css`
                            display: inline;
                            position: absolute;
                            top: ${element.marginVertical || 5}px;
                            left: ${element.marginHorizontal || 5}px;
                            background-color: white;
                            border-radius: 3px;
                        `}
                    >
                        <Icon>delete</Icon>
                    </Button>
                )}
            </span>
        </span>
    )
}
