import { FormGroup } from "@mui/material"
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel"
import Switch, { SwitchProps } from "@mui/material/Switch"
import { forwardRef } from "react"

export interface CUISwitchProps extends SwitchProps {
    controlLabelProps?: Omit<FormControlLabelProps, "control">
}

export const CUISwitch = forwardRef(({ controlLabelProps, ...props }: CUISwitchProps, ref: any) => {
    return controlLabelProps ? (
        <FormGroup>
            <FormControlLabel
                sx={{ paddingLeft: "11px" }}
                control={<Switch {...props} ref={ref} />}
                {...controlLabelProps}
            />
        </FormGroup>
    ) : (
        <Switch {...props} ref={ref} />
    )
})
