import { MouseEvent } from "react"
import { Editor } from "slate"
import { useSlate } from "slate-react"
import { Button, Icon } from "./UI"

export const ClearButton = () => {
    const editor = useSlate()
    const formats = ["bold", "italic", "underline", "strikethrough", "code", "superscript", "subscript"]

    const handleOnClick = (e: MouseEvent<HTMLInputElement>) => {
        e.preventDefault()
        formats.forEach(format => Editor.removeMark(editor, format))

        Editor.addMark(editor, "fontFamily", "Lato, Roboto, Helvetica Neue, Arial, sans-serif")
        Editor.addMark(editor, "fontSize", "16px")
    }

    return (
        <Button active={false} onMouseDown={handleOnClick}>
            <Icon title="Eliminar formato">format_clear</Icon>
        </Button>
    )
}
