import TextField from "@mui/material/TextField"
import { StandardTextFieldProps, FilledTextFieldProps, OutlinedTextFieldProps } from "@mui/material/TextField"
import { forwardRef } from "react"

export type CUIStandardTextProps = StandardTextFieldProps

export type CUIOutlinedTextProps = OutlinedTextFieldProps

export type CUIFilledTextProps = FilledTextFieldProps

export const CUIText = forwardRef<HTMLDivElement, CUIStandardTextProps | CUIOutlinedTextProps | CUIFilledTextProps>(
    (props, ref) => {
        return (
            <TextField {...props} ref={ref}>
                {props?.children}
            </TextField>
        )
    }
)
