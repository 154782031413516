import { KeyboardEvent } from "react"
import { HistoryEditor } from "slate-history"
import { useSlate } from "slate-react"
import { Button, Icon } from "./UI"

interface IProps {
    action: string
    title: string
}

export const HistoryButton = ({ action, title }: IProps) => {
    const editor = useSlate()

    return (
        <Button
            active={false}
            onMouseDown={(event: KeyboardEvent<HTMLSpanElement>) => {
                event.preventDefault()
                HistoryEditor[action](editor)
            }}
        >
            <Icon title={title}>{action}</Icon>
        </Button>
    )
}
