import { Divider, Grid, Paper, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd"
import { useMemo, useState } from "react"
import { CUIList, CUIListItem, CUIListItemText } from "@puntaje/react/shared/cui"
import { Control, useController } from "react-hook-form"
import { ShowcaseBox, showcaseBoxesAlumnos, showcaseBoxesProfesores } from "./showcases"

const CUIListHeightened = styled(CUIList)(({ theme }) => ({
    minHeight: "200px"
}))

const CUIListItemColor = styled(CUIListItem, { shouldForwardProp: prop => prop != "isDragging" })<{
    isDragging: boolean
}>(({ theme, isDragging }) => ({
    backgroundColor: isDragging ? theme.palette.grey[100] : "white"
}))

interface SeccionesHomeProps {}

function useBoxColumna(boxes: ShowcaseBox[], columna) {
    return useMemo(() => {
        return boxes.filter(box => box?.columna === columna)
    }, [boxes, columna])
}

function DroppableWithBoxes({ droppableId, boxes, title }) {
    return (
        <Grid item md={3}>
            <Typography>{title}</Typography>

            <Paper>
                <Droppable droppableId={droppableId}>
                    {provided => (
                        <CUIListHeightened ref={provided.innerRef} {...provided.droppableProps}>
                            {boxes.map((box, index) => (
                                <Draggable key={box.identificador} draggableId={box.identificador} index={index}>
                                    {(provided, snapshot) => (
                                        <>
                                            <CUIListItemColor
                                                isDragging={snapshot.isDragging}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                role="listitem"
                                            >
                                                <CUIListItemText primary={box.nombre} />
                                            </CUIListItemColor>
                                            {!snapshot.isDragging && <Divider />}
                                        </>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </CUIListHeightened>
                    )}
                </Droppable>
            </Paper>
        </Grid>
    )
}

function onDragEndBuilder(boxes: ShowcaseBox[], setBoxes: Function, onChange: Function) {
    return result => {
        const { destination, source, draggableId } = result

        if (!destination) {
            return
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return
        }

        const newBoxes = [...boxes]
        const box = newBoxes.find(box => box.identificador === draggableId)

        const draggableIdToBoxesMap = {
            disabled: newBoxes.filter(box => box.columna === null),
            0: newBoxes.filter(box => box.columna === 0),
            1: newBoxes.filter(box => box.columna === 1),
            2: newBoxes.filter(box => box.columna === 2)
        }

        const sourceColumn = draggableIdToBoxesMap[source.droppableId]
        sourceColumn.splice(source.index, 1)

        box.columna = destination.droppableId === "disabled" ? null : parseInt(destination.droppableId)
        box.orden = destination.index

        const destinationColumn = draggableIdToBoxesMap[destination.droppableId]
        destinationColumn.splice(destination.index, 0, box)

        draggableIdToBoxesMap[source.droppableId] = draggableIdToBoxesMap[source.droppableId].map((box, index) => ({
            ...box,
            orden: index
        }))
        draggableIdToBoxesMap[destination.droppableId] = draggableIdToBoxesMap[destination.droppableId].map(
            (box, index) => ({ ...box, orden: index })
        )

        const reorderedBoxes = Object.values(draggableIdToBoxesMap).flat()

        setBoxes(reorderedBoxes)
        onChange(reorderedBoxes.filter(box => box.columna !== null))
    }
}

export function SeccionesHome() {
    const {
        field: { onChange: onChangeAlumnos, value: valueAlumnos }
    } = useController({
        name: "configuracion.app.showcasesByApp.alumnos",
        defaultValue: []
    })

    const [boxesAlumnos, setBoxesAlumnos] = useState<ShowcaseBox[]>(() => {
        return showcaseBoxesAlumnos
            .filter(box => (valueAlumnos ?? []).every(oBox => box.identificador != oBox.identificador))
            .map((box, index) => ({
                ...box,
                columna: null,
                orden: index
            }))
            .concat(
                (valueAlumnos ?? []).map(va => {
                    return {
                        ...va,
                        orden: parseInt(va.orden),
                        columna: parseInt(va.columna)
                    }
                })
            )
    })

    const boxesAlumnosDisabled = useBoxColumna(boxesAlumnos, null)
    const boxesAlumnosColumna1 = useBoxColumna(boxesAlumnos, 0)
    const boxesAlumnosColumna2 = useBoxColumna(boxesAlumnos, 1)
    const boxesAlumnosColumna3 = useBoxColumna(boxesAlumnos, 2)

    const {
        field: { onChange: onChangeProfesores, value: valueProfesores }
    } = useController({
        name: "configuracion.app.showcasesByApp.profesores",
        defaultValue: []
    })

    const [boxesProfesores, setBoxesProfesores] = useState<ShowcaseBox[]>(() => {
        return showcaseBoxesProfesores
            .filter(box => (valueProfesores ?? []).every(oBox => box.identificador != oBox.identificador))
            .map((box, index) => ({
                ...box,
                columna: null,
                orden: index
            }))
            .concat(
                (valueProfesores ?? []).map(va => {
                    return {
                        ...va,
                        orden: parseInt(va.orden),
                        columna: parseInt(va.columna)
                    }
                })
            )
    })

    const boxesProfesoresDisabled = useBoxColumna(boxesProfesores, null)
    const boxesProfesoresColumna1 = useBoxColumna(boxesProfesores, 0)
    const boxesProfesoresColumna2 = useBoxColumna(boxesProfesores, 1)
    const boxesProfesoresColumna3 = useBoxColumna(boxesProfesores, 2)

    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <Typography variant="h5" mb={2}>
                    Showcase boxes del home
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="h6" mb={2}>
                    Alumnos
                </Typography>
            </Grid>
            <Grid item container spacing={2} data-testid="drags-alumnos">
                <DragDropContext onDragEnd={onDragEndBuilder(boxesAlumnos, setBoxesAlumnos, onChangeAlumnos)}>
                    <DroppableWithBoxes title="Deshabilitados" droppableId="disabled" boxes={boxesAlumnosDisabled} />
                    <DroppableWithBoxes title="Columna 1" droppableId="0" boxes={boxesAlumnosColumna1} />
                    <DroppableWithBoxes title="Columna 2" droppableId="1" boxes={boxesAlumnosColumna2} />
                    <DroppableWithBoxes title="Columna 3" droppableId="2" boxes={boxesAlumnosColumna3} />
                </DragDropContext>
            </Grid>
            <Grid item md={12}>
                <Typography variant="h6" mb={2}>
                    Profesores
                </Typography>
            </Grid>
            <Grid item container spacing={2} data-testid="drags-profesores">
                <DragDropContext onDragEnd={onDragEndBuilder(boxesProfesores, setBoxesProfesores, onChangeProfesores)}>
                    <DroppableWithBoxes title="Deshabilitados" droppableId="disabled" boxes={boxesProfesoresDisabled} />
                    <DroppableWithBoxes title="Columna 1" droppableId="0" boxes={boxesProfesoresColumna1} />
                    <DroppableWithBoxes title="Columna 2" droppableId="1" boxes={boxesProfesoresColumna2} />
                    <DroppableWithBoxes title="Columna 3" droppableId="2" boxes={boxesProfesoresColumna3} />
                </DragDropContext>
            </Grid>
        </Grid>
    )
}
