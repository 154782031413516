import MenuItem from "@mui/material/MenuItem"
import { useOptionsForm } from "./useOptionsForm"
import CircularProgress from "@mui/material/CircularProgress"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { styled } from "@mui/material/styles"
import { ControllerFieldState, ControllerRenderProps, FieldValues } from "react-hook-form"

function getOptionLabel(option, inputBuilder) {
    return inputBuilder.getOptionLabel
        ? inputBuilder.getOptionLabel(option)
        : typeof option === "string"
        ? option
        : option.label
        ? option.label
        : option[Object.keys(option)[0]]
}

function getOptionId(option, optionLabel) {
    return typeof option === "string" ? option : option?.id ?? optionLabel
}

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    position: "absolute",
    right: theme.spacing(1),
    flexShrink: 0
}))

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
    position: "absolute",
    right: theme.spacing(1),
    flexShrink: 0,
    pointerEvents: "none"
}))

export const useCUISelectFormProps = (
    {
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
    }: {
        field: ControllerRenderProps<FieldValues, string>
        fieldState: ControllerFieldState
    },
    inputBuilder
) => {
    const { query, hasOptionsParams } = useOptionsForm(name, value, inputBuilder)

    const options = query ? (query.data ? query.data : []) : inputBuilder.options

    const loading = query ? query.isLoading : false

    const menuItems = options.map((option, i) => {
        const optionLabel = getOptionLabel(option, inputBuilder)
        const optionId = getOptionId(option, optionLabel)

        return !inputBuilder.componentProps?.native ? (
            <MenuItem key={optionId} value={optionId}>
                {optionLabel}
            </MenuItem>
        ) : (
            <option key={optionId} value={optionId}>
                {optionLabel}
            </option>
        )
    })

    const children = [
        ...(inputBuilder.placeholder
            ? !inputBuilder.componentProps?.native
                ? [
                      <MenuItem key="placeholder" value={""}>
                          {inputBuilder.placeholder}
                      </MenuItem>
                  ]
                : [
                      <option key="placeholder" value={""}>
                          {inputBuilder.placeholder}
                      </option>
                  ]
            : []),
        ...menuItems
    ]

    const possibleValue = inputBuilder.emitId
        ? value ?? ""
        : inputBuilder.componentProps?.multiple
        ? (value ?? []).map(selectedOption => {
              const optionLabel = getOptionLabel(selectedOption, inputBuilder)
              const optionId = getOptionId(selectedOption, optionLabel)

              return optionId
          })
        : getOptionId(value ?? "", getOptionLabel(value ?? "", inputBuilder))

    return {
        ...inputBuilder.componentProps,
        onChange: (event: any) => {
            if (inputBuilder.componentProps?.multiple) {
                const selectedOptions = options.filter((option, i) => {
                    const optionLabel = getOptionLabel(option, inputBuilder)
                    const optionId = getOptionId(option, optionLabel)

                    const selected =
                        typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value

                    return selected.includes(optionId)
                })

                if (inputBuilder.emitId) {
                    onChange(selectedOptions.map(option => getOptionId(option, getOptionLabel(option, inputBuilder))))
                } else if (inputBuilder.mapOption) {
                    onChange(selectedOptions.map(option => inputBuilder.mapOption(option, value)))
                } else {
                    onChange(selectedOptions)
                }
            } else {
                const option = options.find((option, i) => {
                    const optionLabel = getOptionLabel(option, inputBuilder)
                    const optionId = getOptionId(option, optionLabel)

                    return optionId === event.target.value
                })

                if (inputBuilder.emitId) {
                    onChange(getOptionId(option, getOptionLabel(option, inputBuilder)))
                } else if (inputBuilder.mapOption) {
                    onChange(inputBuilder.mapOption(option, value))
                } else {
                    onChange(option)
                }
            }
        },
        IconComponent: () =>
            loading ? <StyledCircularProgress color="inherit" size={20} /> : <StyledArrowDropDownIcon />,
        inputProps: {
            ...inputBuilder.componentProps?.inputProps,
            onBlur
        },
        formControlProps: {
            ...inputBuilder.componentProps?.formControlProps,
            sx: {
                ...inputBuilder.componentProps?.formControlProps?.sx
            },
            error: error != undefined || undefined
        },
        displayEmpty: !!inputBuilder.placeholder,
        label: inputBuilder.placeholder ? undefined : inputBuilder.label, // no se le pone label a un select con placeholder dado que el label overlapearia con el placeholder
        value: hasOptionsParams ? (query.isLoading ? "" : possibleValue) : possibleValue,
        ref,
        children,
        helpertext: error != undefined ? error.message : undefined,
        required: inputBuilder.rules?.required != null && inputBuilder.rules?.required != undefined
    }
}
