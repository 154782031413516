import { Asignaturas } from "./Asignaturas"
import { ClasificacionTipos } from "./ClasificacionTipos"
import { Usuarios } from "./Usuarios"
import { Plataformas } from "./Plataformas"
import { ReporteRazones } from "./ReporteRazones"
import { TipoInstrumentoPlataformas } from "./TipoInstrumentoPlataformas"
import { TipoInstrumentos } from "./TipoInstrumentos"
import { Clasificaciones } from "./Clasificaciones"
import { Materiales } from "./Materiales"
import { GeneradorInstrumentos } from "./GeneradorInstrumentos"
import { GeneradorInstrumentoInstrucciones } from "./GeneradorInstrumentoInstrucciones"
import { Preguntas } from "./Preguntas"
import { S3Policy } from "@puntaje/react/shared/core"
import { Reportes } from "./Reportes"
import { ClasificacionMateriales } from "./ClasificacionMateriales"
import { Alternativas } from "./Alternativas"
import { CertificacionRecursos } from "./CertificacionRecursos"
import { Personas } from "./Personas"
import { PlanEstudios } from "./PlanEstudios"
import { AsignaturaPlataformas } from "./AsignaturaPlataformas"
import { ContestableTipos } from "./ContestableTipos"
import { MaterialTipos } from "./MaterialTipos"
import { Ayudas } from "./Ayudas"
import { LotePreguntas } from "./LotePreguntas"
import { Lotes } from "./Lotes"
import { ClasificacionTipoAliases } from "./ClasificacionTipoAliases"

export const resources = {
    Asignaturas,
    Usuarios,
    ClasificacionTipos,
    Plataformas,
    ReporteRazones,
    TipoInstrumentos,
    TipoInstrumentoPlataformas,
    Clasificaciones,
    Materiales,
    GeneradorInstrumentos,
    Preguntas,
    GeneradorInstrumentoInstrucciones,
    S3Policy,
    Reportes,
    ClasificacionMateriales,
    Alternativas,
    PlanEstudios,
    AsignaturaPlataformas,
    CertificacionRecursos,
    Personas,
    ContestableTipos,
    MaterialTipos,
    Ayudas,
    LotePreguntas,
    Lotes,
    ClasificacionTipoAliases
}
