import { Api, ApiOptions } from "@puntaje/react/shared/core"
import { Usuario } from "./Usuario"

export class Usuarios extends Api<Usuario> {
    constructor(baseUrl: string, token: string, options: ApiOptions = {}) {
        super(baseUrl, token, "usuarios", "usuario", options)
    }

    public login(idToken: string) {
        return this.all<any>()
            .collection("sign_in")
            .post(
                {
                    usuario: {
                        id_token: idToken
                    }
                },
                { notConcat: true }
            )
    }
}
