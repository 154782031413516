import { MouseEvent } from "react"
import { useSlate } from "slate-react"
import { Button, Icon } from "./UI"
import { copyRichText } from "./utils"

export const CutButton = (): JSX.Element => {
    const editor = useSlate()
    const fragment = editor.getFragment()
    const activeIcon = fragment?.[0]?.["children"]?.[0]?.["text"] !== ""

    const handleCut = (e: MouseEvent<HTMLInputElement>) => {
        e.preventDefault()
        copyRichText(editor)
        editor.deleteFragment()
    }

    return (
        <Button active={Boolean(activeIcon)} onClick={handleCut}>
            <Icon title="Cortar">content_cut</Icon>
        </Button>
    )
}
