import { useState } from "react"
import { Editor, Element as SlateElement, Transforms } from "slate"
import { useSlate, useSlateStatic } from "slate-react"
import { css } from "@emotion/css"
import { Grid, MenuItem, Typography } from "@mui/material"

import CUITooltip from "../../DataDisplay/CUITooltip"
import { CUIDialog, CUIDialogActions, CUIDialogContent, CUIDialogTitle } from "../../Feedback"
import CUIButton from "../CUIButton"
import { CUIText } from "../CUIText"
import { Button, Icon } from "./UI"
import { getElements } from "./utils"

import {
    CustomEditor,
    TableBodyElement,
    TableCaptionElement,
    TableCellElement,
    TableElement,
    TableRowElement
} from "../custom-types"
import CUISelect from "../CUISelect"

interface IState {
    rows: number
    columns: number
    height: number
    width: number
    title: string
    border: number
    padding: number
    align: "center" | "right" | "left" | "none"
    header: "none" | "row" | "column" | "both"
}

const createTableNode = (tableDimensions: (string[] | string)[], options: IState): TableElement => {
    const { border, align, height, width, padding, header, title } = options

    const calHeight = height > 0 ? height : tableDimensions.length * 25

    const tableChildren: TableRowElement[] = Array.from(tableDimensions, (value, index) => {
        const isRowHeader = header === "row" || header === "both"
        const isHeader = isRowHeader && index === 0

        return createRow(value, { padding, header, border, isHeader })
    })

    const tableBody: TableBodyElement = {
        type: "table-body",
        children: tableChildren
    }

    const tableCaption: TableCaptionElement = {
        type: "caption",
        children: [{ text: title }]
    }

    let style = ""
    if (border && border > 0) style = `border:${border}px solid #ccc;`
    if (align === "center") style = style + "margin:0 auto;"
    if (align === "right") style = style + "float:right;"
    if (width) style = style + `width:${width}px;`
    style = style + `height:${calHeight}px;`
    style = style + `border-collapse:collapse;`

    return {
        type: "table",
        children: [tableCaption, tableBody],
        style: style,
        title: title
    }
}

const createRow = (
    columns: string | string[],
    options: { padding: number; header: IState["header"]; border: number; isHeader: boolean }
): TableRowElement => {
    const newRow = Array.from(columns, (value: string, index) => {
        let isColumnHeader = false

        if (options.header === "column" || options.header === "both") {
            isColumnHeader = index === 0
        }

        const isCellHeader = options.isHeader ? options.isHeader : isColumnHeader

        return createTableCell(value, isCellHeader, options)
    })

    return {
        type: "table-row",
        children: newRow
    }
}

const createTableCell = (
    text: string,
    isHeader: boolean,
    options: { padding: number; header: IState["header"]; border: number }
): TableCellElement => {
    const { header, padding, border } = options

    let style = ""
    if (border && border > 0) style = `border:${border}px solid #ccc;`
    if (padding) style = style + `padding:${padding}px;`
    if (isHeader) style = style + `font-weight:bolder;`

    return {
        type: isHeader ? "table-cell-header" : "table-cell",
        children: [{ text }],
        style: style,
        padding,
        border,
        header,
        isHeader
    }
}

export const Table = ({ attributes, children, element, baseAttributes }): JSX.Element => {
    const { width, height, border, align, cellpadding, cellspacing, margin = 0, float = "none" } = element

    const tableAttributes = {}

    if (width) tableAttributes["width"] = width
    if (height) tableAttributes["height"] = height
    if (border) tableAttributes["border"] = border
    if (align) tableAttributes["align"] = align
    if (cellpadding) tableAttributes["cellpadding"] = cellpadding
    if (cellspacing) tableAttributes["cellspacing"] = cellspacing

    return (
        <table {...tableAttributes} {...baseAttributes} {...attributes}>
            {children}
        </table>
    )
}

export const TableCell = ({ attributes, children, element, baseAttributes }): JSX.Element => {
    return (
        <td {...baseAttributes} {...attributes}>
            {children}
        </td>
    )
}

export const TableHeader = ({ attributes, children, element, baseAttributes }): JSX.Element => {
    return (
        <th {...baseAttributes} {...attributes}>
            {children}
        </th>
    )
}

export const TableButton = (): JSX.Element => {
    const initialState: IState = {
        rows: 3,
        columns: 2,
        height: undefined,
        width: 500,
        padding: 5,
        border: 1,
        title: "",
        align: "none",
        header: "none"
    }
    const editor = useSlateStatic()
    const [showDialog, setShowDialog] = useState(false)
    const [state, setState] = useState<IState>(initialState)

    const handleTable = () => {
        if (!state.rows || !state.columns) {
            return
        }

        const tableDimensions = Array.from({ length: state.rows }, () =>
            Array.from({ length: state.columns }, () => "")
        )
        const newTable: TableElement = createTableNode(tableDimensions, state)

        Transforms.insertNodes(editor, newTable)
        Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, { mode: "highest" })

        setShowDialog(false)
    }

    const handleClick = () => {
        setState(initialState)
        setShowDialog(true)
    }

    const handleClose = () => setShowDialog(false)

    const handleInputChange = ({ target }) => {
        setState({
            ...state,
            [target.name]: target.value
        })
    }

    return (
        <>
            <Button active={false} onMouseDown={handleClick}>
                <Icon title="Tabla">grid_on</Icon>
            </Button>
            <CUIDialog onClose={handleClose} open={showDialog}>
                <CUIDialogTitle onClose={handleClose}>Ingresa datos de la tabla</CUIDialogTitle>
                <CUIDialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <CUIText
                                type="number"
                                label="N° columnas"
                                value={state.columns}
                                onChange={handleInputChange}
                                fullWidth
                                name="columns"
                                margin="dense"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CUIText
                                type="number"
                                label="N° filas"
                                name="rows"
                                value={state.rows}
                                onChange={handleInputChange}
                                fullWidth
                                margin="dense"
                                size="small"
                            />
                        </Grid>
                        <Grid container alignItems="center" margin={2}>
                            <Grid item xs={3}>
                                <Typography variant="overline" sx={{ marginTop: "56" }}>
                                    Campos opcionales
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <hr />
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <CUIText
                                type="number"
                                label="Altura"
                                value={state.height}
                                onChange={handleInputChange}
                                fullWidth
                                name="height"
                                margin="dense"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CUIText
                                type="number"
                                label="Anchura"
                                name="width"
                                value={state.width}
                                onChange={handleInputChange}
                                fullWidth
                                margin="dense"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CUIText
                                type="number"
                                label="Tamaño borde"
                                value={state.border}
                                onChange={handleInputChange}
                                fullWidth
                                name="border"
                                margin="dense"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CUISelect
                                variant="outlined"
                                value={state.align}
                                name="align"
                                label="Alineación"
                                formControlProps={{ fullWidth: true, style: { marginTop: "8px" } }}
                                onChange={handleInputChange}
                                size="small"
                            >
                                <MenuItem value={"none"}>Sin posición definida</MenuItem>
                                <MenuItem value={"left"}>Izquierda</MenuItem>
                                <MenuItem value={"center"}>Centro</MenuItem>
                                <MenuItem value={"right"}>Derecha</MenuItem>
                            </CUISelect>
                        </Grid>
                        <Grid item xs={4}>
                            <CUISelect
                                variant="outlined"
                                value={state.header}
                                name="header"
                                label="Encabezado"
                                formControlProps={{ fullWidth: true, style: { marginTop: "8px" } }}
                                onChange={handleInputChange}
                                size="small"
                            >
                                <MenuItem value={"none"}>Sin encabezado</MenuItem>
                                <MenuItem value={"row"}>Primera fila</MenuItem>
                                <MenuItem value={"column"}>Primera columna</MenuItem>
                                <MenuItem value={"both"}>Ambas</MenuItem>
                            </CUISelect>
                        </Grid>
                        <Grid item xs={4}>
                            <CUIText
                                type="number"
                                label="Esp. interior"
                                value={state.padding}
                                onChange={handleInputChange}
                                fullWidth
                                name="padding"
                                margin="dense"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CUIText
                                label="Título"
                                name="title"
                                value={state.title}
                                onChange={handleInputChange}
                                fullWidth
                                margin="dense"
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </CUIDialogContent>
                <CUIDialogActions>
                    <CUIButton onMouseDown={handleClose}>Cancelar</CUIButton>
                    <CUIButton onMouseDown={handleTable}>Aceptar</CUIButton>
                </CUIDialogActions>
            </CUIDialog>
        </>
    )
}

export const DeleteTableButton = (): JSX.Element => {
    const editor = useSlate()
    const [tableEntry] = getElements(editor, "table")
    const buttonClass = tableEntry ? "inline-blocK" : "none"

    const handleRemoveTable = (editor: CustomEditor) => {
        Transforms.removeNodes(editor, {
            match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table"
        })
    }

    return (
        <CUITooltip title="Eliminar tabla">
            <Button
                active={false}
                onMouseDown={() => handleRemoveTable(editor)}
                className={css`
                    display: ${buttonClass};
                `}
            >
                <Icon>grid_off</Icon>
            </Button>
        </CUITooltip>
    )
}
