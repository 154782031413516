import { Editor, Element as SlateElement, Transforms } from "slate"
import { useSlate } from "slate-react"
import { CustomEditor } from "../custom-types"
import { Button, Icon } from "./UI"

const LIST_TYPES = ["numbered-list", "bulleted-list"]
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"]

interface IProps {
    format: string
    icon: string
    title: string
    disabled?: boolean
}

const isBlockActive = (editor: CustomEditor, format: string, blockType = "type"): boolean => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format
        })
    )

    return !!match
}

export const toggleBlock = (editor: CustomEditor, format: string) => {
    const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "textAlign" : "type")
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n => {
            return (
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                LIST_TYPES.includes(n.type) &&
                !TEXT_ALIGN_TYPES.includes(format)
            )
        },
        split: true
    })

    let newProperties: Partial<SlateElement>
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            textAlign: isActive ? undefined : format
        }
    } else {
        newProperties = {
            type: isActive ? "paragraph" : isList ? "list-item" : format
        }
    }

    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

export const BlockButton = ({ format, icon, title, disabled }: IProps): JSX.Element => {
    const editor = useSlate()
    return (
        <Button
            disabled={true}
            active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "textAlign" : "type")}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            <Icon title={title}>{icon}</Icon>
        </Button>
    )
}
