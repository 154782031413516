import { styled } from "@mui/material/styles"
import { useEnvironment, useSession } from "@puntaje/react/shared/core"
import { ClonadorEnvironment, ClonadorSession } from "@puntaje/clonador/core"
import { useClonadorApi } from "@puntaje/clonador/api-services"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { GoogleLogin } from "@react-oauth/google"

const CenteringDiv = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "400px"
}))

export function Login() {
    const { googleAuthClientId } = useEnvironment<ClonadorEnvironment>()
    const session = useSession<ClonadorSession>()
    const [replaceUrl, setReplaceUrl] = useState(true)
    const { usuariosService } = useClonadorApi()
    const navigate = useNavigate()
    const mutation = useMutation((response: any) => {
        return usuariosService.login(response.credential).then(res => {
            const parsedInfo = JSON.parse(JSON.parse(atob(res.token.split(".")[1]))["payload"])

            const sessionData = {
                tokens: {
                    clonador: res.token,
                    nebu: parsedInfo["nebu_token"]
                },
                user: {
                    email: parsedInfo["email"],
                    nombre: parsedInfo["nombre"]
                }
            }

            setReplaceUrl(false)
            session.set(sessionData)
        })
    })

    const responseGoogle = (response: any) => {
        mutation.mutate(response)
    }

    useEffect(() => {
        if (session && session.isLoggedIn) {
            navigate("/configuraciones", { replace: replaceUrl })
        }
    }, [session])

    return (
        <CenteringDiv>
            <GoogleLogin
                onSuccess={responseGoogle}
                onError={() => {
                    alert("Login Failed. Try again.")
                }}
            />
        </CenteringDiv>
    )
}
