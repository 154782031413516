import { S3PolicyType } from "./S3Policy"
import { uploadFileBuilder } from "./uploadFileBuilder"

interface UploadFileS3BuilderParams {
    getS3Policy: (file: File) => Promise<S3PolicyType>
    s3BucketUrl: string
    returnJustKey?: boolean
}

export function uploadFileS3Builder({ getS3Policy, s3BucketUrl, returnJustKey }: UploadFileS3BuilderParams) {
    return async (file: File) => {
        const policy = await getS3Policy(file)

        if ("error" in policy) {
            throw new Error(policy.error)
        }

        const keys: string[] = ["AWSAccessKeyId", "Content-Type", "acl", "key", "policy", "signature"]
        const data: FormData = new FormData()

        for (const key in keys) {
            data.append(keys[key], policy[keys[key]])
        }

        const uploadFile = uploadFileBuilder({ uploadUrl: s3BucketUrl, data, fileKey: "file" })

        return uploadFile(file).then(_ => {
            return returnJustKey ? policy.key : `${s3BucketUrl}/${policy.key}`
        })
    }
}
