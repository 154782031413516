import Tab, { TabProps } from "@mui/material/Tab"

function a11yProps(index: number) {
    return {
        id: `cui-tab-${index}`,
        "aria-controls": `cui-tabpanel-${index}`
    }
}

export interface CUITabProps extends TabProps {
    value: any
}

export function CUITab(props: CUITabProps) {
    return <Tab {...props} {...a11yProps(props.value)} />
}

export default CUITab
