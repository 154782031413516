import { MouseEvent } from "react"
import { Transforms } from "slate"
import { useSlateStatic } from "slate-react"
import { Button, Icon } from "./UI"
import { deserialize } from "./utils"

export const PasteButton = (): JSX.Element => {
    const editor = useSlateStatic()

    const handleCopy = async (e: MouseEvent<HTMLSpanElement>): Promise<void> => {
        e.preventDefault()
        const text = await navigator.clipboard.read()

        for (const clipboardItem of text) {
            const blob = await clipboardItem.getType("text/html")
            const blobText = await blob.text()
            const document = new DOMParser().parseFromString(blobText, "text/html")
            const deserializedText = deserialize(document.body)
            Transforms.insertNodes(editor, deserializedText)
        }
    }

    return (
        <Button active={false} onClick={handleCopy}>
            <Icon title="Pegar">content_paste</Icon>
        </Button>
    )
}
