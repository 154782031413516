import { Alert, CardContent, Grid, Card, Typography } from "@mui/material"
import { CUICheckboxes, CUIContentBox, CUIFormInput, CUIRadios } from "@puntaje/react/shared/cui"
import { CUICheckbox } from "@puntaje/react/shared/cui"
import { useNebulosaApi } from "@puntaje/react/nebulosa/api-services"
import { useQuery } from "@tanstack/react-query"
import { EvaluacionTipoDefaultConfig } from "../EvaluacionTipoDefaultConfig"
import { useFieldArray, useWatch } from "react-hook-form"
import { useEffect } from "react"
import ConfigTipoEvaluaciones from "./ConfigTipoEvaluaciones/ConfigTipoEvaluaciones"
import { EvaluacionTipoMap } from "../../types/configuration.types"

interface TiposEvaluacionesProps {
    /**
     * Valor que permite distinguir al componente si se está creando o editando un clon.
     * editing es true cuando estoy editando el clon, y false cuando estoy creando un clon nuevo
     */
    editing: boolean

    /**
     * Valor que permite distinguir al componente si el clon es o no un clon de Puntaje Nacional, para
     * ocultar o mostrar los campos que correspondan.
     */
    clonPNCL: boolean
}

export default function TiposEvaluaciones({ editing, clonPNCL }: TiposEvaluacionesProps) {
    const { tipoInstrumentosService } = useNebulosaApi()
    const { data, isLoading } = useQuery(["tipoInstrumentos"], () => tipoInstrumentosService.whereWithoutCount())

    /**
     * Este campo es un arreglo con los nombres de los tipos de evaluación que están marcados en las checkbox
     */
    const evaluacionTiposOptions: string[] = useWatch({
        name: "configuracion.plataforma.evaluacionTipos"
    })

    /**
     * Este campo es un arreglo con las configuraciones de cada tipo de evaluación.
     * Cada elemento es un EvaluacionTipoMap.
     */
    const {
        fields: evaluacionTipoConfigs,
        append: addEvaluacionTipoConfig,
        remove: removeEvaluacionTipoConfig,
        update: updateEvaluacionTipoConfig
    } = useFieldArray({
        name: "configuracion.evaluaciones"
    })

    /**
     *  Esta función es llamada al marcar o desmarcar un checkbox de tipo de evaluación.
     *  Si estoy marcando un tipo de evaluación, me agrega al formulario un objeto con la configuración
     *  del tipo de evaluación que estoy agregando (o la default)
     *  Si estoy desmarcando un tipo de evaluación, me borra la configuración de ese tipo de evaluación
     */
    useEffect(() => {
        const evaluacionTiposConfig: string[] = evaluacionTipoConfigs.map(
            etc => (etc as EvaluacionTipoMap).evaluacionTipo as string
        )

        const evaluacionTipoAdded = evaluacionTiposOptions?.find(eto => !evaluacionTiposConfig.includes(eto))

        if (evaluacionTipoAdded) {
            addEvaluacionTipoConfig(
                // Si es que existe una configuración default en particular para el tipo de evaluación
                // que estoy agregando, agrego esa configuración (las configuraciones están en el archivo EvaluacionTipoDefaultConfig)
                // Si no, el tipo de evaluación se agrega con una configuración default genérica
                EvaluacionTipoDefaultConfig.config[evaluacionTipoAdded] || {
                    ...EvaluacionTipoDefaultConfig.config.default,
                    alias: evaluacionTipoAdded,
                    evaluacionTipo: evaluacionTipoAdded
                }
            )
        } else {
            const evaluacionTipoRemovedIndex = evaluacionTiposConfig.findIndex(
                etc => !evaluacionTiposOptions?.includes(etc)
            )

            if (evaluacionTipoRemovedIndex >= 0) {
                removeEvaluacionTipoConfig(evaluacionTipoRemovedIndex)
            }
        }
    }, [evaluacionTiposOptions])

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h4" component="div" mb={2}>
                        Evaluaciones
                    </Typography>
                    <Alert severity="info" sx={{ mb: 2 }}>
                        En la pestaña "Acciones adidcionales" se pueden seleccionar generadores de instrumento para
                        copiar a la plataforma.
                    </Alert>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            {data && (
                                <CUIFormInput
                                    inputBuilder={{
                                        component: CUICheckboxes,
                                        componentProps: {
                                            direction: "horizontal"
                                        },
                                        options: data.map(ti => ti.tipo_instrumento),
                                        label: "Tipos de evaluaciones",
                                        name: "configuracion.plataforma.evaluacionTipos",
                                        rules: {
                                            required: "Debe seleccionar al menos un tipo de evaluación"
                                        },
                                        tooltip:
                                            "Seleccione los tipos de evaluaciones que se podrán realizar en el clon",
                                        tooltipAlign: "start"
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item sm={4} xs={6}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIRadios,
                                    componentProps: {},
                                    name: "configuracion.plataforma.evaluacionDefault",
                                    label: "Evaluación por defecto",
                                    options: evaluacionTiposOptions,
                                    tooltip:
                                        "Seleccione el tipo de evaluación que se usará en la sección cuando no se especifique.",
                                    tooltipAlign: "start"
                                }}
                            />
                        </Grid>
                        <Grid item sm={4} xs={6}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIRadios,
                                    componentProps: {},
                                    name: "configuracion.plataforma.evaluacionTipoAdaptiveDefault",
                                    label: "Evaluación tipo adaptive default",
                                    options: evaluacionTiposOptions,
                                    tooltip:
                                        "Seleccione el tipo de evaluación que se usará en la sección relacionada a Planes Adaptive cuando no se especifique.",
                                    tooltipAlign: "start"
                                }}
                            />
                        </Grid>
                        <Grid item sm={4} xs={6}>
                            {!clonPNCL && (
                                <CUIFormInput
                                    inputBuilder={{
                                        component: CUIRadios,
                                        componentProps: { direction: "horizontal" },
                                        name: "configuracion.plataforma.evaluacionResponder",
                                        label: "Forma de responder",
                                        options: ["estandar", "secuencial"],
                                        tooltip: (
                                            <div>
                                                <p>
                                                    Estandar: se ven todas las preguntas a responder en una misma
                                                    página, pudiendo hacer scroll para navegar en estas.
                                                </p>
                                                <p>
                                                    Secuencial: se ve una pregunta a la vez, teniendo un listado de los
                                                    números de preguntas para seleccionar cuál se quiere contestar,
                                                    además de poseer botones Siguiente / Anterior para recorrer las
                                                    preguntas en orden.
                                                </p>
                                            </div>
                                        ),
                                        tooltipAlign: "start"
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {evaluacionTipoConfigs.length > 0 && (
                <ConfigTipoEvaluaciones
                    editing={editing}
                    evaluacionTipoConfigs={evaluacionTipoConfigs as EvaluacionTipoMap[]}
                    updateEvaluacionTipoConfig={updateEvaluacionTipoConfig}
                />
            )}
        </>
    )
}
