interface CreateLinkAndOpenParams {
    link: string
    downloadString: string
    time?: number
    newTab?: boolean
}

export function createLinkAndOpen({ link, downloadString, time = 0, newTab = false }: CreateLinkAndOpenParams) {
    const a = document.createElement("a")
    a.download = downloadString
    a.href = link
    if (newTab) {
        a.target = "_blank"
        a.rel = "noopener noreferrer"
    }

    let resolve
    const promise = new Promise<void>(res => {
        resolve = res
    })

    setTimeout(() => {
        a.click()
        resolve()
    }, time)

    return promise
}
