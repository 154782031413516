import { ChangeEvent, useCallback, useMemo } from "react"
import CUICheckbox, { CUICheckboxProps } from "./CUICheckbox"
import { styled } from "@mui/material/styles"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import { useId } from "react"
import { FieldError } from "react-hook-form"
import { Box, FormHelperText } from "@mui/material"

export interface CUICheckboxesProps<T> {
    checkboxProps?: CUICheckboxProps<"control" | "label">
    labelExtractor?: keyof T | ((item: T) => string)
    idExtractor?: keyof T | ((item: T) => number | string)
    compareBy?: keyof T | ((item: T) => any)
    onChange: (selected: T[]) => void
    options: T[]
    selected: T[]
    direction?: "horizontal" | "vertical"
    id?: string
    label?: string
    error?: FieldError
    groupOptionsBy?: string | ((item: T) => string)
}

const DirectionalDiv = styled("div", { shouldForwardProp: prop => prop != "direction" })<{
    direction: "horizontal" | "vertical"
}>(({ direction }) => ({
    display: "flex",
    flexDirection: direction === "horizontal" ? "row" : "column",
    flexWrap: direction == "horizontal" ? "wrap" : "nowrap"
}))

export function CUICheckboxes<T>({
    checkboxProps,
    labelExtractor,
    idExtractor,
    onChange,
    options,
    selected,
    id,
    label,
    direction = "vertical",
    compareBy = (item: T) => item,
    error,
    groupOptionsBy = undefined
}: CUICheckboxesProps<T>) {
    const extractId = useCallback(
        (item: T) => {
            return idExtractor
                ? typeof idExtractor === "function"
                    ? idExtractor(item)
                    : item[idExtractor]
                : item.toString()
        },
        [idExtractor]
    )

    const extractLabel = useCallback(
        (item: T) => {
            return labelExtractor
                ? typeof labelExtractor === "function"
                    ? labelExtractor(item)
                    : item[labelExtractor]
                : item.toString()
        },
        [labelExtractor]
    )

    const extractComparer = useCallback(
        (item: T) => {
            return typeof compareBy === "function" ? compareBy(item) : item[compareBy]
        },
        [compareBy]
    )

    const onChangeCheckbox = useCallback(
        (item: T, event: ChangeEvent<HTMLInputElement>) => {
            onChange(
                event.target.checked
                    ? [...selected, item]
                    : selected.filter(selectedItem => extractComparer(selectedItem) != extractComparer(item))
            )
        },
        [selected]
    )

    const selectedMap = useMemo(() => {
        return new Map(selected.map(selectedItem => [extractComparer(selectedItem), selectedItem]))
    }, [options, selected])

    const optionGroups = useMemo(() => {
        if (!groupOptionsBy) return undefined
        else {
            const groups = new Map<string, T[]>()
            options.forEach(option => {
                const group = typeof groupOptionsBy === "function" ? groupOptionsBy(option) : option[groupOptionsBy]
                if (!groups.has(group)) groups.set(group, [])
                groups.get(group)?.push(option)
            })
            return groups
        }
    }, [groupOptionsBy, options])

    const { controlLabelProps: controlLabelPropsCheckbox, ...restCheckboxProps } = checkboxProps || {}

    const labelId = useId()

    return (
        <FormControl id={id} error={error != undefined || undefined}>
            <FormLabel id={labelId}>{label}</FormLabel>
            <DirectionalDiv direction={direction} aria-labelledby={labelId}>
                {!groupOptionsBy &&
                    options.map(item => {
                        const controlLabelProps = {
                            ...controlLabelPropsCheckbox,
                            label: extractLabel(item)
                        }

                        return (
                            <div key={extractId(item) + ""}>
                                <CUICheckbox
                                    controlLabelProps={controlLabelProps as any}
                                    {...restCheckboxProps}
                                    checked={selectedMap.has(extractComparer(item))}
                                    onChange={event => onChangeCheckbox(item, event)}
                                />{" "}
                            </div>
                        )
                    })}
                {groupOptionsBy &&
                    Array.from(optionGroups.entries()).map(([group, items]) => {
                        return (
                            <Box key={group} sx={{ mt: 1 }}>
                                <FormLabel>{group}</FormLabel>
                                {items.map(item => {
                                    const controlLabelProps = {
                                        ...controlLabelPropsCheckbox,
                                        label: extractLabel(item)
                                    }

                                    return (
                                        <div key={extractId(item) + ""}>
                                            <CUICheckbox
                                                controlLabelProps={controlLabelProps as any}
                                                {...restCheckboxProps}
                                                checked={selectedMap.has(extractComparer(item))}
                                                onChange={event => onChangeCheckbox(item, event)}
                                            />{" "}
                                        </div>
                                    )
                                })}
                            </Box>
                        )
                    })}
            </DirectionalDiv>
            {error != undefined && <FormHelperText> {error.message} </FormHelperText>}
        </FormControl>
    )
}
