import { createContext, ReactNode, useContext, useEffect, useMemo } from "react"
import { useConfig } from "../config"
import { useStateWithStorage } from "../hooks/useStateWithStorage"
import { WebLocalStorage } from "../storage/WebLocalStorage"
import { BaseStorage } from "../storage/BaseStorage"
import { Session } from "./Session"
import { BaseUsuarioSession, SessionData } from "./SessionData"

interface SessionProviderProps<T extends BaseUsuarioSession, U, V extends Session<T, U>> {
    /**
     * El tipo de storage a usar, por defecto WebLocalStorage que wrappea localStorage.
     */
    storage?: BaseStorage

    /**
     * Nodos hijos a renderizar donde se aplica el contexto.
     */
    children: ReactNode

    /**
     * Una función que devuelve una instancia de Session. Se usa en caso que cada plataforma tenga una implementación diferente.
     * Por defecto se crea una instancia de @type {Session}
     *
     * @param {SessionData<T, U>} sessionData Los datos de la sesión.
     * @param {(sessionData) => void} setSessionData La función que se usa para actualizar la sesión.
     * @returns {V} Una instancia de Session.
     */
    sessionFactory?: (sessionData: SessionData<T, U>, setSessionData) => V
}

export const SessionContext = createContext<Session<any, any>>(null)

/**
 * Saca el valor de la sesion del usuario.
 * Puede ser null si aún no se sincroniza con el storage.
 *
 * @returns {Session<T, U>}
 */
export function useSession<V extends Session<any, any>>() {
    return useContext(SessionContext) as V
}

/**
 * Provider que provee un objeto sesión.
 *
 * @param {SessionProviderProps<T, U, V>} props
 * @returns {JSX.Element}
 */
export function SessionProvider<T, U, V extends Session<T, U>>({
    children,
    storage,
    sessionFactory = (sessionData: SessionData<T, U>, setSessionData) =>
        new Session<T, U>(sessionData, setSessionData) as V
}: SessionProviderProps<T, U, V>) {
    //If necesario ya que localStorage no esta definido en el objeto window en Next.js, ademas Next.js realiza server render antes del client render.
    if (typeof window !== "undefined") {
        storage = storage || new WebLocalStorage(localStorage)
    }
    const [sessionData, setSessionData, isSynced] = useStateWithStorage("sessionData", undefined, storage)

    const session = useMemo(() => {
        return isSynced ? sessionFactory(sessionData, setSessionData) : null
    }, [sessionData, isSynced])

    return session ? <SessionContext.Provider value={session}>{children}</SessionContext.Provider> : null
}
