import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd"
import { Alert, Box, Grid, Paper, Typography } from "@mui/material"
import { Html } from "@puntaje/react/shared/core"
import { CUIRadios, CUIEditor, CUIFile, CUIButton, EditorActions } from "@puntaje/react/shared/cui"
import { useMemo, useRef, useState } from "react"
import { useFieldArray } from "react-hook-form"

const tipos = [
    {
        nombre: "html",
        toString: () => "HTML"
    },
    {
        nombre: "imagen",
        toString: () => "Imagen"
    }
]

const columnas = [
    {
        nombre: "col1",
        toString: () => "Columna 1"
    },
    {
        nombre: "col2",
        toString: () => "Columna 2"
    },
    {
        nombre: "col3",
        toString: () => "Columna 3"
    },
    {
        nombre: "footer",
        toString: () => "Footer"
    }
]

export function SeccionesLanding() {
    const [tipoToAdd, setTipoToAdd] = useState<{ nombre: string; toString: () => string }>(tipos[0])
    const [colToAdd, setColToAdd] = useState<{ nombre: string; toString: () => string }>(null)
    const [html, setHTML] = useState("")
    const [fileContent, setFileContent] = useState(null)

    const managerRef = useRef<EditorActions>()

    const {
        fields: col1Fields,
        append: appendCol1,
        remove: removeCol1,
        insert: insertCol1
    } = useFieldArray({
        name: "configuracion.app.landing.col1"
    })

    const {
        fields: col2Fields,
        append: appendCol2,
        remove: removeCol2,
        insert: insertCol2
    } = useFieldArray({
        name: "configuracion.app.landing.col2"
    })

    const {
        fields: col3Fields,
        append: appendCol3,
        remove: removeCol3,
        insert: insertCol3
    } = useFieldArray({
        name: "configuracion.app.landing.col3"
    })

    const {
        fields: footerFields,
        append: appendFooter,
        remove: removeFooter,
        insert: insertFooter
    } = useFieldArray({
        name: "configuracion.app.landing.footer"
    })

    const colToFunctions = useMemo(() => {
        return {
            col1: {
                fields: col1Fields,
                append: appendCol1,
                remove: removeCol1,
                insert: insertCol1
            },
            col2: {
                fields: col2Fields,
                append: appendCol2,
                remove: removeCol2,
                insert: insertCol2
            },
            col3: {
                fields: col3Fields,
                append: appendCol3,
                remove: removeCol3,
                insert: insertCol3
            },
            footer: {
                fields: footerFields,
                append: appendFooter,
                remove: removeFooter,
                insert: insertFooter
            }
        }
    }, [col1Fields, col2Fields, col3Fields, footerFields])

    return (
        <Paper sx={{ p: 2, mb: 3 }}>
            <Typography variant="h5" mb={2}>
                Secciones Landing
            </Typography>
            <Alert severity="info" sx={{ mb: 2 }}>
                En esta sección se configura el contenido del landing. Puede agregar secciones de tipo HTML o Imágen. En
                caso de ser HTML, puede agregar contenido con el editor de texto. En caso de ser Imágen, puede agregar
                una imagen desde su computadora.
            </Alert>
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12}>
                    <CUIRadios
                        value={tipoToAdd}
                        direction="horizontal"
                        options={tipos}
                        onChange={option => setTipoToAdd(option)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CUIRadios
                        value={colToAdd}
                        direction="horizontal"
                        options={columnas}
                        onChange={option => setColToAdd(option)}
                    />
                </Grid>

                <Grid item xs={12}>
                    {tipoToAdd.nombre == "html" && (
                        <CUIEditor value={html} onChangeHTML={setHTML} managerRef={managerRef} />
                    )}
                    {tipoToAdd.nombre == "imagen" && (
                        <CUIFile
                            onChange={file => {
                                const reader = new FileReader()
                                reader.onload = () => {
                                    setFileContent(reader.result)
                                }
                                reader.readAsDataURL(file)
                            }}
                            labelProps={{ label: "Archivo de Imagen" }}
                        />
                    )}
                </Grid>

                <Grid item xs={12}>
                    <CUIButton
                        onClick={() => {
                            if (
                                !colToAdd ||
                                !tipoToAdd ||
                                (!html && tipoToAdd.nombre == "html") ||
                                (!fileContent && tipoToAdd.nombre == "imagen")
                            ) {
                                return
                            }

                            const fieldArray = colToFunctions[colToAdd.nombre]

                            if (fieldArray.fields.length >= 3) return

                            fieldArray.append({
                                tipo: tipoToAdd.nombre,
                                contenido: tipoToAdd.nombre == "html" ? html : fileContent
                            })

                            setTipoToAdd(tipos[0])
                            setColToAdd(null)
                            setHTML("")
                            setFileContent(null)

                            managerRef.current?.reset()
                        }}
                    >
                        Agregar Elemento
                    </CUIButton>
                </Grid>

                <Grid item container spacing={2}>
                    <DragDropContext
                        onDragEnd={result => {
                            const { destination, source, draggableId } = result

                            if (!destination) return

                            if (destination.droppableId === source.droppableId && destination.index === source.index)
                                return

                            const fieldArraySource = colToFunctions[source.droppableId]
                            const fieldArrayDestination = colToFunctions[destination.droppableId]

                            const field = fieldArraySource.fields[source.index]

                            fieldArraySource.remove(source.index)
                            fieldArrayDestination.insert(destination.index, {
                                ...field
                            })
                        }}
                    >
                        {columnas.map(col => (
                            <Grid item xs={12} key={col.nombre}>
                                <Typography variant="h6">Elementos {col.toString()}</Typography>
                                <Droppable droppableId={col.nombre}>
                                    {provided => (
                                        <Box ref={provided.innerRef} {...provided.droppableProps}>
                                            {colToFunctions[col.nombre].fields.map((field, index) => (
                                                <Draggable key={field.id} draggableId={field.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <Paper
                                                            key={field.id}
                                                            sx={{
                                                                mb: 1,
                                                                p: 2,
                                                                display: "flow-root",
                                                                backgroundColor: snapshot.isDragging
                                                                    ? "grey.100"
                                                                    : "white",
                                                                cursor: "grab"
                                                            }}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            {field.tipo == "html" && (
                                                                <Box mb={1}>
                                                                    <Html content={field.contenido} />
                                                                </Box>
                                                            )}
                                                            {field.tipo == "imagen" && (
                                                                <Box mb={1}>
                                                                    <img src={field.contenido} />
                                                                </Box>
                                                            )}
                                                            {field.tipo == "formulario" && (
                                                                <Box mb={1}>
                                                                    <Typography>Formulario iniciar sesión</Typography>
                                                                </Box>
                                                            )}
                                                            {field.tipo != "formulario" && (
                                                                <CUIButton
                                                                    onClick={() =>
                                                                        colToFunctions[col.nombre].remove(index)
                                                                    }
                                                                >
                                                                    Eliminar
                                                                </CUIButton>
                                                            )}
                                                        </Paper>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </Box>
                                    )}
                                </Droppable>
                            </Grid>
                        ))}
                    </DragDropContext>
                </Grid>
            </Grid>
        </Paper>
    )
}
