import Tabs, { TabsProps } from "@mui/material/Tabs"

export interface CUITabsProps extends TabsProps {

}

export function CUITabs(props: CUITabsProps) {
    return <Tabs {...props}></Tabs>
}

export default CUITabs