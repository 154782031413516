import { Api, ApiOptions } from "@puntaje/react/shared/core"
import { InfoAdicional } from "./InfoAdicional"

interface GetValuesParams {
    infoAdicional: { infoAdicional: string }
}

export class InfoAdicionales extends Api<InfoAdicional> {
    constructor(baseUrl: string, token: string, options: ApiOptions = {}) {
        super(baseUrl, token, "info_adicionales", "info_adicional", options)
    }

    getValueOfInfoAdicional(params: GetValuesParams) {
        return this.all<{ valor: string }[]>().collection("getValueOf").get(params)
    }
}
