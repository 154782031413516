import { useTranslateLib } from "../i18n/useTranslateLib"

/**
 * Hook que retorna una función que retorna un mensaje de error si el valor no cumple con el patrón
 *
 * @returns Objeto con la función `patternError` que retorna un mensaje de error si el valor no cumple con el patrón
 */
export function usePatternError() {
    const { t } = useTranslateLib("use_pattern_error")

    return {
        patternError: (label: string) => t("pattern_error", { label })
    }
}
