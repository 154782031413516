import { Api, ApiOptions } from "@puntaje/react/shared/core"
import { GeneradorInstrumento } from "./GeneradorInstrumento"
import { Clasificacion } from "./Clasificacion"

interface ClasificacionTipoConfiguracion {
    clasificacionTipo: string
    name: string
    htmlId: string
    hasHijos: boolean
}

export class GeneradorInstrumentos extends Api<GeneradorInstrumento> {
    constructor(baseUrl: string, token: string, options: ApiOptions = {}) {
        super(baseUrl, token, "generador_instrumentos", "generador_instrumento", options)
    }
    filtroClasificaciones(generadorInstrumentoId: number, params: any = null) {
        return this.one<Clasificacion[]>(generadorInstrumentoId).collection("filtro_clasificaciones").get(params)
    }

    filtroClasificacionTiposConfiguracion(generadorInstrumentoId: number, params: any = null) {
        return this.one<ClasificacionTipoConfiguracion[]>(generadorInstrumentoId)
            .collection("filtro_clasificacion_tipos_configuracion")
            .get(params)
    }
}
