import { LocaleType } from "./types"

export const pt: LocaleType = {
    use_validate_email: {
        email_error: "O email é inválido."
    },
    use_validate_phone: {
        phone_error: "O número de telefone é inválido."
    },
    use_validate_rut: {
        rut_error: "O RUT é inválido. O RUT deve ser inserido com traço e sem pontos."
    },
    use_validate_ti_o_cc: {
        ti_o_cc_error: "O Número Único de Identificação Pessoal é inválido. O NUIP deve ser inserido sem pontos."
    },
    use_validate_curp: {
        curp_error: "O CURP é inválido. O CURP deve ter 18 caracteres."
    },
    use_validate_cpf: {
        cpf_error: "O CPF é inválido."
    },
    use_pattern_error: {
        pattern_error: "O campo %{label} não é válido."
    },
    use_max_error: {
        max_error: "O campo %{label} deve ser menor ou igual a %{max}."
    },
    use_min_error: {
        min_error: "O campo %{label} deve ser maior ou igual a %{min}."
    },
    use_min_length_error: {
        min_length_error: "O campo %{label} deve ter no mínimo %{min} caracteres."
    },
    use_max_length_error: {
        max_length_error: "O campo %{label} deve ter no máximo %{max} caracteres."
    },
    use_required_error: {
        required_error: "O campo %{label} é obrigatório."
    },
    use_inicio_less_than_termino: {
        inicio_less_than_termino_error: "A data final deve ser posterior à data inicial."
    },
    use_validate_str_array_numbers: {
        error: "O valor inserido não está em um formato válido."
    },
    password_strength_bar: {
        muy_debil: "Muito fraca",
        debil: "Fraca",
        moderada: "Moderada",
        segura: "Forte",
        muy_segura: "Muito forte"
    },

    use_fecha_atras: {
        ayer: "1 dia atrás",
        hace_ano: "mais do que%{smart_count} ano |||| mais do que %{smart_count} ano",
        hace_dia: "faz %{smart_count} dia |||| faz %{smart_count} dias",
        hace_mes: "mais do que %{smart_count} mes |||| mais do que %{smart_count} meses",
        hace_semana: "mais do que %{smart_count} semana |||| mais do que %{smart_count} semanas",
        hoy: "hoje"
    }
}
