import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { CUIContentBox, CUIFormInput } from "@puntaje/react/shared/cui"
import { CUIText } from "@puntaje/react/shared/cui"
import { Paper } from "@mui/material"
interface LogosProps {
    /**
     * Valor que permite distinguir al componente si se está creando o editando un clon.
     * editing es true cuando estoy editando el clon, y false cuando estoy creando un clon nuevo
     */
    editing: boolean
}

export default function Analytics({ editing }: LogosProps) {
    return (
        <Paper sx={{ p: 2, mb: 3 }}>
            <Typography variant="h5" mb={2}>
                Google analytics
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item sm={4} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIText,
                            componentProps: {
                                fullWidth: true
                            },
                            name: "configuracion.app.googleAnalyticsPropertyId.profesores",
                            label: "Código profesores",
                            placeholder: "AA-11112222-1",
                            rules: {
                                pattern: {
                                    value: /\b^[A-Z][A-Z0-9]?-[A-Z0-9]{4,10}(?:\-[1-9]\d{0,3})?$\b/,
                                    message:
                                        "El valor de este campo debe ser un identificador de Google Analytics válido"
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIText,
                            componentProps: {
                                fullWidth: true
                            },
                            name: "configuracion.app.googleAnalyticsPropertyId.alumnos",
                            label: "Código alumnos",
                            placeholder: "AA-11112222-1",
                            rules: {
                                pattern: {
                                    value: /\b^[A-Z][A-Z0-9]?-[A-Z0-9]{4,10}(?:\-[1-9]\d{0,3})?$\b/,
                                    message:
                                        "El valor de este campo debe ser un identificador de Google Analytics válido"
                                }
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Typography variant="h5" mb={2}>
                Facebook analytics
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item sm={4} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIText,
                            componentProps: {
                                fullWidth: true
                            },
                            name: "configuracion.app.facebookAnalyticsPropertyId.profesores",
                            label: "Código profesores",
                            placeholder: "1111111122222222",
                            rules: {
                                pattern: {
                                    value: /\b^(\d)+\b/,
                                    message:
                                        "El valor de este campo debe ser un identificador de Facebook Analytics válido"
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIText,
                            componentProps: {
                                fullWidth: true
                            },
                            name: "configuracion.app.facebookAnalyticsPropertyId.alumnos",
                            label: "Código alumnos",
                            placeholder: "1111111122222222",
                            rules: {
                                pattern: {
                                    value: /\b^(\d)+\b/,
                                    message:
                                        "El valor de este campo debe ser un identificador de Facebook Analytics válido"
                                }
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Typography variant="h5" mb={2}>
                Google Tag Manager
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item sm={4} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIText,
                            componentProps: {
                                fullWidth: true
                            },
                            name: "configuracion.app.googleTagManagerPropertyId.profesores",
                            label: "Código profesores",
                            placeholder: "GTM-XXXXXXX",
                            rules: {
                                pattern: {
                                    value: /\b^GTM-[A-Z0-9]{6,10}\b/,
                                    message:
                                        "El valor de este campo debe ser un identificador de Google Tag Manager válido"
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIText,
                            componentProps: {
                                fullWidth: true
                            },
                            name: "configuracion.app.googleTagManagerPropertyId.alumnos",
                            label: "Código alumnos",
                            placeholder: "GTM-XXXXXXX",
                            rules: {
                                pattern: {
                                    value: /\b^GTM-[A-Z0-9]{6,10}\b/,
                                    message:
                                        "El valor de este campo debe ser un identificador de Google Tag Manager válido"
                                }
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Typography variant="h5" mb={2}>
                Global Site Tag / Google Ads
            </Typography>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIText,
                            componentProps: {
                                fullWidth: true
                            },
                            name: "configuracion.app.globalSiteTag.profesores.propertyId",
                            label: "Código profesores",
                            placeholder: "G-XXXXXXX",
                            rules: {
                                pattern: {
                                    value: /\b^G-[A-Z0-9]{6,10}\b/,
                                    message: "El valor de este campo debe ser un código de Global Site Tag válido"
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIText,
                            componentProps: {
                                fullWidth: true
                            },
                            name: "configuracion.app.globalSiteTag.profesores.sendTo",
                            label: "Conversión Send to Profesores",
                            placeholder: "G-XXXXXXX/XXXXXXX",
                            rules: {
                                pattern: {
                                    value: /\b^G-[A-Z0-9]{6,10}-[A-Z0-9]{6,10}\b/,
                                    message:
                                        "El valor de este campo debe ser un código de Conversión Send to Profesores válido"
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIText,
                            componentProps: {
                                fullWidth: true
                            },
                            name: "configuracion.app.globalSiteTag.alumnos.propertyId",
                            label: "Código alumnos",
                            placeholder: "G-XXXXXXX",
                            rules: {
                                pattern: {
                                    value: /\b^G-[A-Z0-9]{6,10}\b/,
                                    message: "El valor de este campo debe ser un código de Global Site Tag válido"
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIText,
                            componentProps: {
                                fullWidth: true
                            },
                            name: "configuracion.app.globalSiteTag.alumnos.sendTo",
                            label: "Conversión Send to Alumnos",
                            placeholder: "G-XXXXXXX/XXXXXXX",
                            rules: {
                                pattern: {
                                    value: /\b^G-[A-Z0-9]{6,10}-[A-Z0-9]{6,10}\b/,
                                    message:
                                        "El valor de este campo debe ser un código de Conversión Send to Profesores válido"
                                }
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}
