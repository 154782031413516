import { useTranslateLib } from "../i18n/useTranslateLib"

/**
 * Hook que retorna una función que retorna un mensaje de error si el valor es menor al mínimo
 *
 * @returns Objeto con la función `minError` que retorna un mensaje de error si el valor es menor al mínimo
 */
export function useMinError() {
    const { t } = useTranslateLib("use_min_error")

    return {
        minError: (label: string, min: number | string) => t("min_error", { label, min })
    }
}
