import { ControllerFieldState, ControllerRenderProps, FieldValues } from "react-hook-form"

export const useCUIEditorFormProps = (
    {
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
    }: {
        field: ControllerRenderProps<FieldValues, string>
        fieldState: ControllerFieldState
    },
    inputBuilder
) => {
    return {
        ...inputBuilder.componentProps,
        value: value ?? "",
        error: error != undefined || undefined,
        helpertext: error != undefined ? error.message : undefined,
        onChangeHTML: onChange,
        editableProps: {
            ...inputBuilder.componentProps?.editableProps,
            onBlur,
            id: name
        }
    }
}
