import { BaseUsuarioSession, SessionData } from "./SessionData"

declare const ga: any

export class Session<T extends BaseUsuarioSession, U> {
    constructor(
        public readonly sessionData: SessionData<T, U>,
        protected setSessionData: (value: any | ((v: any) => void)) => void
    ) {}

    get usuario() {
        return this.sessionData?.user
    }

    get tokens() {
        return this.sessionData?.tokens
    }

    get redirectUrl() {
        return this.sessionData?.redirectUrl
    }

    get isLoggedIn() {
        const tokens = Object.values(this.tokens ?? {}) as any[]

        if (tokens.length === 0) return false

        return tokens.reduce((acc, token) => {
            if (!token) return false

            try {
                const exp = JSON.parse(atob(token.split(".")[1])).exp

                if (!(+exp * 1000 > Date.now())) {
                    console.log("me desloguié por")
                    console.log(+exp * 1000, " menor que ", Date.now())
                    console.log(exp)
                    console.log(token)
                    console.log({ ...this.tokens })
                }

                return acc && +exp * 1000 > Date.now()
            } catch (e) {
                console.log(e)
                return false
            }
        }, true)
    }

    public set(dataUsuario: SessionData<T, U> | ((oldState: SessionData<T, U>) => SessionData<T, U>)) {
        if (typeof dataUsuario == "function") {
            const newFun = (oldState: SessionData<T, U>) => {
                const newState = dataUsuario(oldState)

                this.configureData(newState)

                return newState
            }
            this.setSessionData(newFun)
        } else {
            this.configureData(dataUsuario)
            this.setSessionData(dataUsuario)
        }
    }

    public clean() {
        this.setSessionData(null)
    }

    public setAnalyticsUser(userId) {
        if (typeof ga !== "undefined") {
            ga("set", "userId", userId)
        }
    }

    private configureData(dataUsuario: SessionData<T, U>) {
        if (dataUsuario?.user?.id && dataUsuario.user.id != this.usuario?.id) {
            this.setAnalyticsUser(dataUsuario.user.id)
        }
    }

    public shouldUpdateData() {
        const usuario = this.usuario as any
        if (!usuario.last_updated_data) {
            return true
        }

        const today = new Date()
        const lastUpdatedData = new Date(usuario.last_updated_data)

        return lastUpdatedData.getFullYear() < today.getFullYear()
    }
}
