import axios, { AxiosInstance } from "axios"
import { ApiOptions, ApiRequestOptions } from "./ApiInterfaces"
import { ApiRequest } from "./ApiRequest"
import { BasicApi } from "./BasicApi"
import { RecursivePartial } from "../types/RecursivePartial"
import { RecursiveNumberToString } from "../types/RecursiveNumberToString"
import { response } from "msw"
import { createLinkAndOpen } from "../util"

/**
 * Clase que representa un endpoint de una API.
 * junto con sus métodos básicos para realizar llamadas REST.
 *
 * Ejemplo:
 *  where -> GET `/usuarios`
 *  find -> GET `/usuarios/:id`
 *  create -> POST `/usuarios`
 *  update -> PATCH `/usuarios/:id`
 *  delete -> DELETE `/usuarios/:id`
 */
export class Api<T> extends BasicApi<T> {
    where(params: any = {}, options: ApiRequestOptions = {}) {
        return Promise.all([this.whereWithoutCount(params, options), this.whereCount(params, options)])
    }

    whereCount(params: any = {}, options: ApiRequestOptions = {}) {
        const countParams = {
            ...params,
            count: 1
        }

        return this.all<number>().get(countParams, options)
    }

    whereWithoutCount(params: any = {}, options: ApiRequestOptions = {}) {
        return this.all<T[]>().get(params, options)
    }

    whereCSV(params: any = {}, options: ApiRequestOptions = {}) {
        return this.whereWithoutCount(params, { ...options, responseType: "arraybuffer" as "json" }).then(
            (response: any) => {
                const blob = new Blob([response], { type: "text/csv" })
                createLinkAndOpen({
                    link: window.URL.createObjectURL(blob),
                    downloadString: params.to_csv || "datos.csv"
                })
            }
        )
    }

    wherePostWithoutCount(params: any = {}, options: ApiRequestOptions = {}) {
        return this.all<T[]>()
            .collection("post")
            .post(params, { ...options, notConcat: true })
    }

    wherePostCount(params: any = {}, options: ApiRequestOptions = {}) {
        const countParams = {
            ...params,
            count: 1
        }

        return this.all<number>()
            .collection("post")
            .post(countParams, { ...options, notConcat: true })
    }

    wherePost(params: any = {}, options: ApiRequestOptions = {}) {
        return Promise.all([
            this.wherePostWithoutCount(params, { ...options, notConcat: true }),
            this.wherePostCount(params, options)
        ])
    }

    find(id: number, params: any = null, options: ApiRequestOptions = {}) {
        return this.one(id).get(params, options)
    }

    save(object: RecursiveNumberToString<RecursivePartial<T>>, options: ApiRequestOptions = {}) {
        return this.all().post(object, options)
    }

    update(id: number, object: RecursiveNumberToString<RecursivePartial<T>>, options: ApiRequestOptions = {}) {
        return this.one(id).patch(object, options)
    }

    remove(id: number, options: ApiRequestOptions = {}) {
        return this.one<void>(id).delete(null, options)
    }
}
