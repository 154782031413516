import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { useMemo } from "react"
import { CUICheckboxes, CUICheckboxesProps } from "./CUICheckboxes"

export interface CUIGroupedCheckboxesProps<T> extends CUICheckboxesProps<T> {
    groupBy: keyof T | ((item: T) => string)
}

const CheckboxesContainer = styled("div")(({ theme }) => ({
    marginBottom: theme.spacing(2)
}))

export function CUIGroupedCheckboxes<T>({ groupBy, options, error, ...restProps }: CUIGroupedCheckboxesProps<T>) {
    const groupedOptions = useMemo(() => {
        return options.reduce((groupedOptions, option) => {
            const group = typeof groupBy === "function" ? groupBy(option) : option[groupBy] + ""
            if (!groupedOptions.has(group)) {
                groupedOptions.set(group, [])
            }
            groupedOptions.get(group).push(option)
            return groupedOptions
        }, new Map<string, T[]>())
    }, [options, groupBy])

    const groupedOptionsArray = Array.from(groupedOptions.entries())

    return (
        <div>
            <Typography sx={{ mb: 1 }}>{restProps.label}</Typography>
            {groupedOptionsArray.map(([group, options], index) => {
                return (
                    <CheckboxesContainer key={group}>
                        <CUICheckboxes
                            {...restProps}
                            label={group}
                            options={options}
                            error={index == groupedOptionsArray.length - 1 ? error : null}
                        />
                    </CheckboxesContainer>
                )
            })}
        </div>
    )
}
