import axios from "axios"

interface UploadFileBuilderParams {
    uploadUrl: string
    data?: FormData
    fileKey: string
    responseToUrl?: (data: any) => string
}

export function uploadFileBuilder({
    uploadUrl,
    fileKey,
    data = new FormData(),
    responseToUrl = (data: any) => data
}: UploadFileBuilderParams) {
    return (file: File) => {
        data.append(fileKey, file)

        return axios
            .post(uploadUrl, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(response => {
                return responseToUrl(response.data)
            })
    }
}
