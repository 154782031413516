import { createContext, ReactNode, useState } from "react"
import { Editor, Range, Element as SlateElement } from "slate"
import { useSlateStatic } from "slate-react"
import { LinkElement } from "../custom-types"
import { getElements } from "./utils"

type DialogeType = "Link" | "Image"
export type FieldType =
    | "imageUrl"
    | "linkText"
    | "linkUrl"
    | "imageWidth"
    | "imageHeight"
    | "imageAlt"
    | "imageBorder"
    | "imgMarginHorizontal"
    | "imgMarginVertical"
    | "imgFloat"

interface IEditorActions {
    setField: (type: FieldType, value: string) => void
    openImageDialog: () => void
    openLinkDialog: () => void
    closeDialog: (value: DialogeType) => void
}
export interface IEditorContext {
    state: IState
    actions: IEditorActions
}

interface IState {
    imageUrl: string
    linkText: string
    linkUrl: string
    imageHeight: number
    imageWidth: number
    imageAlt: string
    imageBorder: number
    imgMarginHorizontal: number
    imgMarginVertical: number
    imgFloat: string
    isOpenImageDialog: boolean
    isOpenLinkDialog: boolean
    isEditingLink: boolean
}

export const EditorContext = createContext<IEditorContext>(null)

const initialState: IState = {
    imageUrl: "",
    linkText: "",
    linkUrl: "",
    imageHeight: 350,
    imageWidth: 350,
    imageAlt: "",
    imageBorder: 0,
    imgMarginHorizontal: 0,
    imgMarginVertical: 0,
    imgFloat: "",
    isOpenImageDialog: false,
    isOpenLinkDialog: false,
    isEditingLink: false
}

export const EditorProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [state, setState] = useState<IState>(initialState)
    const editor = useSlateStatic()

    const closeDialog = (dialogType: DialogeType) => {
        const dialogName = `isOpen${dialogType}Dialog`
        setState(prevState => ({
            ...prevState,
            [dialogName]: false
        }))
    }

    const openLinkDialog = () => {
        let linkUrl = ""
        let isEditingURL = false
        const isCollapsed = Range.isCollapsed(editor.selection)
        let linkText = !isCollapsed ? Editor.string(editor, editor.selection) : "link"

        const [entry] = Editor.nodes<LinkElement>(editor, {
            match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link"
        })

        if (entry) {
            const [link, path] = entry
            linkText = Editor.string(editor, path)
            linkUrl = link.url
            isEditingURL = true
        }

        setState(prevState => ({
            ...prevState,
            isOpenLinkDialog: true,
            isEditingLink: isEditingURL,
            linkText,
            linkUrl
        }))
    }

    const openImageDialog = () => {
        const [imgEntry] = getElements(editor, "image")
        let imgUrl = ""
        let imageHeight = 350
        let imageWidth = 350
        let imageAlt = ""
        let imageBorder = 0
        let imgMarginHorizontal = 0
        let imgMarginVertical = 0
        let imgFloat = "none"

        if (imgEntry) {
            imgUrl = imgEntry[0]["url"]
            imageHeight = imgEntry[0]["height"] ? parseInt(imgEntry[0]["height"], 10) : imageHeight
            imageWidth = imgEntry[0]["width"] ? parseInt(imgEntry[0]["width"], 10) : imageWidth
            imageAlt = imgEntry[0]["alt"]
            imageBorder = imgEntry[0]["border"]
            imgMarginHorizontal = imgEntry[0]["marginHorizontal"]
            imgMarginVertical = imgEntry[0]["marginVertical"]
            imgFloat = imgEntry[0]["float"] || imgFloat
        }

        setState(prevState => ({
            ...prevState,
            imageUrl: imgUrl,
            imageHeight,
            imageWidth,
            imageAlt,
            imageBorder,
            imgMarginHorizontal,
            imgMarginVertical,
            imgFloat,
            isOpenImageDialog: true
        }))
    }

    const setField = (field: FieldType, value: string | number) => {
        setState(prevState => ({ ...prevState, [field]: value }))
    }

    return (
        <EditorContext.Provider
            value={{
                state,
                actions: {
                    closeDialog,
                    openLinkDialog,
                    openImageDialog,
                    setField
                }
            }}
        >
            {children}
        </EditorContext.Provider>
    )
}
