import { Box, SxProps } from "@mui/material"

export interface CUITabPanelProps {
    children?: React.ReactNode
    index: any
    value: any
    sx?: SxProps
}

export function CUITabPanel(props: CUITabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`cui-tabpanel-${index}`}
            aria-labelledby={`cui-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Box>
    )
}

export default CUITabPanel
