import HelpIcon from "@mui/icons-material/Help"
import { Box, SxProps } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import { Control, Controller, FieldPath, FieldValues, useFormContext } from "react-hook-form"
import { CUIFormInputParams } from "./CUIFormInputParams"
import { useComponentProps } from "./useComponentProps"
import { useAddMessagesToRules } from "./useAddMessagesToRules"
import { useMemo } from "react"

/**
 * Props para CUIFormInput
 *
 * @typeParam T - Componente de React
 * @typeParam V - Tipo de los props del componente
 * @typeParam U - Tipo del objeto cuando existe un arreglo de opciones
 */
export interface CUIFormInputProps<
    T extends React.ElementType<any>,
    V extends React.ComponentPropsWithRef<T>,
    U = any,
    W = any
> {
    /**
     * Objeto que define las propiedades de un componente, para
     * poder armar un campo en el formulario
     *
     * @type {CUIFormInputParams<T, V, U>}
     */
    inputBuilder: CUIFormInputParams<T, V, U>

    /**
     * Objeto control de react-hook-form para manejar el estado del formulario.
     * Si no viene, usa el del contexto.
     *
     * No acceder sus atributos directamente por recomendación de la librería. @see https://react-hook-form.com/api/useform/control
     *
     * @see https://react-hook-form.com/api/useform
     */
    control?: Control<W, any>

    /**
     * Estilos para el componente basados en la librería de estilos de Material UI
     */
    sx?: SxProps
}

/**
 * Componente que permite armar un campo en un formulario, usando react-hook-form.
 *
 * @param {CUIFormInputProps<T, V, U>} props - Props para CUIFormInput
 * @returns {JSX.Element} - Componente de React
 */
export function CUIFormInput<
    T extends React.ElementType,
    V extends React.ComponentPropsWithRef<T> = React.ComponentPropsWithRef<T>,
    U = any,
    W = any
>({ inputBuilder, control, sx }: CUIFormInputProps<T, V, U, W>) {
    const methods = useFormContext()
    control = control ?? (methods.control as unknown as Control<W, any>)

    const {
        name,
        component: Component,
        tooltip,
        tooltipAlign = "center",
        tooltipTrigger = <HelpIcon />,
        rules
    } = inputBuilder

    const { addMessagesToRules } = useAddMessagesToRules()

    const extendedRules = useMemo(() => {
        return addMessagesToRules(rules, inputBuilder.label as string)
    }, [rules, inputBuilder.label])

    return (
        <Box display="flex" alignItems={tooltipAlign} sx={sx}>
            {/* TODO(efrias): revisar el tipado de name y control */}
            <Controller
                name={name as FieldPath<W>}
                control={control as unknown as Control<FieldValues, any>}
                render={({ field, formState, fieldState }) => {
                    const fullComponentProps = useComponentProps(Component, { field, fieldState }, inputBuilder)

                    return <Component {...fullComponentProps} />
                }}
                rules={extendedRules}
            />
            {tooltip && (
                <Tooltip sx={{ ml: 1 }} title={<Box fontSize="14px">{tooltip}</Box>}>
                    {tooltipTrigger}
                </Tooltip>
            )}
        </Box>
    )
}
