import { useTranslateLib } from "../i18n/useTranslateLib"

/**
 * Hook que retorna una función que retorna un mensaje de error si el valor es requerido
 *
 * @returns Objeto con la función `requiredError` que retorna un mensaje de error si el valor es requerido
 */
export function useRequiredError() {
    const { t } = useTranslateLib("use_required_error")

    return {
        requiredError: (label: string) => t("required_error", { label })
    }
}
