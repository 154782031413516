import { Session } from "../session/Session"
import { useSession } from "../session/SessionContext"
import { useEffect, useMemo, useState } from "react"
import { deleteCookie } from "cookies-next"

export interface AuthGuardProps<T> {
    component?: (props: T) => JSX.Element
    componentProps?: T
    render?: () => JSX.Element
    children?: React.ReactNode
    loginPath?: string
    asPath: string
    push: (path: string) => void
}

export function AuthGuard<T>({
    component: RenderComponent,
    componentProps,
    asPath,
    push,
    render,
    children,
    loginPath = "/landing"
}: AuthGuardProps<T>) {
    const session = useSession<Session<any, any>>()

    const redirectUrlAfterInvalidToken = session?.sessionData?.redirectUrl?.afterInvalidToken
    const redirectUrlAfterRegister = session?.sessionData?.redirectUrl?.afterRegister

    const hasToNotRedirect = useMemo(
        () => session?.isLoggedIn && !redirectUrlAfterInvalidToken && !redirectUrlAfterRegister,
        [asPath]
    )
    const [hasToRender, setHasToRender] = useState(hasToNotRedirect)

    useEffect(() => {
        if (asPath && session) {
            if (session.isLoggedIn) {
                if (!hasToRender) {
                    setHasToRender(true)
                }

                if (redirectUrlAfterInvalidToken || redirectUrlAfterRegister) {
                    session.set({
                        ...session.sessionData,
                        redirectUrl: {}
                    })

                    if (redirectUrlAfterRegister) {
                        push(redirectUrlAfterRegister)
                    } else if (redirectUrlAfterInvalidToken) {
                        push(redirectUrlAfterInvalidToken)
                    }
                }
            } else {
                session.set(() => {
                    return {
                        redirectUrl: {
                            afterInvalidToken: asPath
                        }
                    }
                })
                deleteCookie("puntaje_usuario_id")
                deleteCookie("puntaje_usuario_rol")

                push(loginPath)
            }
        }
    }, [asPath, !!session])

    return hasToRender ? (
        RenderComponent ? (
            <RenderComponent {...componentProps} />
        ) : render ? (
            render()
        ) : children ? (
            <>{children}</>
        ) : null
    ) : null
}
