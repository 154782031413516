import { es } from "./locales/es"
import { esCL } from "./locales/es-cl"
import { esGEN } from "./locales/es-gen"
import { esMX } from "./locales/es-mx"
import { esPNCO } from "./locales/es-pn-co"
import { pt } from "./locales/pt"

export const sharedCoreTranslations = {
    es: es,
    "es-cl": esCL,
    "es-gen": esGEN,
    "es-mx": esMX,
    "es-co": esPNCO,
    pt: pt
}
