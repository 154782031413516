import { EvaluacionTipoMap, EvaluacionConf } from "../types/configuration.types"

export class EvaluacionTipoDefaultConfig {
    static config: EvaluacionTipoMap = {
        ensayo: {
            alias: "ensayo",
            evaluacionTipo: "ensayo",
            cantidadPreguntas: 5,
            clasificaciones: {
                estadisticas: ["eje temático", "sub eje tematico"],
                estadisticasEvolutivas: ["eje temático", "sub eje tematico"],
                desempenoEvaluacion: ["eje temático", "sub eje tematico"],
                clasificacionTiposTablaEspecificaciones: ["eje temático", "habilidad cognitiva"],
                methods: "clasificaciones",
                referencesTo: [{ key: "sub eje tematico", value: "eje temático" }],
                cambiarPregunta: ["eje temático", "sub eje tematico"],
                groupBy: [["eje temático", "sub eje tematico"], ["curso PSU"], ["habilidad cognitiva"], ["mención"]]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "eje temático",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático",
                    compuesto: true,
                    alumnos: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "sub eje tematico",
                    compuesto: true,
                    alumnos: false,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "habilidad cognitiva",
                    compuesto: true,
                    alumnos: false,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso",
            cursos: [
                { clasificacion: "Séptimo Básico", label: "7º Básico" },
                { clasificacion: "Octavo Básico", label: "8º Básico" },
                { clasificacion: "Primero Medio", label: "1º Medio" },
                { clasificacion: "Segundo Medio", label: "2º Medio" },
                { clasificacion: "Tercero Medio", label: "3º Medio" },
                { clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            cursosMaterial: [],
            estrellas: [
                { min: 150, max: 266 },
                { min: 267, max: 383 },
                { min: 384, max: 500 },
                { min: 501, max: 617 },
                { min: 618, max: 734 },
                { min: 735, max: 850 }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases PSU"
        },
        "prueba de transición": {
            alias: "prueba de transición",
            evaluacionTipo: "prueba de transición",
            cantidadPreguntas: 5,
            clasificaciones: {
                estadisticas: ["nivel 1 prueba de transición", "nivel 2 prueba de transición"],
                estadisticasEvolutivas: ["nivel 1 prueba de transición", "nivel 2 prueba de transición"],
                desempenoEvaluacion: ["nivel 1 prueba de transición", "nivel 2 prueba de transición"],
                clasificacionTiposTablaEspecificaciones: [
                    "nivel 1 prueba de transición",
                    "habilidad prueba de transición"
                ],
                methods: "clasificaciones",
                referencesTo: [{ key: "nivel 2 prueba de transición", value: "nivel 1 prueba de transición" }],
                cambiarPregunta: ["nivel 1 prueba de transición"],
                groupBy: [["nivel 1 prueba de transición", "nivel 2 prueba de transición"]]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "nivel 1 prueba de transición",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "nivel 1 prueba de transición",
                    compuesto: true,
                    alumnos: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "nivel 2 prueba de transición",
                    compuesto: true,
                    alumnos: false,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "habilidad prueba de transición",
                    compuesto: true,
                    alumnos: false,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso PSU",
            cursos: [
                { clasificacion: "Séptimo Básico", label: "7º Básico" },
                { clasificacion: "Octavo Básico", label: "8º Básico" },
                { clasificacion: "Primero Medio", label: "1º Medio" },
                { clasificacion: "Segundo Medio", label: "2º Medio" },
                { clasificacion: "Tercero Medio", label: "3º Medio" },
                { clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            cursosMaterial: [],
            estrellas: [
                { min: 150, max: 266 },
                { min: 267, max: 383 },
                { min: 384, max: 500 },
                { min: 501, max: 617 },
                { min: 618, max: 734 },
                { min: 735, max: 850 }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases Prueba de transición"
        },
        curricular: {
            alias: "prueba",
            evaluacionTipo: "curricular",
            cantidadPreguntas: 4,
            clasificaciones: {
                estadisticas: ["eje temático LGE"],
                estadisticasEvolutivas: ["eje temático LGE"],
                desempenoEvaluacion: ["eje temático LGE"],
                clasificacionTiposTablaEspecificaciones: ["objetivo de aprendizaje", "habilidad cognitiva LGE"],
                methods: "clasificaciones",
                referencesTo: [{ key: "objetivo de aprendizaje", value: "curso" }],
                groupBy: [
                    ["eje temático LGE", "objetivo de aprendizaje"],
                    ["unidad LGE", "objetivo de aprendizaje"],
                    ["unidad LOCE", "aprendizaje esperado"]
                ]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "eje temático LGE",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático LGE",
                    compuesto: false,
                    alumnos: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    // HEY LISTEN! acordarse que hay que manejar este caso de forma general
                    nombre: "aprendizaje esperado",
                    compuesto: false,
                    alumnos: false,
                    profesores: false,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso",
            cursos: [
                { clasificacion: "Séptimo Básico", label: "7º Básico" },
                { clasificacion: "Octavo Básico", label: "8º Básico" },
                { clasificacion: "Primero Medio", label: "1º Medio" },
                { clasificacion: "Segundo Medio", label: "2º Medio" },
                { clasificacion: "Tercero Medio", label: "3º Medio" },
                { clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            cursosMaterial: [
                { id: 7, clasificacion: "Séptimo Básico", label: "7º Básico" },
                { id: 8, clasificacion: "Octavo Básico", label: "8º Básico" },
                { id: 9, clasificacion: "Primero Medio", label: "1º Medio" },
                { id: 10, clasificacion: "Segundo Medio", label: "2º Medio" },
                { id: 11, clasificacion: "Tercero Medio", label: "3º Medio" },
                { id: 12, clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            estrellas: [
                { min: 1, max: 2 },
                { min: 2, max: 3 },
                { min: 3, max: 4 },
                { min: 4, max: 5 },
                { min: 5, max: 6 },
                { min: 6, max: 7 }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 0,
                tickInterval: 25,
                max: 400
            },
            streamingChannelAlias: "Clases Curriculares",
            hideEstadistica: false
        },
        default: {
            cantidadPreguntas: 4,
            clasificaciones: {
                estadisticas: [],
                estadisticasEvolutivas: [],
                desempenoEvaluacion: [],
                clasificacionTiposTablaEspecificaciones: [],
                methods: undefined,
                referencesTo: [],
                groupBy: []
            },
            cursos: [
                { clasificacion: "Séptimo Básico", label: "7º Básico" },
                { clasificacion: "Octavo Básico", label: "8º Básico" },
                { clasificacion: "Primero Medio", label: "1º Medio" },
                { clasificacion: "Segundo Medio", label: "2º Medio" },
                { clasificacion: "Tercero Medio", label: "3º Medio" },
                { clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            cursosMaterial: [
                { id: 7, clasificacion: "Séptimo Básico", label: "7º Básico" },
                { id: 8, clasificacion: "Octavo Básico", label: "8º Básico" },
                { id: 9, clasificacion: "Primero Medio", label: "1º Medio" },
                { id: 10, clasificacion: "Segundo Medio", label: "2º Medio" },
                { id: 11, clasificacion: "Tercero Medio", label: "3º Medio" },
                { id: 12, clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            estrellas: [
                { min: 1, max: 2 },
                { min: 2, max: 3 },
                { min: 3, max: 4 },
                { min: 4, max: 5 },
                { min: 5, max: 6 },
                { min: 6, max: 7 }
            ]
        }
    }
}
