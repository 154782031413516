import React, { ReactNode } from "react"
import Box, { BoxProps } from "@mui/material/Box"
import CUIContentLayoutProps from "./CUIContentLayoutProps"
import CUIContentBox from "./CUIContentBox"
import { useContentLayoutOptions } from "./CUIContentLayoutProvider"
import CUIHeadline from "./CUIHeadline"

export interface CUIDefaultCLProps extends CUIContentLayoutProps {}

export function CUIDefaultCL(props: CUIDefaultCLProps) {
    const { boxedContent } = useContentLayoutOptions()
    return (
        <div>
            {props.upperContent && <div>{props.upperContent}</div>}
            <CUIContentBox boxed={boxedContent}>
                {props.overHeadline && <div>{props.overHeadline}</div>}
                {props.headline && <CUIHeadline>{props.headline}</CUIHeadline>}
                {props.content}
                {props.children}
            </CUIContentBox>
        </div>
    )
}

export default CUIDefaultCL
