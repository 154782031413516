import { createContext, ReactNode, useContext, useEffect } from "react"
interface ConfigContextProps<T> {
    config: T
    children: ReactNode
}

const ConfigContext = createContext<{}>(null)

export function useConfig<T>() {
    return useContext(ConfigContext) as T
}

export function ConfigProvider<T>({ children, config }: ConfigContextProps<T>) {
    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}
