import { ControllerFieldState, ControllerRenderProps, FieldValues } from "react-hook-form"
import { useOptionsForm } from "./useOptionsForm"

export const useCUIRadiosFormProps = (
    {
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
    }: {
        field: ControllerRenderProps<FieldValues, string>
        fieldState: ControllerFieldState
    },
    inputBuilder
) => {
    const { query } = useOptionsForm(name, value, inputBuilder)

    const options = query ? (query.data ? query.data : []) : inputBuilder.options

    const labelExtractor = option =>
        typeof option === "string"
            ? option
            : inputBuilder.getOptionLabel
            ? inputBuilder.getOptionLabel(option)
            : typeof inputBuilder.componentProps?.labelExtractor == "string"
            ? option[inputBuilder.componentProps?.labelExtractor]
            : inputBuilder.componentProps?.labelExtractor?.(option) ?? option.label

    const idExtractor = option =>
        typeof option === "string"
            ? option
            : typeof inputBuilder.componentProps?.idExtractor == "string"
            ? option[inputBuilder.componentProps?.idExtractor]
            : inputBuilder.componentProps?.idExtractor?.(option) ?? option.id ?? labelExtractor(option)

    return {
        ...inputBuilder.componentProps,
        onChange,
        idExtractor,
        labelExtractor,
        options,
        value: value,
        id: name,
        label: inputBuilder.label,
        ref,
        error
    }
}
