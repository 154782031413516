import { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
    Transforms,
    createEditor,
    Editor,
    Element as SlateElement,
    Point,
    Range,
    Descendant,
    BaseElement,
    Text
} from "slate"
import { Editable, withReact, Slate, RenderLeafProps, RenderElementProps } from "slate-react"
import { withHistory } from "slate-history"
import isHotkey from "is-hotkey"
import isUrl from "is-url"
import { Box, styled } from "@mui/material"
import {
    BlockButton,
    ClearButton,
    EditorProvider,
    EquationButton,
    HistoryButton,
    Image,
    ImageButton,
    LinkButton,
    MarkButton,
    RemoveLinkButton,
    toggleMark,
    Toolbar,
    wrapLink,
    DeleteTableButton,
    Table,
    TableButton,
    TableCell,
    TableHeader,
    CutButton,
    CopyButton,
    deserialize,
    SpecialCharacterButton,
    AlignButton,
    PasteButton,
    EditorLink,
    toggleBlock,
    Dropdown,
    Equation,
    serialize
} from "./Editor"
import { CustomEditor } from "./custom-types"
import { EditableProps } from "slate-react/dist/components/editable"
import { FormHelperText } from "@mui/material"
import { Button } from "./Editor/UI"
import { CUIText } from "./CUIText"
import HtmlIcon from "@mui/icons-material/Html"
import AbcIcon from "@mui/icons-material/Abc"

export interface EditorActions {
    reset: () => void
}

interface IProps {
    /**
     * Valor inicial del editor
     *
     * Puede ser un HTML o un arreglo de @type {Descendant}
     *
     * @see https://docs.slatejs.org/concepts/03-model
     */
    value: Descendant[] | string

    /**
     * Función que se ejecuta cuando se sube una imágen.
     */
    onUpload?: ((file: File) => Promise<string>) | undefined

    /**
     * Función que se ejecuta cuando cambia el valor del editor.
     *
     * @param {Descendant[]} value - Arreglo de @type {Descendant}
     */
    onChange?: ((value: Descendant[]) => void) | undefined

    /**
     * Función que se ejecuta cuando cambia el valor del editor con el HTML serializado.
     *
     * @param {string} value - HTML serializado
     */
    onChangeHTML?: ((value: string) => void) | undefined

    /**
     * Ref que tiene métodos de utilidad para manipular el editor.
     */
    managerRef?: React.MutableRefObject<EditorActions>

    editableProps?: EditableProps
    error?: boolean
    helpertext?: string
}

const HOTKEYS = {
    "mod+b": "bold",
    "mod+i": "italic",
    "mod+u": "underline",
    "mod+`": "code"
}

const EditorContainer = styled(Box)(({ theme }) => ({
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.divider,
    "& .editable-input": {
        minHeight: "300px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: theme.palette.divider,
        resize: "vertical",
        overflow: "auto",
        padding: theme.spacing(1)
    },
    "& .error": {
        borderColor: theme.palette.error.main
    }
}))

const withCustomizations = (editor: CustomEditor) => {
    const { insertData, isInline, insertText, isVoid, deleteBackward, deleteForward, insertBreak } = editor

    editor.isInline = element => ["link", "image", "equation"].includes(element.type) || isInline(element)

    editor.isVoid = element => ["image", "equation"].includes(element.type) || isVoid(element)

    editor.insertText = text => {
        if (text && isUrl(text)) {
            wrapLink(editor, text, { expand: false })
        } else {
            insertText(text)
        }
    }

    editor.insertData = data => {
        const text = data.getData("text/plain")
        if (text && isUrl(text)) {
            wrapLink(editor, text, { expand: false })
            return
        }

        const html = data.getData("text/html")

        if (html) {
            const parsed = new DOMParser().parseFromString(html, "text/html")
            const fragment = deserialize(parsed.body)
            Transforms.insertFragment(editor, fragment)
            return
        }

        insertData(data)
    }

    editor.deleteBackward = unit => {
        const { selection } = editor
        if (selection) {
            const [cell] = Editor.nodes(editor, {
                match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table-cell"
            })

            const prevNodePath = Editor.before(editor, selection)

            const [tableNode] = Editor.nodes(editor, {
                at: prevNodePath,
                match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table-cell"
            })

            if (cell) {
                const [, cellPath] = cell

                const start = Editor.start(editor, cellPath)
                if (Point.equals(selection.anchor, start)) {
                    return
                }
            }
            if (!cell && tableNode) {
                return
            }
        }

        deleteBackward(unit)
    }

    editor.deleteForward = unit => {
        const { selection } = editor
        if (selection && Range.isCollapsed(selection)) {
            const [cell] = Editor.nodes(editor, {
                match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table-cell"
            })

            const prevNodePath = Editor.after(editor, selection)

            const [tableNode] = Editor.nodes(editor, {
                at: prevNodePath,
                match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table-cell"
            })

            if (cell) {
                const [, cellPath] = cell
                const end = Editor.end(editor, cellPath)

                if (Point.equals(selection.anchor, end)) {
                    return
                }
            }
            if (!cell && tableNode) {
                return
            }
        }

        deleteForward(unit)
    }

    editor.insertBreak = () => {
        const { selection } = editor

        const [list] = Editor.nodes(editor, {
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                (n.type === "numbered-list" || n.type === "bulleted-list")
        })

        if (list) {
            const lastIndex = list[0]["children"].length - 1
            const lastListItem = list[0]["children"][lastIndex]["children"][0]["text"]
            const hasContent = lastListItem.trim().length

            if (!hasContent) {
                const listType = list[0]["type"]

                toggleBlock(editor, listType)
                return
            }
        }
        if (selection) {
            const [table] = Editor.nodes(editor, {
                match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "table"
            })

            if (table) {
                return
            }
        }

        insertBreak()
    }

    return editor
}

//Retorna elementos html -a excepción de los que estilizan texto-
const Element = ({ attributes, children, element }: RenderElementProps) => {
    const baseAttributes = {}
    if (element["id"]) baseAttributes["id"] = element["id"]
    if (element["class"]) baseAttributes["className"] = element["class"]
    if (element["role"]) baseAttributes["role"] = element["role"]
    if (element["style"]) baseAttributes["style"] = cssInObject(element["style"])

    switch (element.type) {
        case "image":
            return <Image attributes={attributes} children={children} element={element} />
        case "equation":
            return <Equation attributes={attributes} children={children} math={element.math} />
        case "link":
            return (
                <EditorLink
                    baseAttributes={baseAttributes}
                    attributes={attributes}
                    element={element}
                    children={children}
                />
            )
        case "block-quote":
            return (
                <blockquote {...baseAttributes} {...attributes}>
                    {children}
                </blockquote>
            )
        case "bulleted-list":
            return (
                <ul {...baseAttributes} {...attributes}>
                    {children}
                </ul>
            )
        case "heading-one":
            return (
                <h1 {...baseAttributes} {...attributes}>
                    {children}
                </h1>
            )
        case "heading-two":
            return (
                <h2 {...baseAttributes} {...attributes}>
                    {children}
                </h2>
            )
        case "heading-three":
            return (
                <h3 {...baseAttributes} {...attributes}>
                    {children}
                </h3>
            )
        case "heading-four":
            return (
                <h4 {...baseAttributes} {...attributes}>
                    {children}
                </h4>
            )
        case "heading-five":
            return (
                <h5 {...baseAttributes} {...attributes}>
                    {children}
                </h5>
            )
        case "heading-six":
            return (
                <h6 {...baseAttributes} {...attributes}>
                    {children}
                </h6>
            )
        case "list-item":
            return (
                <li {...baseAttributes} {...attributes}>
                    {children}
                </li>
            )
        case "numbered-list":
            return (
                <ol {...baseAttributes} {...attributes}>
                    {children}
                </ol>
            )
        case "table":
            return (
                <Table baseAttributes={baseAttributes} attributes={attributes} children={children} element={element} />
            )
        case "table-row":
            return (
                <tr {...baseAttributes} {...attributes}>
                    {children}
                </tr>
            )
        case "table-body":
            return (
                <tbody {...baseAttributes} {...attributes}>
                    {children}
                </tbody>
            )
        case "table-head":
            return (
                <thead {...baseAttributes} {...attributes}>
                    {children}
                </thead>
            )
        case "caption":
            return (
                <caption {...baseAttributes} {...attributes}>
                    {children}
                </caption>
            )
        case "table-cell":
            return (
                <TableCell
                    baseAttributes={baseAttributes}
                    attributes={attributes}
                    children={children}
                    element={element}
                />
            )
        case "table-cell-header":
            return (
                <TableHeader
                    baseAttributes={baseAttributes}
                    attributes={attributes}
                    children={children}
                    element={element}
                />
            )
        case "html":
            return (
                <div {...baseAttributes} {...attributes}>
                    {children}
                </div>
            )
        case "paragraph":
            return (
                <p {...baseAttributes} {...attributes}>
                    {children}
                </p>
            )
        case "span":
            return (
                <span {...baseAttributes} {...attributes}>
                    {children}
                </span>
            )
        case "div":
            return (
                <div {...baseAttributes} {...attributes}>
                    {children}
                </div>
            )
        case "style":
            return <style>{(element.children && element.children[0].text) || ""}</style>

        default:
            return <>{children}</>
    }
}

const cssInObject = cssString => {
    if (!cssString) return {}
    return cssString
        .split(";")
        .map(cur => cur.split(":"))
        .reduce((acc, val) => {
            let [key, value] = val
            if (key !== "" && value !== undefined) {
                key = key.trim().replace(/-./g, css => css.toUpperCase()[1])
                acc[key] = value.trim()
            }
            return acc
        }, {})
}

//Agrega etiquetas que estilizan texto
const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
    const style = { fontSize: leaf["fontSize"], fontFamily: leaf["fontFamily"] }

    if (leaf["bold"]) {
        children = <strong>{children}</strong>
    }

    if (leaf["code"]) {
        children = <code>{children}</code>
    }

    if (leaf["italic"]) {
        children = <em>{children}</em>
    }

    if (leaf["underline"]) {
        children = <u>{children}</u>
    }

    if (leaf["strikethrough"]) {
        children = <s>{children}</s>
    }

    if (leaf["superscript"]) {
        children = <sup>{children}</sup>
    }

    if (leaf["subscript"]) {
        children = <sub>{children}</sub>
    }

    if (leaf["marker"]) {
        children = (
            <span className="marker" style={{ backgroundColor: "#FFFF00" }}>
                {children}
            </span>
        )
    }

    return (
        <span style={style} {...attributes}>
            {children}
        </span>
    )
}

CUIEditor.cuiComponentName = "CUIEditor"

const randomClass = () => {
    return "styleWrap-" + Math.random().toString(36).slice(2, 7)
}

const htmlToSlate = (html: string | Descendant[]) => {
    if (typeof html === "string" && html) {
        const parsed = new DOMParser().parseFromString(html, "text/html")
        const fragment = deserialize(parsed.documentElement, randomClass())
        return Array.isArray(fragment) ? fragment : [fragment]
    } else if (html) {
        return html
    } else {
        return [{ type: "paragraph", children: [{ text: "" }] }]
    }
}

export function CUIEditor(props: IProps) {
    const [htmlMode, setHtmlMode] = useState(false)
    const initialValue: Descendant[] = useMemo(() => {
        const value = props.value
        return htmlToSlate(value) as Descendant[]
    }, [])

    const {
        value = initialValue,
        onChange,
        onUpload,
        onChangeHTML,
        managerRef,
        editableProps,
        error,
        helpertext
    } = props

    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withCustomizations(withReact(withHistory(createEditor()))), [])

    const handleOnChange = value => {
        if (htmlMode) {
            return
        }
        const isAstChange = editor.operations.some(op => "set_selection" !== op.type)

        if (isAstChange) {
            onChange?.(value)
            onChangeHTML?.(serialize({ children: value }, htmlMode))
        }
    }

    const clearEditor = () => {
        const length = editor.children.length
        // un for porque el largo de children va cambiando mientras los voy sacando
        for (let i = 0; i < length; i++) {
            Transforms.removeNodes(editor, { at: [0] })
        }
    }

    const handleOnChangeToHtmlEditor = () => {
        const htmlString = serialize({ children: editor.children }) + ""
        clearEditor()
        Transforms.insertNodes(
            editor,
            { type: "paragraph", children: [{ text: htmlString }] },
            { at: [editor.children.length] }
        )
    }

    const clearHtml = input => {
        const output = input
            // remove eols between tags
            .replace(/\>[\r\n ]+\</g, "><")
            // remove spaces between tags
            .replace(/(<.*?>)|\s+/g, (m, $1) => $1 || " ")
            .trim()
        return output
    }

    const handleOnChangeFromHtmlEditor = () => {
        Transforms.select(editor, {
            anchor: Editor.start(editor, []),
            focus: Editor.end(editor, [])
        })
        const htmlString = clearHtml(Editor.string(editor, editor.selection)) //((editor.children[0] as BaseElement).children[0] as Text).text
        const slateValue = htmlToSlate(htmlString)
        onChange?.(slateValue as Descendant[])
        onChangeHTML?.(htmlString)
        clearEditor()
        Transforms.insertNodes(editor, slateValue as Descendant[], { at: [editor.children.length] })
    }

    useEffect(() => {
        if (managerRef) {
            managerRef.current = {
                reset: () => {
                    Transforms.select(editor, {
                        anchor: Editor.start(editor, []),
                        focus: Editor.end(editor, [])
                    })

                    Transforms.insertFragment(editor, initialValue)
                }
            }
        }
    }, [managerRef])

    return (
        <EditorContainer>
            <Slate editor={editor} value={initialValue} onChange={handleOnChange}>
                <EditorProvider>
                    <Box display="flex" flexDirection="column" alignItems="stretch" padding={1}>
                        <Toolbar>
                            {!htmlMode ? (
                                <>
                                    <CutButton />
                                    <CopyButton />
                                    <PasteButton />
                                    <HistoryButton action="undo" title="Deshacer" />
                                    <HistoryButton action="redo" title="Rehacer" />
                                    <LinkButton />
                                    <RemoveLinkButton />
                                    <ImageButton onUpload={onUpload} />
                                    <MarkButton format="bold" icon="format_bold" title="Negrita" />
                                    <MarkButton format="italic" icon="format_italic" title="Cursiva" />
                                    <MarkButton format="underline" icon="format_underlined" title="Subrayar" />
                                    <MarkButton format="strikethrough" icon="format_strikethrough" title="Tachar" />
                                    <MarkButton format="code" icon="code" title="Código" />
                                    <MarkButton format="superscript" icon="superscript" title="Superíndice" />
                                    <MarkButton format="subscript" icon="subscript" title="Subíndice" />
                                    <MarkButton format="marker" icon="drive_file_rename_outline" title="Destacador" />
                                    <Dropdown format="fontFamily" />
                                    <Dropdown format="fontSize" />
                                    <ClearButton />
                                    <EquationButton />
                                    <BlockButton
                                        format="heading-one"
                                        icon="looks_one"
                                        title="Título 1"
                                        disabled={htmlMode}
                                    />
                                    <BlockButton
                                        format="heading-two"
                                        icon="looks_two"
                                        title="Título 2"
                                        disabled={htmlMode}
                                    />
                                    <BlockButton
                                        format="heading-three"
                                        icon="looks_3"
                                        title="Título 3"
                                        disabled={htmlMode}
                                    />
                                    <BlockButton
                                        format="heading-four"
                                        icon="looks_4"
                                        title="Título 4"
                                        disabled={htmlMode}
                                    />
                                    <BlockButton
                                        format="heading-five"
                                        icon="looks_5"
                                        title="Título 5"
                                        disabled={htmlMode}
                                    />
                                    <BlockButton
                                        format="heading-six"
                                        icon="looks_6"
                                        title="Título 6"
                                        disabled={htmlMode}
                                    />
                                    <BlockButton
                                        format="block-quote"
                                        icon="format_quote"
                                        title="Citar"
                                        disabled={htmlMode}
                                    />
                                    <BlockButton
                                        format="numbered-list"
                                        icon="format_list_numbered"
                                        title="Lista numerada"
                                    />
                                    <BlockButton
                                        format="bulleted-list"
                                        icon="format_list_bulleted"
                                        title="Lista"
                                        disabled={htmlMode}
                                    />
                                    <BlockButton
                                        format="left"
                                        icon="format_align_left"
                                        title="Alinear izquierda"
                                        disabled={htmlMode}
                                    />
                                    <BlockButton
                                        format="center"
                                        icon="format_align_center"
                                        title="Alinear centro"
                                        disabled={htmlMode}
                                    />
                                    <BlockButton
                                        format="right"
                                        icon="format_align_right"
                                        title="Alinear derecha"
                                        disabled={htmlMode}
                                    />
                                    <BlockButton
                                        format="justify"
                                        icon="format_align_justify"
                                        title="Justificar"
                                        disabled={htmlMode}
                                    />
                                    <AlignButton action="decrease" title="Agregar sangría" />
                                    <AlignButton action="increase" title="Disminuir sangría" />
                                    <SpecialCharacterButton />
                                    <TableButton />
                                    <DeleteTableButton />
                                </>
                            ) : (
                                <Box minWidth={"450px"}></Box>
                            )}
                            <Button
                                active={htmlMode}
                                onMouseDown={() => {
                                    if (!htmlMode) {
                                        setHtmlMode(true)
                                        handleOnChangeToHtmlEditor()
                                    } else {
                                        handleOnChangeFromHtmlEditor()
                                        setHtmlMode(false)
                                    }
                                }}
                            >
                                {htmlMode ? <AbcIcon></AbcIcon> : <HtmlIcon></HtmlIcon>}
                            </Button>
                        </Toolbar>
                        <Editable
                            className={`editable-input ${error ? "error" : ""}`}
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            spellCheck
                            autoFocus
                            onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
                                for (const hotkey in HOTKEYS) {
                                    if (isHotkey(hotkey, event)) {
                                        event.preventDefault()
                                        const mark = HOTKEYS[hotkey]
                                        toggleMark(editor, mark)
                                    }
                                }
                            }}
                            {...editableProps}
                        />
                        {helpertext && <FormHelperText error={error}> {props.helpertext} </FormHelperText>}
                    </Box>
                </EditorProvider>
            </Slate>
        </EditorContainer>
    )
}
