import { AuthGuard, AuthGuardProps } from "@puntaje/react/shared/core"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export function ClonadorAuthGuard<T>(props: Omit<AuthGuardProps<T>, "push" | "asPath">) {
    const navigate = useNavigate()
    const location = useLocation()
    const [render, setRender] = useState(false)

    // Esto es porque la función navigate, antes que funcione, necesita que el componente esté montado.
    // Si no, se ejecute primero el useEffect del componente hijo antes de este, lo que hace que no haga nada y no redirija la primera vez.
    useEffect(() => {
        setRender(true)
    }, [])

    return render && <AuthGuard {...props} push={navigate} asPath={location.pathname} loginPath="/" />
}
