import { styled } from "@mui/material/styles"
import React, { ReactNode } from "react"
import Box, { BoxProps } from "@mui/material/Box"
import CUIDefaultCL from "./ContentLayouts/CUIDefaultCL"
import { popoverClasses } from "@mui/material"
import { CUIContentLayoutProvider } from "./ContentLayouts/CUIContentLayoutProvider"
import { useTheme } from "@mui/material"

const LoggedLayout = styled(Box, { shouldForwardProp: prop => prop != "backgroundColor" })<{
    backgroundColor: string
}>(({ theme, backgroundColor }) => ({
    minHeight: "100vh",
    display: "grid",
    backgroundColor: backgroundColor,
    gridTemplate: `'header' 'inner' 'footer'`,
    gridTemplateRows: "fit-content(800px) auto fit-content(800px)",
    gridTemplateColumns: "100%",
    "& > .header": {
        gridArea: "header"
    },
    "& > .inner": {
        gridArea: "inner"
    },
    "& > .footer": {
        gridArea: "footer"
    },
    "&.debug": {
        "& > *": {
            border: "1px solid #c3c3c3"
        }
    }
}))

const InnerBox = styled(Box)(({ theme }) => ({
    width: "100%",
    boxSizing: "border-box"
}))

const UpperBox = styled(Box)(({ theme }) => ({
    width: "100%",
    boxSizing: "border-box"
}))

const boxed = {
    mb: 2,
    mt: 0,
    mx: "auto",
    p: 2,
    pb: 3,
    backgroundColor: "background.paper",
    borderRadius: 0,
    boxShadow: 8,
    maxWidth: "1400px"
}

const innerStyle = {
    oneColumn: {
        mb: 0,
        mt: 0,
        mx: "auto",
        p: 2,
        pt: 2,
        backgroundColor: "background.paper",
        borderRadius: 0,
        boxShadow: 8,
        maxWidth: "1400px",
        height: "100%"
    },
    page: {
        mb: 2,
        mt: 2,
        mx: "auto",
        p: 2,
        pt: 1,
        pb: 3,
        backgroundColor: "background.paper",
        borderRadius: 0,
        boxShadow: 8,
        maxWidth: "1400px"
    },
    marginsOnly: {
        pb: 8,
        pt: 2,
        mx: "auto",
        maxWidth: "1400px"
    }
}

export interface CUILoggedLayoutProps<U = any, T extends React.Component<U> = any> extends BoxProps {
    header?: ReactNode
    footer?: ReactNode
    debug?: boolean
    innerSX?: any
    variant?: "none" | "boxed" | "oneColumn" | "page" | "marginsOnly"
    backgroundColor?: "default" | "transparent"
    contentLayout?: T
    contentLayoutProps?: U
}

export function CUIStandardLayout({
    innerSX = { maxWidth: "1400px", mx: "auto" },
    variant = "boxed",
    contentLayout,
    backgroundColor = "default",
    ...props
}: CUILoggedLayoutProps) {
    const ContentLayout = contentLayout
    const theme = useTheme()
    const boxedContent = variant == "boxed" && props.contentLayoutProps?.boxedContent != false
    let bgColor = theme.palette.background.default
    if (backgroundColor == "transparent") bgColor = "transparent"
    return (
        <CUIContentLayoutProvider contentLayoutOptions={{ boxedContent: boxedContent }}>
            <LoggedLayout
                backgroundColor={bgColor}
                sx={props.sx}
                className={`CUILoggedLayout ${props.debug ? "debug" : ""}`}
            >
                <Box className="header">{props.header}</Box>
                <Box sx={{ px: { xs: 2, sm: 2, md: 4 } }}>
                    <InnerBox
                        component="main"
                        id="#main"
                        className="inner"
                        sx={variant && innerStyle[variant] ? { ...innerStyle[variant], ...innerSX } : innerSX}
                    >
                        {ContentLayout && <ContentLayout {...props.contentLayoutProps}>{props.children}</ContentLayout>}
                        {!ContentLayout && props.children}
                    </InnerBox>
                </Box>
                <div className="footer">{props.footer}</div>
            </LoggedLayout>
        </CUIContentLayoutProvider>
    )
}

export default CUIStandardLayout
