import { Api, ApiOptions } from "@puntaje/react/shared/core"
import { CarreraSede } from "./CarreraSede"

export class CarreraSedes extends Api<CarreraSede> {
    constructor(baseUrl: string, token: string, options: ApiOptions = {}) {
        super(baseUrl, token, "carrera_sedes", "carrera_sede", options)
    }

    getValueModalidadArea(params: { attr: string }) {
        return this.all<{ valor: string }[]>().collection("getValueOf").get(params)
    }
}
