import React, { Ref, PropsWithChildren } from "react"
import { cx, css } from "@emotion/css"
import CUITooltip from "../../DataDisplay/CUITooltip"
import { useTheme } from "@mui/material"

interface BaseProps {
    className?: string
    [key: string]: unknown
}
type OrNull<T> = T | null

export const Button = React.forwardRef(
    (
        {
            className,
            active,
            reversed,
            ...props
        }: PropsWithChildren<
            {
                active: boolean
                reversed?: boolean
            } & BaseProps
        >,
        ref: Ref<OrNull<HTMLSpanElement>>
    ) => {
        const theme = useTheme()
        const mainColor = theme.palette.neutral?.main
        const hoverColor = theme.palette.secondary?.main
        return (
            <span
                {...props}
                ref={ref}
                className={cx(
                    className,
                    css`
                        cursor: pointer;
                        color: ${reversed ? (active ? "white" : "#aaa") : mainColor};
                        border: 1px solid ${active ? hoverColor : "transparent"};
                        border-radius: 2px;
                        &:hover {
                            background-color: #f4f4f4;
                        }
                    `
                )}
            />
        )
    }
)

// TODO(efrias): migrar esto a que use los iconos SVG de material, usando estos en vez del componente Icon.
export const Icon = React.forwardRef(
    ({ className, title = "", ...props }: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLSpanElement>>) => (
        <CUITooltip title={title as string} sx={{ marginBottom: "-35px" }}>
            <span
                {...props}
                ref={ref}
                className={cx(
                    "material-icons",
                    className,
                    css`
                        font-size: 18px;
                        vertical-align: text-bottom;
                    `
                )}
            />
        </CUITooltip>
    )
)

const Menu = React.forwardRef(
    ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
        <div
            {...props}
            ref={ref}
            className={cx(
                className,
                css`
                    & > * {
                        display: inline-block;
                        padding: 5px;
                    }
                `
            )}
        />
    )
)

export const Toolbar = React.forwardRef(
    ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
        <Menu
            {...props}
            ref={ref}
            className={cx(
                className,
                css`
                    position: relative;
                    padding: 1px 8px 8px;
                    border-bottom: 2px solid #eee;
                    margin-bottom: 10px;
                `
            )}
        />
    )
)
