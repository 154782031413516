import { MenuItem, styled } from "@mui/material"
import { Editor } from "slate"
import { useSlate } from "slate-react"
import { isMarkActive } from "."
import { CUISelect } from "../CUISelect"

interface IOption {
    label: string
    value: string
}

interface DropdownProps {
    format: "fontFamily" | "fontSize"
}

const fontFamilyOptions: IOption[] = [
    { label: "Arial", value: "Arial, sans-serif" },
    { label: "Comic Sans", value: "Comic Sans MS, Comic Sans, cursive" },
    { label: "Courier", value: "Courier New, monospace" },
    { label: "Georgia", value: "Georgia, serif" },
    { label: "Lucida", value: "Lucida Sans Unicode, sans-serif" },
    { label: "Tahoma", value: "Tahoma, sans-serif" },
    { label: "Times New Roman", value: "Times New Roman, serif" },
    { label: "Trebuchet", value: "Trebuchet MS, sans-serif" },
    { label: "Verdana", value: "Verdana, sans-serif" },
    { label: "Lato", value: "Lato, Roboto, Helvetica Neue, Arial, sans-serif" }
]

const fontSizeOptions = ["8", "9", "10", "11", "12", "14", "16", "18", "20", "22", "24", "26", "28", "36", "48", "72"]

const EditorDropdown = styled(CUISelect)(({ theme }) => ({
    width: "48px",
    fontSize: "12px",
    "&.fontFamily": {
        width: "100px"
    },
    "&.MuiInputBase-root": {
        margin: "2px 5px 0px"
    },
    "&:before": {
        borderColor: theme.palette.neutral.main
    },
    "&:after": {
        borderColor: theme.palette.neutral.main
    },
    "&:not(.Mui-disabled):hover::before": {
        borderColor: theme.palette.neutral.main
    }
}))

export const Dropdown = (props: DropdownProps) => {
    const { format } = props
    const editor = useSlate()

    const handleOnChange = event => {
        event.preventDefault()
        const value = event.target.value
        Editor.addMark(editor, format, value)
    }

    const options: string[] | IOption[] = format === "fontFamily" ? fontFamilyOptions : fontSizeOptions

    return (
        <EditorDropdown
            className={format}
            variant="standard"
            onChange={handleOnChange}
            size="small"
            value={isMarkActive(editor, format)}
        >
            {options.map(option => {
                const label = option.label || option
                const value = option.value || `${option}px`

                return (
                    <MenuItem key={label} value={value}>
                        {label}
                    </MenuItem>
                )
            })}
        </EditorDropdown>
    )
}
