import { useState } from "react"
import { Transforms } from "slate"
import { useSlate } from "slate-react"
import { css } from "@emotion/css"
import { Grid } from "@mui/material"

import CUITooltip from "../../DataDisplay/CUITooltip"
import { CUIDialog, CUIDialogActions, CUIDialogContent, CUIDialogTitle } from "../../Feedback"
import { CUIOmegaIcon } from "../../Icons"
import CUIButton from "../CUIButton"
import { Button as CustomButton } from "./UI"

const CHARLIST = [
    "\u0021",
    "\u0023",
    "\u0024",
    "\u0025",
    "\u0026",
    "\u0027",
    "\u0028",
    "\u0029",
    "\u002A",
    "\u002B",
    "\u002C",
    "\u002D",
    "\u002E",
    "\u002F",
    "\u003C",
    "\u003D",
    "\u003E",
    "\u003F",
    "\u0040",
    "\u005B",
    "\u005C",
    "\u005D",
    "\u005E",
    "\u005F",
    "\u0060",
    "\u007B",
    "\u007C",
    "\u007D",
    "\u007E",
    "\u00A1",
    "\u00A2",
    "\u00A3",
    "\u00A4",
    "\u00A5",
    "\u00A6",
    "\u00A7",
    "\u00A8",
    "\u00A9",
    "\u00AA",
    "\u00AB",
    "\u00AC",
    "\u00AE",
    "\u00AF",
    "\u00B5",
    "\u00B6",
    "\u00BB",
    "\u01B1",
    "\u2020",
    "\u2641",
    "\u2642",
    "\u2643",
    "\u266B"
]

export const SpecialCharacterButton = (): JSX.Element => {
    const editor = useSlate()
    const [open, setOpen] = useState(false)

    const handleToggle = () => setOpen(!open)

    const handleAccept = (char: string) => {
        Transforms.insertText(editor, char)
        handleToggle()
    }

    return (
        <>
            <CUITooltip title="Caracteres especiales">
                <CustomButton
                    active={false}
                    className={css`
                        padding: 5px 1px;
                    `}
                    onMouseDown={handleToggle}
                >
                    <CUIOmegaIcon
                        className={css`
                            max-height: 18px;
                            vertical-align: text-bottom;
                        `}
                    />
                </CustomButton>
            </CUITooltip>
            <CUIDialog onClose={handleToggle} open={open}>
                <CUIDialogTitle onClose={handleToggle}>Selecciona el caracter especial</CUIDialogTitle>
                <CUIDialogContent>
                    <Grid container>
                        {CHARLIST.map(char => (
                            <Grid item xs={1} key={char}>
                                <CUIButton variant="text" color="inherit" onClick={() => handleAccept(char)}>
                                    {char}
                                </CUIButton>
                            </Grid>
                        ))}
                    </Grid>
                </CUIDialogContent>
                <CUIDialogActions>
                    <CUIButton onMouseDown={handleToggle}>Cancelar</CUIButton>
                </CUIDialogActions>
            </CUIDialog>
        </>
    )
}
