import Card from "@mui/material/Card"
import { CardContent, TextField } from "@mui/material"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { CUIFormInput } from "@puntaje/react/shared/cui"
import { CUIText } from "@puntaje/react/shared/cui"
import { CUISelect } from "@puntaje/react/shared/cui"
import { CUICheckbox } from "@puntaje/react/shared/cui"
import { CUIThreeColumnsCL } from "@puntaje/react/shared/cui"
import { useFormContext, useWatch } from "react-hook-form"
import { useEffect } from "react"

interface InformacionBasicaProps {
    /**
     * Valor que permite distinguir al componente si se está creando o editando un clon.
     * editing es true cuando estoy editando el clon, y false cuando estoy creando un clon nuevo
     */
    editing: boolean

    /**
     * Valor que permite distinguir al componente si el clon es o no un clon de Puntaje Nacional, para
     * ocultar o mostrar los campos que correspondan.
     */
    clonPNCL: boolean
}

export default function InformacionBasica({ editing, clonPNCL }: InformacionBasicaProps) {
    const { setValue } = useFormContext()

    const plataforma = useWatch({
        name: "plataforma"
    })

    /**
     * Genera la abreviación del nombre de la plataforma según lo que ha sido ingresado en el campo nombre plataforma
     */
    const generarAbreviacion = () => {
        if (!editing && plataforma && plataforma.length > 0) {
            var abreviaturaGenerada = plataforma
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[^a-z0-9]/gi, "_")
                .toLowerCase()
            if (abreviaturaGenerada.length > 100) {
                abreviaturaGenerada = abreviaturaGenerada.slice(0, 100)
            }
            setValue("abreviatura_plataforma", abreviaturaGenerada)
        }
    }

    useEffect(() => {
        generarAbreviacion()
    }, [plataforma])

    /**
     * Cambia el país de la plataforma a Chile si es que el checkbox de clon Puntaje Nacional está activado
     */
    const onCheckClonPNCL = () => {
        if (clonPNCL) {
            setValue("configuracion.plataforma.pais", "chile")
        }
    }

    useEffect(() => {
        onCheckClonPNCL()
    }, [clonPNCL])

    const fechaCreacion = useWatch({ name: "created_at" })
    const fechaModificacion = useWatch({ name: "updated_at" })

    return (
        <>
            <Card style={{ marginBottom: "4px" }}>
                <CardContent>
                    <Typography variant="h4" component="div" mb={2}>
                        Información básica
                    </Typography>
                    <Grid container spacing={2} mb={4}>
                        <Grid item sm={6} xs={12}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        disabled: editing,
                                        fullWidth: true
                                    },
                                    name: "plataforma",
                                    label: "Nombre plataforma",
                                    rules: {
                                        required: "El nombre plataforma es obligatorio"
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        disabled: editing
                                    },
                                    name: "abreviatura_plataforma",
                                    label: "Abreviación",
                                    tooltip: "Ej: Puntaje Nacional Chile => pncl, Aprendolibre Chile => alcl",
                                    rules: !editing
                                        ? {
                                              required: "La abreviación del nombre plataforma es obligatoria"
                                          }
                                        : undefined
                                }}
                            />
                        </Grid>
                        {editing && (
                            <>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        label="Fecha creación"
                                        value={new Date(fechaCreacion).toLocaleDateString("es-ES", {
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                            second: "numeric"
                                        })}
                                        disabled={true}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <TextField
                                        label="Fecha última modificacion"
                                        value={new Date(fechaModificacion).toLocaleDateString("es-ES", {
                                            year: "numeric",
                                            month: "numeric",
                                            day: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                            second: "numeric"
                                        })}
                                        disabled={true}
                                        fullWidth={true}
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid item sm={5} xs={12}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.title.suffix",
                                    label: "Título pestaña"
                                }}
                            />
                        </Grid>
                        <Grid item sm={7} xs={12}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.meta.ogDescription",
                                    label: "Meta tag (og description)"
                                }}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUISelect,
                                    componentProps: {
                                        readOnly: clonPNCL,
                                        disabled: editing
                                    },
                                    name: "configuracion.plataforma.pais",
                                    label: "País",
                                    options: ["chile", "colombia", "mexico", "ecuador", "brasil"]
                                }}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUICheckbox,
                                    componentProps: {
                                        disabled: editing
                                    },
                                    name: "clonPNCL",
                                    label: "Clon de puntaje",
                                    tooltip:
                                        "Visualmente se verá como puntaje, con las mismas secciones. No se podrán editar las secciones, ni los colores y solo puede ser de chile. Puede que también existan configuraciones que son ignoradas."
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Typography variant="h4" component="div" mb={2}>
                        Contacto y redes sociales
                    </Typography>
                    <Grid container spacing={2} mb={2}>
                        <Grid item sm={3} xs={12}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.info.companyName",
                                    label: "Nombre empresa",
                                    tooltip: "Nombre de la compañía que se muestra en los términos y condiciones."
                                }}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.info.companySite",
                                    label: "URL sitio principal",
                                    tooltip: "Nombre del sitio que se muestra en los términos y condiciones."
                                }}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.sesionAlias",
                                    label: "Sesión alias",
                                    tooltip:
                                        "Cómo se denominan las sesiones en la plataforma. Ej: Sesión, Semana, Clase, etc."
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={2}>
                        <Grid item sm={3} xs={6}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.info.contacto.telefono",
                                    label: "Teléfono contacto",
                                    tooltip:
                                        "Teléfono de contacto que se muestra en el footer de la plataforma (Crece Chile). En desuso para el resto de plataformas en favor del Teléfono de soporte."
                                }}
                            />
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.info.contacto.mail",
                                    label: "Email contacto",
                                    tooltip:
                                        "Email de contacto que se muestra en las calugas para realizar pruebas en caso que el alumno no tenga cursos activos. También se muestra en secciones deslogueadas (como el Registro) de PreuUss, en el footer del landing de los clones, y en el footer de UDLA."
                                }}
                            />
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.info.soporte.telefono",
                                    label: "Teléfono soporte",
                                    tooltip: "Teléfono de soporte que se muestra en el footer de la plataforma."
                                }}
                            />
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.info.soporte.mail",
                                    label: "Email soporte",
                                    tooltip:
                                        "Email que aparece en el contacto del landing en: Crece Chile (profesores), Puntaje Chile (profesores), UAH (alumnos y profesores) y UTEM (profesores)."
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item sm={3} xs={6}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.info.social.facebook",
                                    label: "Link a Facebook",
                                    tooltip:
                                        "Link a la página de Facebook de la plataforma. También causa que se muestre el un link a esta página con el ícono de Facebook en el footer de la plataforma."
                                }}
                            />
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.info.social.twitter",
                                    label: "Link a Twitter",
                                    tooltip:
                                        "Link a la página de Twitter de la plataforma. También causa que se muestre el un link a esta página con el ícono de Twitter en el footer de la plataforma."
                                }}
                            />
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.info.social.youtube",
                                    label: "Link a Youtube",
                                    tooltip:
                                        "Link a la página de Youtube de la plataforma. También causa que se muestre el un link a esta página con el ícono de Youtube en el footer de la plataforma."
                                }}
                            />
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <CUIFormInput
                                inputBuilder={{
                                    component: CUIText,
                                    componentProps: {
                                        fullWidth: true
                                    },
                                    name: "configuracion.plataforma.info.social.instagram",
                                    label: "Link a Instagram",
                                    tooltip:
                                        "Link a la página de Instagram de la plataforma. También causa que se muestre el un link a esta página con el ícono de Instagram en el footer de la plataforma."
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
