import DialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

export interface CUIDialogTitleProps extends DialogTitleProps {
    /**
     * Para cerrar el Dialog desde un botón de cerrar dentro del título
     */
    onClose?: () => void
}

export function CUIDialogTitle({ onClose, children, ...props }: CUIDialogTitleProps) {
    return (
        <DialogTitle {...props}>
            {children}
            {onClose && (
                <IconButton
                    aria-label="cerrar"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
        </DialogTitle>
    )
}
