import Autocomplete, { AutocompleteProps, AutocompleteRenderInputParams } from "@mui/material/Autocomplete"
import { forwardRef } from "react"
import { CUIFilledTextProps, CUIOutlinedTextProps, CUIStandardTextProps, CUIText } from "./CUIText"

export interface CUIAutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, "renderInput"> {
    cuiTextProps?: CUIStandardTextProps | CUIOutlinedTextProps | CUIFilledTextProps
    cuiTextPropsFunc?: (
        props: AutocompleteRenderInputParams
    ) => CUIStandardTextProps | CUIOutlinedTextProps | CUIFilledTextProps
}

export const CUIAutocomplete = forwardRef(
    <
        T,
        Multiple extends boolean | undefined,
        DisableClearable extends boolean | undefined,
        FreeSolo extends boolean | undefined
    >(
        {
            cuiTextProps = { label: "" },
            cuiTextPropsFunc,
            ...props
        }: CUIAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
        ref
    ): JSX.Element => {
        return (
            <Autocomplete
                ref={ref}
                {...props}
                disablePortal
                sx={props.fullWidth ? { ...props.sx } : { width: 300, ...props.sx }}
                renderInput={params => <CUIText {...cuiTextProps} {...params} {...cuiTextPropsFunc?.(params)} />}
            />
        )
    }
)

export default CUIAutocomplete
