import { Alert, Paper } from "@mui/material"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { CUIFormInput } from "@puntaje/react/shared/cui"
import { CUICheckbox } from "@puntaje/react/shared/cui"
import { useFieldArray } from "react-hook-form"
import { SeccionesHome } from "./SeccionesHome/SeccionesHome"

interface SeccionesProps {
    /**
     * Valor que permite distinguir al componente si se está creando o editando un clon.
     * editing es true cuando estoy editando el clon, y false cuando estoy creando un clon nuevo
     */
    editing: boolean
}

export default function Secciones({ editing }) {
    const { fields: navigationsAlumnos } = useFieldArray({
        name: "configuracion.app.navigationByApp.alumnos"
    })
    const { fields: navigationsProfesores } = useFieldArray({
        name: "configuracion.app.navigationByApp.profesores"
    })

    return (
        <>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Alert severity="info" sx={{ mb: 2, mt: 2 }}>
                    Secciones mostradas en el menú para distintos tipos de usuarios.
                    {editing && (
                        <>
                            <br />
                            Las secciones pueden ser <b>Base</b> o <b>Personalizado</b> y significan lo siguiente:
                            <ul>
                                <li>
                                    Base: Está dentro de las opciones predefinidas que se pueden activar y desactivar en
                                    el clonador.
                                </li>
                                <li>
                                    Personalizado: Son opciones de secciones que, o se agregaron mediante desarrollo, o
                                    son levemente distintas en comparación con las predefinidas, como su nombre a
                                    mostrar, icono o dirección del link. Si se desactiva una de estas, no saldrá la
                                    siguiente vez que se edite el clon.
                                </li>
                            </ul>
                        </>
                    )}
                </Alert>
                <Grid container spacing={2} mb={2}>
                    <Grid item sm={6} xs={12} title="alumnos">
                        <Typography variant="h6" mb={1}>
                            Alumnos
                        </Typography>
                        {navigationsAlumnos.map((a, index) => {
                            const element = a as any
                            return (
                                <div key={a.id}>
                                    <CUIFormInput
                                        inputBuilder={{
                                            component: CUICheckbox,
                                            componentProps: {},
                                            name: `configuracion.app.navigationByApp.alumnos.${index}.selected`,
                                            label: element.clonadorName
                                        }}
                                    />
                                    <br />
                                </div>
                            )
                        })}
                    </Grid>
                    <Grid item sm={6} xs={12} title="profesores">
                        <Typography variant="h6" mb={1}>
                            Profesores
                        </Typography>
                        {navigationsProfesores.map((p, index) => {
                            const element = p as any
                            return (
                                <div key={p.id}>
                                    <CUIFormInput
                                        inputBuilder={{
                                            component: CUICheckbox,
                                            componentProps: {},
                                            name: `configuracion.app.navigationByApp.profesores.${index}.selected`,
                                            label: element.clonadorName
                                        }}
                                    />
                                    <br />
                                </div>
                            )
                        })}
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ p: 2, mb: 3 }}>
                <SeccionesHome />
            </Paper>
            <Paper sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={2} mb={2}>
                    <Grid item md={12}>
                        <Typography variant="h6" mb={2}>
                            Secciones adicionales
                        </Typography>
                    </Grid>
                    <Grid item md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUICheckbox,
                                componentProps: {},
                                name: "configuracion.plataforma.showAyuda",
                                label: "Navbar - Ayuda"
                            }}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUICheckbox,
                                componentProps: {},
                                name: "configuracion.plataforma.enableAchievements",
                                label: "Navbar - Logros"
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <br />
        </>
    )
}
