import { Logged } from "../layouts/Logged"
import { DetalleClon } from "../detalleclon/DetalleClon"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { CUILoader } from "@puntaje/react/shared/cui"
import { useClonadorApi } from "@puntaje/clonador/api-services"

export function ConfiguracionNew() {
    const { configuracionesService } = useClonadorApi()
    const navigate = useNavigate()
    const [isSaving, setIsSaving] = useState(false)

    /**
     * Guarda los cambios del formulario y crea un nuevo clon
     * @param data Los datos del formulario del clon a crear
     * @param e
     */
    const onSubmit = (data, e) => {
        setIsSaving(true)
        configuracionesService.save({ configuracion: data }, { notConcat: true }).then(result => {
            navigate("/configuraciones")
        })
    }

    return (
        <Logged title="Crear plataforma">
            <CUILoader
                loading={isSaving}
                render={() => {
                    return <DetalleClon editing={false} onSubmit={onSubmit} />
                }}
            />
        </Logged>
    )
}
