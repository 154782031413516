interface HtmlProps<T> extends React.HTMLAttributes<T> {
    content: string
    htmlRef?: React.RefObject<T>
    component?: React.ElementType
}

export function Html<T>({ content, component = "div", htmlRef, ...elementProps }: HtmlProps<T>) {
    const Component = component

    return <Component dangerouslySetInnerHTML={{ __html: content }} {...elementProps} ref={htmlRef} />
}
