import "./theme-wrap.module.scss"
import styled from "@emotion/styled"

const StyleWrap = styled.div`
    * {
        /* Titulo base */
        --base-titlebar_color: #333333;
        --base-titlebar_size: 26px;
        --base-titlebar_subtitle_color: #4f585d;
        --base-titlebar_subtitle_size: 18px;
        --base-subtitle_color: #333333;
        --base-subtitle_underline_color: #cccccc;

        /* Botones base */
        --base-primary_button_background_color: #353535;
        --base-primary_button_background_color_hover: #1c1c1c;
        --base-primary_button_font_color: white;
        --base-primary_button_font_color_hover: white;

        --base-cancel_button_background_color: #353535;
        --base-cancel_button_background_color_hover: #1c1c1c;
        --base-cancel_button_font_color: white;
        --base-cancel_button_font_color_hover: white;

        --base-secondary_button_background_color: #353535;
        --base-secondary_button_background_color_hover: #1c1c1c;
        --base-secondary_button_font_color: white;
        --base-secondary_button_font_color_hover: white;

        /* Menu item base (calugas) */
        --base-menu_item_background_left_color: #353535;
        --base-menu_item_background_left_font_color: white;
        --base-menu_item_title_color: #353535;
        --base-menu_item_title_underline_color: #cccccc;

        /* Showcasebox base */
        --base-showcasebox_icon_color: #353535;
        --base-showcasebox_title_color: #353535;
        --base-showcasebox_title_background_color: white;

        /* Navbar base */
        --base-navbar_height: 50px;
        --base-navbar_avatar_size: 40px;
        --base-navbar_logo_height: 30px;
        --base-navbar_background_color: white;
        --base-navbar_background_color_hover: #e6e6e6;
        --base-navbar_font_color: #333333;
        --base-navbar_font_color_hover: #333333;
        --base-navbar_menu_btn_background_color: transparent;
        --base-navbar_menu_btn_background_color_hover: transparent;
        --base-navbar_font_color_menu_icon: #333333;
        --base-navbar_font_color_menu_icon_hover: #333333;
        --base-navbar_avatar_background_color: #a5adb3;

        /* Topmenu base */
        --base-topmenu_background_color: white;
        --base-topmenu_background_color_hover: #e6e6e6;
        --base-topmenu_font_color: #333333;
        --base-topmenu_font_color_hover: #333333;

        /* menu horizontal base */
        --base-menu_horizontal_background_color: white;
        --base-menu_horizontal_background_color_hover: #333333;
        --base-menu_horizontal_font_color: #333333;
        --base-menu_horizontal_font_color_hover: white;
        --base-menu_horizontal_border_marker: false;
        --base-menu_horizontal_with_bottom_border: false;
        --base-menu_horizontal_no_icon: false;

        /* menu showcasebox base */
        --base-menu_showcasebox_background_color: white;
        --base-menu_showcasebox_background_color_hover: #e6e6e6;
        --base-menu_showcasebox_font_color: #333333;
        --base-menu_showcasebox_font_color_hover: #333333;
        --base-menu_showcasebox_icon_font_color: white;
        --base-menu_showcasebox_icon_background_color: #333333;
        --base-menu_showcasebox_with_arrow: true;
        --base-menu_showcasebox_rounded: false;

        --base-menu_showcasebox_radius: "3px";

        /* Footer base */
        --base-footer_background_color: #353535;
        --base-footer_font_color: white;
        --base-footer_font_color_hover: white;
        --base-footer_social_media_background_color: transparent;
        --base-footer_social_media_background_color_hover: #1c1c1c;

        /* Landing base */
        --base-landing_subtitle_font_color: white;
        --base-landing_footer_background_color: #353535;
        --base-landing_logo_max_width: 400px;
        --base-landing_logo_footer_height: 40px;
        --base-landing_login_align: center;
    }
`

/* eslint-disable-next-line */
export interface ThemeWrapProps {
    children?: any
}

export function ThemeWrap(props: ThemeWrapProps) {
    return <StyleWrap>{props.children}</StyleWrap>
}

export default ThemeWrap
