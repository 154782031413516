import { createContext, ReactNode, useContext } from "react"

export interface BaseEnvironment {
    production?: boolean
}

export const EnvironmentContext = createContext<{}>(null)

export function useEnvironment<T>() {
    return useContext(EnvironmentContext) as T
}

export function EnvironmentProvider<T>({ children, environment }: { children: ReactNode; environment: T }) {
    return <EnvironmentContext.Provider value={environment}>{children}</EnvironmentContext.Provider>
}
