import { ClickAwayListener, Link, Paper, Popover, Popper, Typography } from "@mui/material"
import { CUILoader } from "@puntaje/react/shared/cui"
import axios from "axios"
import { useCallback, useEffect, useId, useState } from "react"
import { Control, useFormContext, useWatch } from "react-hook-form"

interface ImgPreviewProps {
    /**
     * Nombre del campo en el objeto formulario
     */
    field: string

    /**
     * Control del formulario de react-hook-form para manejar el estado del formulario.
     * @see https://react-hook-form.com/api/useform/control
     *
     * Si no viene se usa el del contexto.
     */
    control?: Control
}

export function ImgPreview({ control, field }: ImgPreviewProps) {
    const methods = useFormContext()
    control = control ?? methods.control

    const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null)
    const [size, setSize] = useState({ width: 0, height: 0 })
    const [loadedImage, setLoadedImage] = useState(false)
    const [base64Img, setBase64Img] = useState("")

    const open = Boolean(anchorEl)
    const generatedId = useId()
    const id = open ? generatedId : undefined

    const img = useWatch({
        control,
        name: field
    })

    useEffect(() => {
        if (img) {
            setBase64Img("")
            axios.get(img, { responseType: "blob" }).then(async imgResponse => {
                const blob = imgResponse.data as Blob
                const reader = new FileReader()
                reader.readAsDataURL(blob)
                reader.onloadend = () => {
                    setBase64Img(reader.result as string)
                }
            })
        }
    }, [img])

    const imgRef = useCallback(
        (node: HTMLImageElement) => {
            if (node !== null) {
                setSize({ width: node.naturalWidth, height: node.naturalHeight })
            }
        },
        [loadedImage]
    )

    return img ? (
        <>
            &nbsp;
            <Link
                href="#"
                onClick={e => {
                    e.preventDefault()

                    setAnchorEl(anchorEl ? null : e.currentTarget)
                }}
                sx={{ fontSize: "0.6em" }}
            >
                Preview
            </Link>
            <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" sx={{ zIndex: 1 }}>
                <ClickAwayListener
                    onClickAway={() => {
                        setAnchorEl(null)
                        setLoadedImage(false)
                    }}
                >
                    <Paper sx={{ p: 2 }}>
                        <Typography>Ancho: {size.width}</Typography>
                        <Typography>Alto: {size.height}</Typography>
                        <CUILoader
                            loading={!base64Img}
                            render={() => (
                                <img
                                    style={{ maxHeight: 900 }}
                                    src={base64Img}
                                    alt="Preview"
                                    ref={imgRef}
                                    onLoad={() => setLoadedImage(true)}
                                />
                            )}
                        />
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    ) : null
}
