export function clearLatex(toClear: string) {
    if (!toClear) {
        return ""
    }

    let listStrings = [
        "\\\\f\\]",
        "\\\\f\\[",
        "\\\\'a",
        "\\\\'e",
        "\\\\'i",
        "\\\\'o",
        "\\\\'u",
        "\\\\'A",
        "\\\\'E",
        "\\\\'I",
        "\\\\'O",
        "\\\\'U",
        "\\?`",
        "\\\\~n",
        "&nbsp;",
        "&lt;",
        "&gt;",
        "\\\\\\[",
        "\\\\\\]",
        "\\\\\\(",
        "\\\\\\)"
    ]

    let mapObj = {
        "\\f]": "",
        "\\f[": "",
        "\\'a": "á",
        "\\'e": "é",
        "\\'i": "í",
        "\\'o": "ó",
        "\\'u": "ú",
        "\\'A": "Á",
        "\\'E": "É",
        "\\'I": "Í",
        "\\'O": "Ó",
        "\\'U": "Ú",
        "?`": "¿",
        "\\~n": "ñ",
        "&nbsp;": " ",
        "&lt;": "<",
        "&gt;": ">",
        "\\[": "$$",
        "\\]": "$$",
        "\\(": "$",
        "\\)": "$"
    }
    let re = new RegExp(listStrings.join("|"), "g")

    return toClear
        .replace(re, function (matched) {
            return mapObj[matched]
        })
        .replace(/\\mbox\{([^\}]*)\}/g, "\\text{$1}");
}
