import { TimePicker, TimePickerProps } from "@mui/x-date-pickers/TimePicker"
import { forwardRef } from "react"
import { CUIText } from "./CUIText"
import { CUILocalizationProvider } from "../Utils/CUILocalizationProvider"

export interface CUITimePickerProps<TInputDate, TDate> extends TimePickerProps<TInputDate, TDate> {}

export const CUITimePicker = forwardRef(<TInputDate, TDate>(props: CUITimePickerProps<TInputDate, TDate>, ref: any) => {
    return (
        <CUILocalizationProvider>
            <TimePicker renderInput={params => <CUIText {...params} />} {...props} ref={ref} />
        </CUILocalizationProvider>
    )
})
