import List, { ListProps } from "@mui/material/List"
import { forwardRef } from "react"

export interface CUIListProps extends ListProps {
    component?: React.ElementType
}

export const CUIList = forwardRef((props: CUIListProps, ref: any) => {
    return (
        <List {...props} ref={ref}>
            {props.children}
        </List>
    )
})

export default CUIList
