import { Global } from "@emotion/react"
import { StrictMode } from "react"
import * as ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

import App from "./app/app"

const globalStyles = {
    body: {
        margin: 0
    }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <StrictMode>
        <Global styles={globalStyles} />
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </StrictMode>
)
