import { forwardRef, useCallback, useEffect, useState } from "react"
import { CUIFile, CUIFileProps } from "./CUIFile"

export interface CUIFileUploadProps
    extends Omit<CUIFileProps<File>, "onChange" | "disableSetValueOnChange" | "transformFile"> {
    /**
     * Callback que se ejecuta cuando `onFileChange` se ejecuta correctamente, con la URL del archivo subido.
     *
     * @param {string} url URL del archivo subido.
     */
    onChange?: (url: string) => void

    /**
     * Callback que se ejecuta cuando se selecciona un archivo, el cual se suele usar para subir el archivo seleccionado a un servidor.
     *
     * @param {File} file - Archivo seleccionado.
     *
     * @returns {Promise<string>} - URL del archivo subido.
     */
    onFileChange: (file: File) => Promise<string>
    onUploadStart?: () => void
}

/**
 * Componente que permite hacer operaciones asíncronas con un archivo seleccionado, como por ejemplo subirlo a un servidor.
 *
 * @param {CUIFileUploadProps} props - Propiedades del componente.
 *
 * @returns {JSX.Element} - Componente.
 */
export const CUIFileUpload = forwardRef(
    (
        { onChange, onFileChange, onUploadStart, filePath, ...rest }: CUIFileUploadProps,
        ref: React.Ref<HTMLInputElement>
    ) => {
        const [internalFilePath, setInternalFilePath] = useState(filePath ?? "")

        const onCUIFileChange = useCallback(
            (value: File) => {
                onUploadStart?.()
                onFileChange(value)
                    .then(url => {
                        setInternalFilePath(url)
                        onChange?.(url)
                    })
                    .catch(error => {
                        // TODO(efrias): manejar error
                    })
            },
            [onChange, onFileChange]
        )

        useEffect(() => {
            if (filePath != internalFilePath) {
                setInternalFilePath(filePath ?? "")
            }
        }, [filePath])

        return (
            <CUIFile
                {...rest}
                filePath={internalFilePath}
                onChange={onCUIFileChange as (value: unknown) => void}
                disableSetValueOnChange
                ref={ref}
            />
        )
    }
)
