import { Api, ApiOptions } from "@puntaje/react/shared/core"
import { Material } from "./Material"

export interface ISentiment {
    id: number
    material_id: number
    plataforma_id: number
    likes: number
    dislikes: number
}

export interface SentimentParams {
    status: string
    status_was: string
    plataforma: string
}

//TODO: Arreglar este error de ortografía, se necesita hacer cambios en puntaje-api, nebulosa-api en los endpoints y además en la bbdd
export type SentimentType = "LIKE" | "DISLIKE" | "INDIFERENT"

export class Materiales extends Api<Material> {
    constructor(baseUrl: string, token: string, options: ApiOptions = {}) {
        super(baseUrl, token, "materiales", "material", options)
    }

    getSentimentPromise(materialId: number, platform: string) {
        return this.one<ISentiment>(materialId).collection("sentiment").get({ plataforma: platform })
    }

    getLikeOrDislikePromise(materialId: number, type: SentimentType, params: { sentiment: SentimentParams }) {
        const collectionName = type.toLowerCase()
        return this.one<ISentiment>(materialId).collection(collectionName).post(params, { notConcat: true })
    }
}
