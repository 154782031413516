import { Api, ApiOptions } from "@puntaje/react/shared/core"
import { Alternativas } from "./Alternativas"
import { ISentiment, SentimentParams, SentimentType } from "./Materiales"
import { Pregunta } from "./Pregunta"

type RespuestaLike = {
    pregunta_id: number
    contestable_id: number
    alternativa_id: number
}

export class Preguntas extends Api<Pregunta> {
    constructor(baseUrl: string, token: string, options: ApiOptions = {}) {
        super(baseUrl, token, "preguntas", "pregunta", options)
    }

    getSentimentPromise(preguntaId: number, platform: string) {
        return this.one<ISentiment>(preguntaId).collection("sentiment").get({ plataforma: platform })
    }

    getLikeOrDislikePromise(preguntaId: number, type: SentimentType, params: { sentiment: SentimentParams }) {
        const collectionName = type.toLowerCase()
        return this.one<ISentiment>(preguntaId).collection(collectionName).post(params, { notConcat: true })
    }

    /**
     * Función que además de ir a buscar las preguntas, va a buscar las alternativas de las preguntas que son libres asociadas a las respuestas
     * y que no se traen por defecto (porque todas son demasiadas)
     *
     * @param params Parámetros de búsqueda de las preguntas
     * @param respuestas Arreglo de objetos tipo Respuestas
     *
     * @returns Promise con el arreglo de preguntas y con sus alternativas ya seteadas
     */
    public async whereWithAlternativasLibres(
        params: any = null,
        respuestas: RespuestaLike[] = null,
        alternativasService: Alternativas
    ) {
        const preguntas = await this.whereWithoutCount(params)

        const preguntasById = preguntas.reduce((acc, pregunta) => {
            acc[pregunta.id] = pregunta

            return acc
        }, {})

        const respuestasAlternativasLibres = respuestas.filter(respuesta => {
            if (!respuesta.alternativa_id) {
                return false
            }

            const pregunta = preguntasById[respuesta.pregunta_id]

            if (!pregunta) {
                return false
            }

            return pregunta.contestables.find(
                c =>
                    c.id == respuesta.contestable_id &&
                    c.contestable_tipo &&
                    !["Pregunta de alternativas", "Lista Desplegable"].includes(c.contestable_tipo.contestable_tipo)
            )
        })

        const alternativaIds = respuestasAlternativasLibres.map(respuesta => respuesta.alternativa_id)

        const alternativasParams = {
            alternativa: {
                id: alternativaIds
            }
        }

        const alternativas = await alternativasService.whereWithoutCount(alternativasParams)
        const respuestasAlternativasLibresByAlternativaId = respuestasAlternativasLibres.reduce((acc, respuesta) => {
            acc[respuesta.alternativa_id] = respuesta

            return acc
        }, {})

        alternativas.forEach(alternativa => {
            const respuestaAlternativa = respuestasAlternativasLibresByAlternativaId[alternativa.id]
            const pregunta = preguntasById[respuestaAlternativa.pregunta_id]

            const contestable = pregunta.contestables.find(c => c.id == respuestaAlternativa.contestable_id)
            contestable.alternativas = contestable.alternativas || []
            contestable.alternativas.push(alternativa)
        })

        return preguntas
    }

    /**
     * Calcula las estadisticas acumuladas de todas las plataformas para el arreglo de preguntas
     *
     * @param params Objeto con el arreglo de ids de las preguntas { pregunta_ids: [ids]}
     * @returns Estadisticas acumuladas de nebu para la preguntas
     */
    public estadisticasAccum(params: { pregunta_ids: number[] }) {
        return this.all<{ [preguntaId: number]: { correctas: number; incorrectas: number; omitidas: number } }>()
            .collection("get_estadisticas_accum")
            .post(params, { singularTableWrap: false })
    }
}
