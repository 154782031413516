import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel"
import Radio, { RadioProps } from "@mui/material/Radio"
import { forwardRef } from "react"

export interface CUIRadioProps extends RadioProps {
    controlLabelProps?: Partial<FormControlLabelProps>
}

export const CUIRadio = forwardRef((props: CUIRadioProps, ref: any): JSX.Element => {
    return props.controlLabelProps ? (
        <FormControlLabel
            control={<Radio ref={ref} />}
            label={props.controlLabelProps.label}
            {...props.controlLabelProps}
        />
    ) : (
        <Radio {...props} />
    )
})

export default CUIRadio
