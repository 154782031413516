import { Box, Tab, Tabs } from "@mui/material"
import { useNebulosaApi } from "@puntaje/react/nebulosa/api-services"
import { CUIContentBox, CUILoader } from "@puntaje/react/shared/cui"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import ConfigTipoEvaluacion from "./ConfigTipoEvaluacion"
import { EvaluacionTipoMap } from "../../../types/configuration.types"

interface ConfigTipoEvaluacionesProps {
    /**
     * Valor que permite distinguir al componente si se está creando o editando un clon.
     * editing es true cuando estoy editando el clon, y false cuando estoy creando un clon nuevo
     */
    editing: boolean

    /**
     * Arreglo que contiene las configuraciones de los tipos de evaluación seleccionados.
     * Por cada elemento del arreglo se mostrará una pestaña
     */
    evaluacionTipoConfigs: EvaluacionTipoMap[]

    /**
     * Función que permite cambiar el contenido de un elemento dentro del arreglo de evaluacionTipoConfigs.
     * Se utiliza para la función de copiar configuración de un tipo de evaluación a otro.
     */
    updateEvaluacionTipoConfig: Function
}

interface TabsEvaluacionTiposProps {
    /**
     * Valor numérico que indica el número de la pestaña que está activa
     */
    tabValue: number

    /**
     * Función que maneja el cambio de una pestaña activa a otra. Se llama al clickear otra pestaña.
     */
    setTabValue: Function

    /**
     * Arreglo que contiene las configuraciones de los tipos de evaluación seleccionados.
     * Por cada elemento del arreglo se mostrará una pestaña
     */
    evaluacionTipoConfigs: EvaluacionTipoMap[]
}

function TabsEvaluacionTipos({ tabValue, setTabValue, evaluacionTipoConfigs }: TabsEvaluacionTiposProps) {
    return (
        <Box
            sx={{
                borderBottom: 1,
                borderColor: "divider",
                position: "sticky",
                zIndex: 2,
                top: 48,
                backgroundColor: "white"
            }}
        >
            <Tabs
                value={tabValue}
                onChange={(e, newValue) => setTabValue(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="tabs evaluacion tipos"
            >
                {evaluacionTipoConfigs.map((et, index) => {
                    return <Tab label={et.evaluacionTipo as string} key={index} />
                })}
            </Tabs>
        </Box>
    )
}

export default function ConfigTipoEvaluaciones({
    editing,
    evaluacionTipoConfigs,
    updateEvaluacionTipoConfig
}: ConfigTipoEvaluacionesProps) {
    const { clasificacionTiposService } = useNebulosaApi()
    const { data, isLoading } = useQuery(["clasificacionTipos"], () =>
        clasificacionTiposService.whereWithoutCount({ sort_by: "clasificacion_tipo" })
    )
    const [tabValue, setTabValue] = useState(0)

    return (
        <CUILoader
            LoaderComponentProps={{ sx: { mt: 2 } }}
            loading={isLoading}
            render={() => (
                <CUIContentBox mt={2}>
                    <TabsEvaluacionTipos
                        tabValue={tabValue}
                        setTabValue={setTabValue}
                        evaluacionTipoConfigs={evaluacionTipoConfigs}
                    />

                    {evaluacionTipoConfigs.map((te, index) => {
                        return index === tabValue ? (
                            <ConfigTipoEvaluacion
                                key={te.id}
                                editing={editing}
                                index={index}
                                tipoEvaluacion={te}
                                clasificacionTipos={isLoading ? [] : data}
                                evaluacionTipoConfigs={evaluacionTipoConfigs}
                                updateEvaluacionTipoConfig={updateEvaluacionTipoConfig}
                            />
                        ) : null
                    })}
                </CUIContentBox>
            )}
        />
    )
}
