import { isElement, isForwardRef } from "react-is"
import CUIAutocomplete from "./CUIAutocomplete"
import CUICheckbox from "./CUICheckbox"
import { CUICheckboxes } from "./CUICheckboxes"
import { CUIDatePicker } from "./CUIDatePicker"
import { CUIDateTimePicker } from "./CUIDateTimePicker"
import { CUIFormInputParams } from "./CUIFormInputParams"
import { CUIRadios } from "./CUIRadios"
import { CUISelect } from "./CUISelect"
import { CUISwitch } from "./CUISwitch"
import { CUIFile } from "./CUIFile"
import { useCUIAutocompleteFormProps } from "./useCUIAutocompleteFormProps"
import { useCUICheckboxesFormProps } from "./useCUICheckboxesFormProps"
import { useCUICheckboxFormProps } from "./useCUICheckboxFormProps"
import { useCUIDatePickerFormProps } from "./useCUIDatePickerFormProps"
import { useCUIFileFormProps } from "./useCUIFileFormProps"
import { useCUIRadiosFormProps } from "./useCUIRadiosFormProps"
import { useCUISelectFormProps } from "./useCUISelectFormProps"
import { useCUIEditorFormProps } from "./useCUIEditorFormProps"
import { useCUISwitchFormProps } from "./useCUISwitchFormProps"
import { CUIGroupedCheckboxes } from "./CUIGroupedCheckboxes"
import { ControllerFieldState, ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form"
import { CUIEditor } from "./CUIEditor"
import { CUITimePicker } from "./CUITimePicker"

type PropsGetter = (
    {
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
    }: {
        field: ControllerRenderProps<FieldValues, string>
        fieldState: ControllerFieldState
    },
    inputBuilder: CUIFormInputParams<React.ElementType, any>
) => React.ComponentPropsWithRef<React.ElementType>

const usePropsMap = new Map<React.ElementType<any> | string, PropsGetter>()
usePropsMap.set(CUIAutocomplete, useCUIAutocompleteFormProps)
usePropsMap.set(CUISelect, useCUISelectFormProps)
usePropsMap.set(CUIDatePicker, useCUIDatePickerFormProps)
usePropsMap.set(CUIDateTimePicker, useCUIDatePickerFormProps)
usePropsMap.set(CUITimePicker, useCUIDatePickerFormProps)
usePropsMap.set(CUICheckbox, useCUICheckboxFormProps)
usePropsMap.set(CUISwitch, useCUISwitchFormProps)
usePropsMap.set(CUICheckboxes, useCUICheckboxesFormProps)
usePropsMap.set(CUIGroupedCheckboxes, useCUICheckboxesFormProps)
usePropsMap.set(CUIRadios, useCUIRadiosFormProps)
usePropsMap.set(CUIFile, useCUIFileFormProps)
usePropsMap.set("CUIFileUpload", useCUIFileFormProps)
usePropsMap.set("CUIEditor", useCUIEditorFormProps)

/**
 * Función que devuelve las props que se le pasan al componente de formulario, en base a los props de react-hook-form.
 * Usa el map `usePropsMap` para obtener la función que se llama por componente, o usa una por defecto si no está en el map.
 *
 * @param {React.ElementType & { cuiComponentName?: string }} Component - Componente al que obtenerle los props.
 * @param {{field: ControllerRenderProps<FieldValues, string>, fieldState: ControllerFieldState}} props - Render props de react-hook-form.
 * @param {CUIFormInputParams<T, V>} inputBuilder - Builder de props del formulario.
 */
export function useComponentProps<
    T extends React.ElementType & { cuiComponentName?: string },
    V extends React.ComponentPropsWithRef<T>,
    W extends FieldValues = FieldValues
>(
    Component: T,
    {
        field: { onChange, onBlur, value, name, ref },
        fieldState
    }: {
        field: ControllerRenderProps<W, FieldPath<W>>
        fieldState: ControllerFieldState
    },
    inputBuilder: CUIFormInputParams<T, V>
): React.ComponentPropsWithRef<T> {
    const propsMap = usePropsMap.get(Component)
    if (propsMap) {
        return propsMap(
            { field: { onChange, onBlur, value, name, ref }, fieldState },
            inputBuilder as unknown as CUIFormInputParams<React.ElementType, any>
        )
    }

    if (Component.cuiComponentName) {
        const propsMap = usePropsMap.get(Component.cuiComponentName)
        if (propsMap) {
            return propsMap(
                { field: { onChange, onBlur, value, name, ref }, fieldState },
                inputBuilder as unknown as CUIFormInputParams<React.ElementType, any>
            )
        }
    }

    const { error } = fieldState
    const { label, placeholder } = inputBuilder

    const props = {
        ...inputBuilder.componentProps,
        label,
        onChange,
        onBlur,
        value: value ?? "",
        placeholder,
        id: name,
        error:
            inputBuilder.componentProps?.error != null && inputBuilder.componentProps?.error != undefined
                ? inputBuilder.componentProps?.error
                : error != undefined || undefined,
        helperText: inputBuilder.componentProps?.helperText
            ? inputBuilder.componentProps?.helperText
            : error != undefined
            ? error.message
            : undefined,
        required: inputBuilder.rules?.required != null && inputBuilder.rules?.required != undefined
    } as unknown as React.ComponentPropsWithRef<T>

    if (isForwardRef(Component) || isElement(Component)) {
        props.ref = ref
    }

    return props
}
