import { AppBar, Avatar, Box, Container, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material"
import { CUIButton, CUIDefaultCL, CUIIconButton, CUILoggedLayout } from "@puntaje/react/shared/cui"
import CycloneIcon from "@mui/icons-material/Cyclone"
import { Link, useNavigate } from "react-router-dom"
import { ClonadorSession } from "@puntaje/clonador/core"
import { useSession } from "@puntaje/react/shared/core"
import { useState } from "react"

export interface LoggedProps {
    children: React.ReactNode
    title?: React.ReactNode
}

export function Logged({ children, title }: LoggedProps) {
    const session = useSession<ClonadorSession>()
    const navigate = useNavigate()

    const [anchorElUser, setAnchorElUser] = useState(null)

    const handleOpenUserMenu = event => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    function logout() {
        session.set(null)
        navigate("/")
    }

    const iniciales =
        session?.usuario?.nombre
            ?.split(" ")
            ?.map(nombre => nombre[0])
            ?.join("")
            .toUpperCase() ?? ""

    return (
        <CUILoggedLayout
            header={
                <AppBar position="sticky">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <CycloneIcon sx={{ display: "flex", mr: 1 }} />
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{
                                    mr: 4,
                                    display: "flex",
                                    fontFamily: "monospace",
                                    fontWeight: 700,
                                    letterSacing: ".3rem",
                                    textDecoration: "none"
                                }}
                            >
                                Clonador
                            </Typography>

                            <Box sx={{ flexGrow: 1, display: "flex" }}>
                                <CUIButton
                                    component={Link}
                                    to="/configuraciones"
                                    variant="text"
                                    sx={{ my: 2, display: "block", color: "white" }}
                                >
                                    Configuraciones
                                </CUIButton>
                            </Box>

                            <Box sx={{ flexGrow: 0 }}>
                                <CUIIconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar>{iniciales}</Avatar>
                                </CUIIconButton>
                                <Menu
                                    sx={{ mt: "45px" }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <MenuItem onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center" onClick={logout}>
                                            Cerrar Sesión
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            }
            contentLayout={CUIDefaultCL}
            contentLayoutProps={{
                headline:
                    // <Typography variant="h4" sx={{ mb: 2, mt: 3 }}>
                    title
                    // </Typography>
            }}
        >
            {children}
        </CUILoggedLayout>
    )
}
