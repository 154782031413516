import { useMemo } from "react"
import { useEnvironment } from "../config/EnvironmentContext"
import { Session } from "../session/Session"
import { useSession } from "../session/SessionContext"
import { ApiOptions } from "./ApiInterfaces"
import { BasicApi } from "./BasicApi"

type Resources = {
    /**
     * Representa una clase que extiende de Api. La llave suele ser el mismo nombre de la clase.
     *
     * @example
     * Ej.
     *  {
     *      Usuarios: Usuarios,
     *      Preguntas: Preguntas
     *  }
     */
    [key: string]: new (baseUrl: string, token: string, options?: ApiOptions) => BasicApi<any>
}

type UnwrapTypeof<T> = T extends new (...args: any[]) => infer R ? R : T

/**
 * Crea una instancia de Api para cada clase que se le pase como argumento.
 * El nombre de cada propiedad es el mismo nombre de la clase, con la primera minuscula, y concatenado con el sufijo "Service"
 *
 * @example
 *
 * Transforma, en terminos de tipo:
 *
 * {
 *    Usuarios: Usuarios,
 *    Preguntas: Preguntas
 * }
 *
 * en:
 *
 * {
 *   usuariosService: new Usuarios(...),
 *   preguntasService: new Preguntas(...)
 * }
 */
type ApiServices<T> = {
    [Property in keyof T as `${Uncapitalize<string & Property>}Service`]: UnwrapTypeof<T[Property]>
}

/**
 * Hook que dada un objeto con constructores de servicios de API,
 * devuelve un objeto con las instancias de los servicios de la API.
 *
 * @param {T extends Resources} resources Objeto con constructores de servicios de API,
 *                                        donde cada uno recibe la url base de la API y el token de autenticación.
 * @param {string} keyUrl Llave del ambiente que tiene la url base de la API.
 * @param {string} keyToken Llave de los tokens que posee la sesión que guarda el token de autenticación.
 * @returns {ApiServices<T>} Objeto con las instancias de los servicios de la API.
 */
export function useApplicationApi<
    TKeyUrl extends string,
    TKeyToken extends string,
    TEnv extends { [P in TKeyUrl]: string },
    TToken extends { [P in TKeyToken]: string },
    TResources extends Resources
>(resources: TResources, keyUrl: TKeyUrl, keyToken: TKeyToken) {
    const session = useSession<Session<any, TToken>>()
    const environment = useEnvironment<TEnv>()

    const services = useMemo(() => {
        return Object.keys(resources).reduce((acc, key) => {
            const resourceClass = resources[key]
            const resource = new resourceClass(environment[keyUrl], session?.tokens?.[keyToken])
            acc[key.charAt(0).toLowerCase() + key.slice(1) + "Service"] = resource

            return acc
        }, {}) as ApiServices<TResources>
    }, [session?.tokens?.[keyToken]])

    return services
}
