import { FormHelperText } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import RadioGroup from "@mui/material/RadioGroup"
import { forwardRef, useCallback, useId } from "react"
import { FieldError } from "react-hook-form"
import { CUIRadioProps, CUIRadio } from "./CUIRadio"

export interface CUIRadiosProps<T> {
    value: T
    options: T[]
    radioProps?: CUIRadioProps
    label?: string
    id?: string
    direction?: "horizontal" | "vertical"
    idExtractor?: keyof T | ((item: T) => number | string)
    labelExtractor?: keyof T | ((item: T) => string | React.ReactNode)
    onChange?: (value: T) => void
    error?: FieldError
}

export const CUIRadios = forwardRef(
    <T,>(
        {
            radioProps,
            label,
            id,
            options,
            onChange,
            idExtractor,
            labelExtractor,
            value,
            direction = "vertical",
            error
        }: CUIRadiosProps<T>,
        ref: any
    ): JSX.Element => {
        const extractId = useCallback(
            (item: T) => {
                return idExtractor
                    ? typeof idExtractor === "function"
                        ? idExtractor(item)
                        : item[idExtractor]
                    : item.toString()
            },
            [idExtractor]
        )

        const extractLabel = useCallback(
            (item: T) => {
                return labelExtractor
                    ? typeof labelExtractor === "function"
                        ? labelExtractor(item)
                        : item[labelExtractor]
                    : item.toString()
            },
            [labelExtractor]
        )

        const handleChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                const option = options.find(option => extractId(option) + "" == event.target.value)

                onChange(option)
            },
            [options, extractId]
        )

        const labelId = useId()

        return (
            <FormControl id={id} error={error != undefined || undefined}>
                <FormLabel id={labelId}>{label}</FormLabel>
                <RadioGroup
                    row={direction == "horizontal"}
                    aria-labelledby={labelId}
                    value={value != null || value != undefined ? extractId(value) : ""}
                    onChange={handleChange}
                    ref={ref}
                >
                    {options?.map(option => {
                        const optionId = extractId(option) + ""
                        const optionLabel = extractLabel(option) as React.ReactNode

                        const cuiRadioProps = {
                            ...radioProps,
                            controlLabelProps: {
                                ...radioProps?.controlLabelProps,
                                value: optionId,
                                label: optionLabel
                            }
                        }

                        return <CUIRadio key={optionId} {...cuiRadioProps} />
                    })}
                </RadioGroup>
                {error != undefined && <FormHelperText> {error.message} </FormHelperText>}
            </FormControl>
        )
    }
)

export default CUIRadios
