import { useTranslateLib } from "../i18n/useTranslateLib"

/**
 * Hook que retorna una función que retorna un mensaje de error si el valor es menor al mínimo de caracteres
 *
 * @returns Objeto con la función `minLengthError` que retorna un mensaje de error si el valor es menor al mínimo de caracteres
 */
export function useMinLengthError() {
    const { t } = useTranslateLib("use_min_length_error")

    return {
        minLengthError: (label: string, min: number | string) => t("min_length_error", { label, min })
    }
}
