import { Navigations } from "./Navigations"
import { ClonConf } from "../types/configuration.types"

export class NuevoClonDefaultConfig {
    static defaultConf: ClonConf = {
        plataforma: "",
        abreviatura_plataforma: "",
        clonPNCL: false,
        configuracion: {
            app: {
                assets: {
                    logoLarge: "",
                    logoMedium: "",
                    logoSmall: "",
                    logoLargeLight: "",
                    logoMediumLight: "",
                    logoSmallLight: "",
                    logoSquare: "",
                    logoSquareLight: "",
                    streamingOffline: ""
                },
                name: undefined,
                paths: {
                    pendingInfo: "/pending_info",
                    landing: "/landing"
                },
                routeUser: {},
                routeUserInfo: {},
                baseStyle: {
                    navbar: {
                        logo: "",
                        logoMobile: ""
                    },
                    footer: {
                        logo: "",
                        logosExtras: [],
                        enableLogo: false
                    },
                    landing: {
                        backgroundImage: "",
                        logo: "",
                        subtitle: "",
                        subtitleHasShadow: false,
                        logoFooter: ""
                    },
                    sharedLayout: {
                        backgroundImage: "",
                        logo: ""
                    },
                    ayuda: {
                        showUploadDate: false,
                        showYoutubeButton: false
                    }
                },
                navigationByApp: {
                    alumnos: Navigations.alumnos,
                    profesores: Navigations.profesores
                },
                showcasesByApp: {
                    alumnos: [],
                    profesores: []
                },
                menuConfig: {
                    tipo: "horizontal",
                    enableShowcaseMenuHomeDocentes: false,
                    enableShowcaseMenuHomeAlumnos: false
                },
                informacionContacto: [],
                googleAnalyticsPropertyId: {
                    alumnos: "",
                    profesores: "",
                    admin: ""
                },
                facebookAnalyticsPropertyId: {
                    alumnos: "",
                    profesores: "",
                    admin: ""
                },
                googleTagManagerPropertyId: {
                    alumnos: "",
                    profesores: "",
                    admin: ""
                },
                globalSiteTag: {
                    alumnos: {
                        propertyId: "",
                        sendTo: ""
                    },
                    profesores: {
                        propertyId: "",
                        sendTo: ""
                    },
                    admin: {
                        propertyId: "",
                        sendTo: ""
                    }
                },
                domain: {
                    alumnos: "",
                    profesores: "",
                    admin: ""
                },
                facebook: {
                    appId: "",
                    apiVersion: ""
                },
                recaptcha: {
                    siteKey: ""
                }
            },
            amazon: {
                s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
                s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
            },
            api: {
                checkTokenPath: "/check_token",
                authPath: "/authenticate"
            },
            asignaturas: [],
            banners: {},
            evaluaciones: {},
            hojaRespuesta: {
                sectores: [
                    [1, 2],
                    [2, 3],
                    [3, 4],
                    [5, 2],
                    [2, 7],
                    [7, 4],
                    [5, 6],
                    [6, 7],
                    [7, 8]
                ],
                alternativas: 5
            },
            instrumentosPredefinidos: { zonas: [] },
            mensajesLogin: {
                idPlaceholder: "Email o RUT (sin puntos, con digito verificador)",
                invalidLoginId: "Debes incluir un correo electrónico o RUT válido.",
                invalidLoginMessage1:
                    "<strong>La combinación de correo electrónico/contraseña o RUT/contraseña es incorrecta.</strong> Las contraseñas de ",
                invalidLoginMessage2:
                    ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.',
                invalidLoginInactiveEstablishments:
                    "<p>Su cuenta no se encuentra asociada a ningún establecimiento activo.</p><p>Para activar su cuenta le rogamos comunicarse con el administrador de su establecimiento o con su monitor de PuntajeNacional.cl al correo <b>contacto@puntajenacional.cl</b> o al teléfono <b>(+56) 9 3578 3784</b>, <b>(+56) 9 3586 7757</b>, o al <b>(+56) 9 3664 1076</b>.</p><p>Le dejamos abierta la invitación a acceder a la versión estudiante de la plataforma.</p>",
                invalidLoginInactiveEstablishmentsRedirectText: "Ir a la versión estudiante"
            },
            plataforma: {
                enableCURPestudiantes: false,
                hideReforzamientos: false,
                hideBarraAvanceEstimado: false,
                showMisCarreras: false,
                disableNivelCalugas: false,
                disableTodosLosEstablecimientos: false,
                hideReplicarPlan: false,
                showTextListado: false,
                showLinkAlumnoAlCompartir: false,
                disableButtonDuda: false,
                usuarioPaa: false,
                añoInicial: new Date().getFullYear(),
                evaluacionDefault: "",
                evaluacionResponder: "secuencial",
                evaluacionInstanciasRoute: "",
                evaluacionTiposMenu: [],
                evaluacionTipoAdaptiveDefault: "",
                evaluacionTiposMultiples: [],
                evaluacionesMultiplesRoute: "",
                grupoUsuarioAlias: "curso",
                clasificacionTipoNivel: "curso",
                numSesionesPlan: 3,
                iconSet: {
                    noticiasShowcase: "megafono-o",
                    establecimientosShowcase: "establecimiento-o",
                    cursosShowcase: "comunidad-o"
                },
                cursosDefault: [],
                id: undefined,
                identificadorEstablecimiento: ["rbd"],
                identificadorUsuario: ["RUT"],
                identificadorUsuarioAlias: {},
                aliasIdentificadorHojaRespuesta: "",
                styleTheme: "",
                disableContactInfo: false,
                hojaRespuestaEjemploLink: "",
                info: {
                    social: {
                        facebook: "",
                        twitter: "",
                        youtube: ""
                    },
                    soporte: {
                        mail: ""
                    },
                    contacto: {
                        telefono: "",
                        mail: ""
                    },
                    companyName: "",
                    companySite: ""
                },
                instrumentoMensualTipo: "curricular",
                lugarLabel: "Comuna",
                lugarTipoColegio: "Comuna",
                name: "",
                pais: "chile",
                periodos: [
                    {
                        label: "",
                        fechaInicial: [0, 1],
                        fechaFinal: [11, 31, 23, 59, 59]
                    }
                ],
                resumenGeneral: {
                    curriculum: "curricular",
                    generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
                },
                streamingDefault: "",
                streamingDisableEnVivo: [],
                tiposInstrumentoInformeUso: [],
                tiposNotificacion: [],
                canTextHighlight: true,
                disableWarningOmitidas: false,
                nombreColumnaResultados: null,
                menuSesiones: false,
                canRecoverPasswordBySMS: false,
                afterUploadResultGoToViewPath: "",
                disableCompartirPresencial: false,
                disableZopim: false,
                sesionAlias: "",
                nombreSesion: "Sesión",
                nombreSesiones: "Sesiones",
                planClasesCountInWeeks: false,
                planClasesRoute: "/plan_clases",
                planClasesUseNiveles: false,
                planClasesRouteDocentes: "/plan_clases",
                orderFiltroClasificaciones: false,
                enableCodigosLibros: false,
                disableSentimentMaterial: false,
                disableCalificacionPlanPersonalSession: false,
                disableTipoEvaluacionCompartir: false,
                showUserId: false,
                hideVolverARealizar: false,
                hideImprimirEvaluacion: false,
                showAyuda: false,
                hideCompartirImprimirEvaluacionMulti: false,
                hideOpcionesImpresionEvaluacionMulti: false,
                hideMessageSugerencia: false,
                pauta_imagen: "",
                textConPrueba: {
                    generarInstrumento: "",
                    pruebasPropias: "",
                    subirPruebasPropias: "",
                    utilizarRecomendado: ""
                },
                customErrorMessage: "",
                sortClasificacionesFiltro: false,
                hidePersonalizarByAsignatura: [],
                urlHojaRespuesta: "",
                loginGoogle: false,
                userIdTablaGrupoUsuarios: false,
                cursosStreaming: [],
                ordenAlternativasAleatorias: false,
                enableAchievements: false,
                evaluacionSecuencial: {
                    autoDudaNoContestada: false,
                    restrictedNavigation: false
                },
                smowl: {
                    cameraEndpoint: "",
                    registrationEndpoint: "",
                    registrationPath: ""
                },
                enableTipoEscala: false,
                enableNotaAprobacionEscala: false,
                locale: "es",
                hideGuardarSinConfigurar: false,
                showFilterCET: false,
                planPersonalesDisableNivel: false,
                realizarButton: "",
                descargarButton: "",
                customGenerateTitle: "",
                title: {
                    prefix: "",
                    suffix: ""
                },
                meta: {
                    ogDescription: ""
                }
            },
            carreras: {
                evaluacionTipo: [""],
                ponderables: [
                    {
                        orden: 1,
                        ponderable: "Matemática",
                        asignaturaAsociada: "Matemática",
                        tipo: "calificable"
                    },
                    {
                        orden: 2,
                        ponderable: "Lenguaje y Comunicación",
                        asignaturaAsociada: "Lenguaje",
                        tipo: "calificable"
                    },
                    {
                        orden: 3,
                        ponderable: "Historia y Ciencias Sociales",
                        asignaturaAsociada: "Historia y Ciencias Sociales",
                        tipo: "calificable"
                    },
                    {
                        orden: 4,
                        ponderable: "Ciencias",
                        asignaturaAsociada: "Ciencias PSU",
                        tipo: "calificable"
                    },
                    {
                        orden: 5,
                        ponderable: "NEM",
                        clase: "nem",
                        tipo: "ingresable",
                        field: "nem",
                        minValue: 150,
                        maxValue: 850
                    },
                    {
                        orden: 6,
                        ponderable: "Ranking",
                        clase: "ranking",
                        tipo: "ingresable",
                        field: "ranking",
                        minValue: 150,
                        maxValue: 850
                    }
                ]
            },
            ensayoNacional: null,
            mensajesInformativos: null,
            aditionalClass: false,
            componentsInSubmodule: null,
            registro: {
                idObligatorio: true,
                emailObligatorio: true,
                contrasenaObligatorio: true,
                confirmacionContrasenaObligatorio: true,
                enableCarreras: false,
                carrerasObligatorio: false,
                universidades: [],
                amount: 3,
                enableNivel: false,
                nivelObligatorio: false,
                enableColegio: false,
                colegioObligatorio: false,
                enableTelefono: false,
                telefonoObligatorio: false,
                nombresObligatorio: false,
                apellidoPaternoObligatorio: false,
                apellidoMaternoObligatorio: false
            },
            generar: {
                generar: true,
                intrumentosRecomendados: true,
                pruebasPropias: true,
                buscador: true
            },
            planificaciones: {
                hidePlanesAdaptive: false,
                hidePlanificacionFromProfesorInAdmin: false
            },
            cargaNomina: {
                ruta: {
                    alumnos: "",
                    profesores: ""
                }
            },
            planesEstudio: {
                hidePlanesAdaptive: false
            },
            noticias: {
                hideInputLugar: false
            },
            cssVariables: {
                clasesConfig: [],
                navbarColorFondo: "",
                navbarColorFuenteHover: "",
                navbarColorIconoMenu: "",
                navbarColorFondoHover: "",
                navbarColorFondoMenu: "",
                navbarColorIconoMenuHover: "",
                navbarColorFuente: "",
                navbarColorFondoMenuHover: "",
                navbarColorFondoAvatar: "",
                navbarAlto: "",
                navbarAltoAvatar: "",
                navbarAltoLogo: "",
                menuColorFondo: "",
                menuColorFondoHover: "",
                menuColorFuente: "",
                menuColorFuenteHover: "",
                menuHorizontalColorFondo: "",
                menuHorizontalColorFondoHover: "",
                menuHorizontalColorFuente: "",
                menuHorizontalColorFuenteHover: "",
                menuHorizontalMarcadorLinea: "",
                menuHorizontalLineaAbajo: "",
                menuHorizontalSinIcono: "",
                menuShowcaseboxColorFondo: "",
                menuShowcaseboxColorIcono: "",
                menuShowcaseboxColorFondoHover: "",
                menuShowcaseboxColorIconoHover: "",
                menuShowcaseboxColorFuente: "",
                menuShowcaseboxConFlecha: "",
                menuShowcaseboxColorFuenteHover: "",
                menuShowcaseboxRedondeado: "",
                footerColor: "",
                footerColorSocial: "",
                footerColorFuente: "",
                footerColorSocialHover: "",
                footerColorFuenteHover: "",
                fuente1Nombre: "",
                fuente1Link: "",
                fuente2Nombre: "",
                fuente2Link: "",
                tituloColor: "",
                tituloTamano: "",
                subtituloTituloColor: "",
                subtituloTituloTamano: "",
                subtituloColor: "",
                subtituloTamano: "",
                subtituloColorSubrayado: "",
                contenidoColorFuente: "",
                contenidoBotonColor: "",
                contenidoBotonColorHover: "",
                contenidoBotonColorFuente: "",
                contenidoBotonColorFuenteHover: "",
                contenidoBotonCancelarColor: "",
                contenidoBotonCancelarColorHover: "",
                contenidoBotonCancelarColorFuente: "",
                contenidoBotonCancelarColorFuenteHover: "",
                contenidoBotonSecundarioColor: "",
                contenidoBotonSecundarioColorHover: "",
                contenidoBotonSecundarioColorFuente: "",
                contenidoBotonSecundarioColorFuenteHover: "",
                calugasColorFondoIzq: "",
                calugasColorIconoIzq: "",
                calugasColorTitulo: "",
                calugasColorSubrayado: "",
                showcaseboxColorIcono: "",
                showcaseboxColorTitulo: "",
                showcaseboxColorFondoTitulo: "",
                landingTituloBajadaColor: "",
                landingColorMiniFooter: ""
            }
        },
        attachments: undefined,
        accionesAdicionales: {
            copiarGeneradores: false,
            copiarGeneradoresCurricularColombia: false,
            copiarGeneradoresCurricularMexico: false,
            copiarGeneradoresCurricularSaber11: false,
            copiarGeneradoresNivelacionEstudios: false,
            copiarGeneradoresCurricularEnem: false
        }
    }
}
