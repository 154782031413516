import ListItem, { ListItemProps } from "@mui/material/ListItem"
import { forwardRef } from "react"

export interface CUIListItemProps extends ListItemProps {}

export const CUIListItem = forwardRef((props: CUIListItemProps, ref: any) => {
    return (
        <ListItem {...props} ref={ref}>
            {props.children}
        </ListItem>
    )
})

export default CUIListItem
