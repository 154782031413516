import { useCallback, useEffect, useRef, useState } from "react"
import { BaseStorage } from "../storage/BaseStorage"
import { useStorage } from "../storage/StorageContext"

async function getStorageValue(key, defaultValue, storage: BaseStorage) {
    // getting stored value
    const saved = await storage.get(key)
    const initial = saved ? JSON.parse(saved) : null
    return initial || defaultValue
}

export function useStateWithStorage(key, defaultValue = null, storage: BaseStorage = useStorage()) {
    const [value, setValue] = useState(undefined)
    const retrievedValueFromStorageRef = useRef(false)
    const [hasValueFromStorage, setHasValueFromStorage] = useState(false)

    const queueRef = useRef([])

    const setValueCustom = useCallback(val => {
        if (!retrievedValueFromStorageRef.current) {
            queueRef.current.push(val)
        } else {
            setValue(val)
        }
    }, [])

    const flushQueue = useCallback(() => {
        queueRef.current.forEach(val => {
            setValue(val)
        })

        queueRef.current = []
    }, [])

    useEffect(() => {
        setHasValueFromStorage(false)
        getStorageValue(key, defaultValue, storage).then(v => {
            setValue(v)
            retrievedValueFromStorageRef.current = true
            flushQueue()
            setHasValueFromStorage(true)
        })
    }, [key])

    useEffect(() => {
        if (retrievedValueFromStorageRef.current) {
            if (value != null && value != undefined) {
                storage.set(key, JSON.stringify(value))
            } else {
                storage.remove(key)
            }
        }
    }, [key, value])

    return [value, setValueCustom, hasValueFromStorage]
}
