import { styled } from "@mui/material"
import { ThemeWrap } from "@puntaje/clonador/previewer"
import { useWatch } from "react-hook-form"

const StyleOverwrite = styled("div")<any>`
    * {
        font-family: sans-serif;

        /* Titulo base */
        --base-titlebar_color: ${props => props.tituloColor};
        --base-titlebar_size: ${props => props.tituloTamano};
        --base-titlebar_subtitle_color: ${props => props.subtituloTituloColor};
        --base-titlebar_subtitle_size: ${props => props.subtituloTituloTamano};
        --base-subtitle_color: ${props => props.subtituloColor};
        --base-subtitle_size: ${props => props.subtituloTamano};
        --base-subtitle_underline_color: ${props => props.subtituloColorSubrayado};

        /* Botones base */
        --base-primary_button_background_color: ${props => props.contenidoBotonColor};
        --base-primary_button_background_color_hover: ${props => props.contenidoBotonColorHover};
        --base-primary_button_font_color: ${props => props.contenidoBotonColorFuente};
        --base-primary_button_font_color_hover: ${props => props.contenidoBotonColorFuenteHover};

        --base-cancel_button_background_color: ${props => props.contenidoBotonCancelarColor};
        --base-cancel_button_background_color_hover: ${props => props.contenidoBotonCancelarColorHover};
        --base-cancel_button_font_color: ${props => props.contenidoBotonCancelarColorFuente};
        --base-cancel_button_font_color_hover: ${props => props.contenidoBotonCancelarColorFuenteHover};

        --base-secondary_button_background_color: ${props => props.contenidoBotonSecundarioColor};
        --base-secondary_button_background_color_hover: ${props => props.contenidoBotonSecundarioColorHover};
        --base-secondary_button_font_color: ${props => props.contenidoBotonSecundarioColorFuente};
        --base-secondary_button_font_color_hover: ${props => props.contenidoBotonSecundarioColorFuenteHover};

        /* Menu item base (calugas) */
        --base-menu_item_background_left_color: ${props => props.calugasColorFondoIzq};
        --base-menu_item_background_left_font_color: ${props => props.calugasColorIconoIzq};
        --base-menu_item_title_color: ${props => props.calugasColorTitulo};
        --base-menu_item_title_underline_color: ${props => props.calugasColorSubrayado};

        /* Showcasebox base */
        --base-showcasebox_icon_color: ${props => props.showcaseboxColorIcono};
        --base-showcasebox_title_color: ${props => props.showcaseboxColorTitulo};
        --base-showcasebox_title_background_color: ${props => props.showcaseboxColorFondoTitulo};

        /* Navbar base */
        --base-navbar_height: ${props => props.navbarAlto};
        --base-navbar_avatar_size: ${props => props.navbarAltoAvatar};
        --base-navbar_logo_height: ${props => props.navbarAltoLogo};
        --base-navbar_background_color: ${props => props.navbarColorFondo};
        --base-navbar_background_color_hover: ${props => props.navbarColorFondoHover};
        --base-navbar_font_color: ${props => props.navbarColorFuente};
        --base-navbar_font_color_hover: ${props => props.navbarColorFuenteHover};
        --base-navbar_menu_btn_background_color: ${props => props.navbarColorFondoBotonMenu};
        --base-navbar_menu_btn_background_color_hover: ${props => props.navbarColorFondoBotonMenuHover};
        --base-navbar_font_color_menu_icon: ${props => props.navbarColorIconoBotonMenu};
        --base-navbar_font_color_menu_icon_hover: ${props => props.navbarColorIconoBotonMenuHover};
        --base-navbar_avatar_background_color: ${props => props.navbarColorFondoAvatar};

        /* Topmenu base */
        --base-topmenu_background_color: ${props => props.menuColorFondo};
        --base-topmenu_background_color_hover: ${props => props.menuColorFondoHover};
        --base-topmenu_font_color: ${props => props.menuColorFuente};
        --base-topmenu_font_color_hover: ${props => props.menuColorFuenteHover};

        /* menu horizontal base */
        --base-menu_horizontal_background_color: ${props => props.menuHorizontalColorFondo};
        --base-menu_horizontal_background_color_hover: ${props => props.menuHorizontalColorFondoHover};
        --base-menu_horizontal_font_color: ${props => props.menuHorizontalColorFuente};
        --base-menu_horizontal_font_color_hover: ${props => props.menuHorizontalColorFuenteHover};
        --base-menu_horizontal_border_marker: ${props => props.menuHorizontalMarcadorLinea};
        --base-menu_horizontal_with_bottom_border: ${props => props.menuHorizontalLineaAbajo};
        --base-menu_horizontal_no_icon: ${props => props.menuHorizontalSinIcono};

        /* menu showcasebox base */
        --base-menu_showcasebox_background_color: ${props => props.menuShowcaseboxColorFondo};
        --base-menu_showcasebox_background_color_hover: ${props => props.menuShowcaseboxColorFondoHover};
        --base-menu_showcasebox_font_color: ${props => props.menuShowcaseboxColorFuente};
        --base-menu_showcasebox_font_color_hover: ${props => props.menuShowcaseboxColorFuenteHover};
        --base-menu_showcasebox_icon_font_color: ${props => props.menuShowcaseboxIconoColorFuente};
        --base-menu_showcasebox_icon_background_color: ${props => props.menuShowcaseboxIconoColorFondo};
        --base-menu_showcasebox_with_arrow: ${props => props.menuShowcaseboxConFlecha};
        --base-menu_showcasebox_rounded: ${props => props.menuShowcaseboxRedondeado};
        --base-menu_showcasebox_radius: ${props => (props.menuShowcaseboxRedondeado == "true" ? "3px" : "200px")};

        /* Footer base */
        --base-footer_background_color: ${props => props.footerColor};
        --base-footer_font_color: ${props => props.footerColorFuente};
        --base-footer_font_color_hover: ${props => props.footerColorFuenteHover};
        --base-footer_social_media_background_color: ${props => props.footerColorSocial};
        --base-footer_social_media_background_color_hover: ${props => props.footerColorSocialHover};

        /* Landing base */
        --base-landing_subtitle_font_color: ${props => props.landingTituloBajadaColor};
        --base-landing_footer_background_color: ${props => props.landingColorMiniFooter};
        --base-landing_logo_max_width: 400px;
        --base-landing_logo_footer_height: 40px;
        --base-landing_login_align: center;
    }
`

interface ConfigToThemeProps {
    children: React.ReactNode
}

export function ConfigToTheme({ children }: ConfigToThemeProps) {
    const cssVariables = useWatch({
        name: "configuracion.cssVariables"
    })

    return (
        <ThemeWrap>
            <StyleOverwrite {...cssVariables}>{children}</StyleOverwrite>
        </ThemeWrap>
    )
}
