import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers/DateTimePicker"
import { forwardRef } from "react"
import { CUIText } from "./CUIText"
import { CUIStandardTextProps } from "./CUIText"
import { CUIOutlinedTextProps } from "./CUIText"
import { CUIFilledTextProps } from "./CUIText"
import { CUILocalizationProvider } from "../Utils/CUILocalizationProvider"

export interface CUIDateTimePickerProps<TInputDate, TDate> extends DateTimePickerProps<TInputDate, TDate> {
    cuiTextProps?: CUIStandardTextProps | CUIOutlinedTextProps | CUIFilledTextProps
    fullWidth?: boolean
    size?: "small" | "medium"
}

export const CUIDateTimePicker = forwardRef(
    <TInputDate, TDate>(props: CUIDateTimePickerProps<TInputDate, TDate>, ref: any) => {
        const { cuiTextProps, size, fullWidth, ...restProps } = props

        return (
            <CUILocalizationProvider>
                <DateTimePicker
                    renderInput={params => (
                        <CUIText
                            {...params}
                            {...cuiTextProps}
                            {...(size && { size })}
                            {...(fullWidth && { fullWidth })}
                        />
                    )}
                    {...restProps}
                    ref={ref}
                />
            </CUILocalizationProvider>
        )
    }
)
