import { BaseStorage } from "./BaseStorage"

export class WebLocalStorage extends BaseStorage {
    constructor(public localStorage: Storage) {
        super()
    }

    public get(key: string): any {
        return this.localStorage.getItem(key)
    }

    public set(key: string, value: any): void {
        this.localStorage.setItem(key, value)
    }

    public remove(key: string): void {
        this.localStorage.removeItem(key)
    }

    public clear(): void {
        this.localStorage.clear()
    }
}
