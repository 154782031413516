import { createContext, useContext } from "react"
import { BaseStorage } from "./BaseStorage"
import { WebLocalStorage } from "./WebLocalStorage"

export const StorageContext = createContext<BaseStorage>(null)

export function useStorage() {
    return useContext(StorageContext)
}

export function StorageProvider({
    children,
    storage = new WebLocalStorage(localStorage)
}: {
    children: React.ReactNode
    storage?: BaseStorage
}) {
    return <StorageContext.Provider value={storage}>{children}</StorageContext.Provider>
}
