import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import InformacionBasica from "./Tabs/InformacionBasica"
import TiposEvaluaciones from "./Tabs/TiposEvaluaciones"
import Logos from "./Tabs/Logos"
import Secciones from "./Tabs/Secciones"
import Registro from "./Tabs/Registro"
import ColoresEstilo from "./Tabs/ColoresEstilo"
import MasEstilo from "./Tabs/MasEstilo"
import Analytics from "./Tabs/Analytics"
import { CUIButton, CUILoader } from "@puntaje/react/shared/cui"
import { Box, Stack } from "@mui/material"
import { FormProvider, useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form"
import { AccionesAdicionales } from "./Tabs/AccionesAdicionales"
import { NuevoClonDefaultConfig } from "./NuevoClonDefaultConfig"
import { ClonConf } from "../types/configuration.types"

interface DetalleClonProps {
    /**
     * Valor que permite distinguir al componente si se está creando o editando un clon.
     * editing es true cuando estoy editando el clon, y false cuando estoy creando un clon nuevo
     */
    editing: boolean

    /**
     *  Función que llama el componente al clickear el botón de guardar cambios
     */
    onSubmit: Function

    /**
     * En el caso de estar editando, valor que contiene la configuración del clon al momento de cargar el componente
     */
    loadedConfig?: ClonConf
}

interface TabsConfigProps {
    /**
     * Título de la pestaña que se muestra arriba
     */
    title: string

    /**
     * Condición para que se muestre la pestaña si ésta es verdadera
     */
    condition: boolean

    /**
     * Componente que se muestra al seleccionar la pestaña
     */
    component: React.ReactNode
}

export function DetalleClon({ editing, onSubmit, loadedConfig = undefined }: DetalleClonProps) {
    const form = useForm({
        defaultValues: React.useMemo(() => NuevoClonDefaultConfig.defaultConf, [NuevoClonDefaultConfig.defaultConf])
    } as any)

    const [didLoad, setDidLoad] = React.useState(false)
    const [isSaving, setIsSaving] = React.useState(false)
    const [currentTab, setCurrentTab] = React.useState(0)

    React.useEffect(() => {
        if (!didLoad && loadedConfig) {
            form.reset(loadedConfig)
            setDidLoad(true)
        }
    }, [loadedConfig])

    const watchClonPNCL = useWatch({
        name: "clonPNCL",
        control: form.control
    })

    const TabsConfig: TabsConfigProps[] = [
        {
            title: "Información básica",
            condition: true,
            component: <InformacionBasica editing={editing} clonPNCL={watchClonPNCL} />
        },
        {
            title: "Tipos de Evaluaciones",
            condition: true,
            component: <TiposEvaluaciones editing={editing} clonPNCL={watchClonPNCL} />
        },
        {
            title: "Logos",
            condition: true,
            component: <Logos editing={editing} />
        },
        {
            title: "Secciones",
            condition: !watchClonPNCL,
            component: <Secciones editing={editing} />
        },
        {
            title: "Registro",
            condition: true,
            component: <Registro editing={editing} />
        },
        {
            title: "Colores y estilo",
            condition: !watchClonPNCL,
            component: <ColoresEstilo editing={editing} />
        },
        {
            title: "Más estilo",
            condition: !watchClonPNCL,
            component: <MasEstilo editing={editing} />
        },
        {
            title: "Analytics",
            condition: true,
            component: <Analytics editing={editing} />
        },
        {
            title: "Acciones adicionales",
            condition: true,
            component: <AccionesAdicionales editing={editing} />
        }
    ]

    const TabsShowed = TabsConfig.filter(tab => tab.condition)

    /**
     * Función que se llama al clickear el botón 'anterior'.
     * Valida que todos los campos de la pestaña actual sean válidos y, si es así, cambia la pestaña actual a la pestaña anterior
     */
    const onClickAnterior = () => {
        form.trigger().then(isValidForm => {
            if (isValidForm) {
                window.scrollTo(0, 0)
                setCurrentTab(currentTab - 1)
            }
        })
    }

    /**
     * Función que se llama al clickear el botón 'siguiente'.
     * Valida que todos los campos de la pestaña actual sean válidos y, si es así, cambia la pestaña actual a la pestaña siguiente
     */
    const onClickSiguiente = () => {
        form.trigger().then(isValidForm => {
            if (isValidForm) {
                window.scrollTo(0, 0)
                setCurrentTab(currentTab + 1)
            }
        })
    }

    /**
     * Función que se llama al clickear una pestaña de la lista de pestañas de arriba.
     * Esto sólo se puede hacer cuando se está editando un clon.
     * Valida que todos los campos de la pestaña actual sean válidos y, si es así, cambia la pestaña actual a la pestaña clickeada
     */
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        form.trigger().then(isValidForm => {
            if (isValidForm) {
                setCurrentTab(newValue)
            }
        })
    }

    /**
     * Valida y luego preprocesa los datos del formulario antes de enviarlos.
     *
     * Se modifican en el objeto del form la estructura de los campos que se guardan de manera distinta
     * a como se pueden manejar localmente en el formulario de react-hook-forms.
     * Luego se llama a la función *onSubmit* que se le entregó al componente DetalleClon,
     * que puede ser crear nuevo clon o guardar los cambios al editar un clon.
     *
     * @param data El objeto con los datos del formulario
     * @param e
     */
    const onSubmitDetalleClon = async (data, e) => {
        form.trigger().then(isValidForm => {
            if (isValidForm) {
                setIsSaving(true)

                const dataToSubmit = JSON.parse(JSON.stringify(data))

                // Aquí hago el preprocesamiento de los datos del formulario antes de guardarlo
                dataToSubmit.configuracion.clon = {
                    "puntaje-angular": dataToSubmit.clonPNCL,
                    "base-angular": !dataToSubmit.clonPNCL
                }

                dataToSubmit.configuracion.plataforma.name = dataToSubmit.plataforma
                dataToSubmit.configuracion.plataforma.clasificacionTipoNivel =
                    dataToSubmit.configuracion.evaluaciones.find(e => !!e.cursoTipo)?.cursoTipo ?? "curso"

                let arrEvaluacionTipos = dataToSubmit.configuracion.evaluaciones
                dataToSubmit.configuracion.evaluaciones = {}
                arrEvaluacionTipos.forEach((evaluacionTipo, index) => {
                    // estadisticasEvolutivas se maneja en el formulario como un arreglo,
                    // pero al guardarse debe convertirse en un string
                    // con todos los elementos seleccionados separados por comas
                    // Ej: ["a", "b", "c"] => "a,b,c"
                    evaluacionTipo.clasificaciones.estadisticasEvolutivas =
                        evaluacionTipo.clasificaciones.estadisticasEvolutivas.join(",")

                    // Lo mismo para desempenoEvaluacion
                    // Ej: ["a", "b", "c"] => "a,b,c"
                    evaluacionTipo.clasificaciones.desempenoEvaluacion =
                        evaluacionTipo.clasificaciones.desempenoEvaluacion.join(",")

                    // groupBy se maneja en el formulario como un arreglo bidimensional,
                    // pero debe convertirse en un arreglo de strings donde cada
                    // elemento corresponde a un arreglo convertido en un string
                    // con todos sus elementos separados por comas
                    // Ej: [["a", "b"], ["c", "d"], ["e"]] => ["a,b", "c,d", "e"]
                    evaluacionTipo.clasificaciones.groupBy.forEach((gb, index) => {
                        evaluacionTipo.clasificaciones.groupBy[index] = gb.join(",")
                    })

                    // referencesTo se maneja  en el formulario como un arreglo de objetos
                    // de la forma { key: "a", value: "b"}, pero debe convertirse en un objeto
                    // donde cada una una de sus propiedades sea un elemento key del arreglo y
                    // cuyo valor sea la propiedad value
                    // Ej: [{key: "a", value: "b"}, {key: "c", value: "d"}] => { a: "b", c: "d"}
                    let newReferencesTo = {}
                    evaluacionTipo.clasificaciones.referencesTo.forEach((rt, index) => {
                        newReferencesTo[rt.key] = rt.value
                    })
                    evaluacionTipo.clasificaciones.referencesTo = newReferencesTo

                    // Las evaluaciones (evaluacionTipo) se manejan en el formulario como un arreglo de objetos,
                    // donde cada objeto tiene una propiedad evaluacionTipo que es el nombre del tipo evaluación.
                    // Hay que convertirla en un objeto cuyas propiedades sean los tipos de evaluación correspondientes.
                    // Ej: [{evaluacionTipo: "ensayo", a: "b", ....}, {evaluacionTipo: "paes", c: "d", ....}]
                    // => { "ensayo": { a: "b", ...}, "paes:" { c: "d", ...} }
                    const tipoEvaluacion = evaluacionTipo.evaluacionTipo
                    delete evaluacionTipo.evaluacionTipo
                    dataToSubmit.configuracion.evaluaciones[tipoEvaluacion] = evaluacionTipo
                })

                // Post proceso para buscar solo las navigaciones que están seleccionadas
                dataToSubmit.configuracion.app.navigationByApp.alumnos =
                    dataToSubmit.configuracion.app.navigationByApp.alumnos
                        .filter(n => n.selected)
                        .map(({ selected, clonadorName, ...rest }) => ({
                            ...rest
                        }))
                dataToSubmit.configuracion.app.navigationByApp.profesores =
                    dataToSubmit.configuracion.app.navigationByApp.profesores
                        .filter(n => n.selected)
                        .map(({ selected, clonadorName, ...rest }) => ({
                            ...rest
                        }))

                // Aquí guardo los datos (según sea el submit del crear o editar)
                onSubmit(dataToSubmit)
            }
        })
    }

    const onError = (errors, e) => console.log(errors, e)

    return (
        <>
            <FormProvider {...form}>
                <CUILoader
                    loading={isSaving && false}
                    render={() => {
                        return (
                            <form onSubmit={form.handleSubmit(onSubmitDetalleClon, onError)}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                        position: "sticky",
                                        top: "-1px",
                                        zIndex: 2,
                                        backgroundColor: "white",
                                        mb: 1
                                    }}
                                >
                                    <Tabs
                                        value={currentTab}
                                        onChange={handleTabChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs"
                                    >
                                        {TabsShowed.map((tab, index) => (
                                            <Tab
                                                disabled={!editing && currentTab != index}
                                                key={index}
                                                id={"tab-" + index}
                                                aria-controls={"tab-" + index}
                                                label={tab.title}
                                            />
                                        ))}
                                    </Tabs>
                                </Box>

                                {TabsShowed.map((tab, index) => {
                                    return currentTab == index ? <div key={index}>{tab.component}</div> : null
                                })}

                                <Stack direction="row" mt={4} spacing={4}>
                                    {!editing && currentTab > 0 && (
                                        <CUIButton variant="contained" color="secondary" onClick={onClickAnterior}>
                                            Anterior
                                        </CUIButton>
                                    )}
                                    {!editing && currentTab < TabsShowed.length - 1 && (
                                        <CUIButton variant="contained" color="primary" onClick={onClickSiguiente}>
                                            Siguiente
                                        </CUIButton>
                                    )}
                                    {(editing || currentTab == TabsShowed.length - 1) && (
                                        <CUIButton variant="contained" color="primary" type="submit">
                                            Guardar
                                        </CUIButton>
                                    )}
                                </Stack>
                            </form>
                        )
                    }}
                />
            </FormProvider>
        </>
    )
}
