import { Typography, Link, Box } from "@mui/material"
import {
    CUIButton,
    CUIContentBox,
    CUIDialog,
    CUIDialogActions,
    CUIDialogContent,
    CUIDialogContentText,
    CUIDialogTitle,
    CUILoader
} from "@puntaje/react/shared/cui"
import { useState } from "react"

interface ExampleImgDialogProps {
    title?: string
    img: string
    linkText?: string
    description?: string
    darkMode?: boolean
}

export function ExampleImgDialog({
    title = "Ejemplo",
    img,
    linkText = "Ver un ejemplo",
    description = "",
    darkMode = false
}: ExampleImgDialogProps) {
    const [open, setOpen] = useState(false)
    const handleToggle = () => setOpen(!open)
    const [loadedImage, setLoadedImage] = useState(false)

    return (
        <Box mt={1}>
            <Link
                href="#"
                onClick={e => {
                    e.preventDefault()
                    handleToggle()
                }}
            >
                <Typography sx={{ fontSize: "0.8em", color: darkMode ? "lightblue" : "blue" }}>{linkText}</Typography>
            </Link>
            <CUIDialog open={open} maxWidth="md" sx={{ zIndex: 2000 }}>
                <CUIDialogTitle onClose={handleToggle}>{title}</CUIDialogTitle>
                <CUIDialogContent>
                    <CUIDialogContentText>
                        {description && <CUIContentBox mb={4}>{description}</CUIContentBox>}
                        <img
                            style={{ maxWidth: "100%", maxHeight: "100%", display: loadedImage ? "" : "hidden" }}
                            src={img}
                            alt={title}
                            onLoad={() => setLoadedImage(true)}
                        />
                        {!loadedImage && <CUILoader loading={true} />}
                    </CUIDialogContentText>
                </CUIDialogContent>
                <CUIDialogActions>
                    <CUIButton onClick={handleToggle}>Cerrar</CUIButton>
                </CUIDialogActions>
            </CUIDialog>
        </Box>
    )
}
