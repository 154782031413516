import { Editor, Element as SlateElement, Text } from "slate"
import escapeHtml from "escape-html"
import { jsx } from "slate-hyperscript"

import { CustomEditor } from "../custom-types"

type TElementType = "image" | "equation" | "link" | "table" | "numbered-list" | "bulleted-list"

const ELEMENT_TAGS = {
    A: el => ({ type: "link", url: el.getAttribute("href") }),
    BLOCKQUOTE: () => ({ type: "block-quote" }),
    H1: () => ({ type: "heading-one" }),
    H2: () => ({ type: "heading-two" }),
    H3: () => ({ type: "heading-three" }),
    H4: () => ({ type: "heading-four" }),
    H5: () => ({ type: "heading-five" }),
    H6: () => ({ type: "heading-six" }),
    IMG: el => ({ type: "image", url: el.getAttribute("src") }),
    TABLE: () => ({ type: "table" }),
    THEAD: () => ({ type: "table-head" }),
    TBODY: () => ({ type: "table-body" }),
    CAPTION: () => ({ type: "caption" }),
    TR: () => ({ type: "table-row" }),
    TH: () => ({ type: "table-cell-header" }),
    TD: () => ({ type: "table-cell" }),
    LI: () => ({ type: "list-item" }),
    OL: () => ({ type: "numbered-list" }),
    P: () => ({ type: "paragraph" }),
    SPAN: () => ({ type: "span" }),
    PRE: () => ({ type: "code" }),
    UL: () => ({ type: "bulleted-list" }),
    DIV: () => ({ type: "div" }),
    BODY: () => ({ type: "div" })
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
// Quise agregar el tag <b> igual, porque puede venir de un copy paste de noticias y cosas así
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
    SUP: () => ({ superscript: true }),
    SUB: () => ({ subscript: true }),
    SMALL: () => ({ small: true }),
    B: () => ({ bold: true })
}
// ?????
const ALLOWED_FONT_FAMILY = {
    arial: "Arial, sans-serif",
    "comic sans ms": "Comic Sans MS, Comic Sans, cursive",
    "courier new": "Courier New, monospace",
    georgia: "Georgia, serif",
    "lucida sans unicode": "Lucida Sans Unicode, sans-serif",
    tahoma: "Tahoma, sans-serif",
    "times new roman": "Times New Roman, serif",
    "trebuchet ms": "Trebuchet MS, sans-serif",
    verdana: "Verdana, sans-serif",
    lato: "Lato, Roboto, Helvetica Neue, Arial, sans-serif"
}

export const getElements = (editor: CustomEditor, type: TElementType) => {
    return Editor.nodes(editor, {
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === type
    })
}

//Transforma el html a string
export const serialize = (node, htmlMode?) => {
    if (htmlMode) {
        return node.children[0].children[0].text
    }

    if (Text.isText(node)) {
        let string = escapeHtml(node.text)
        if (node["bold"]) {
            string = `<strong>${string}</strong>`
        }
        if (node["code"]) {
            string = `<code>${string}</code>`
        }
        if (node["underline"]) {
            string = `<u>${string}</u>`
        }
        if (node["strikethrough"]) {
            string = `<s>${string}</s>`
        }
        if (node["italic"]) {
            string = `<em>${string}</em>`
        }
        if (node["superscript"]) {
            string = `<sup>${string}</sup>`
        }
        if (node["subscript"]) {
            string = `<sub>${string}</sub>`
        }
        if (node["marker"]) {
            string = `<span class="marker">${string}</span>`
        }
        if (node["fontFamily"]) {
            const style = `font-family: ${node["fontFamily"]}`
            string = `<span style="${style}">${string}</span>`
        }
        if (node["fontSize"]) {
            const style = `font-size: ${node["fontSize"]}`
            string = `<span style="${style}">${string}</span>`
        }
        return string
    }

    const children = node.children.map(n => serialize(n)).join("")

    // aquí hay que ir a buscar estilo, atributos, clases, etc.
    let clases = node.class || ""
    if (node.type === "equation") clases += " inline-math-katex"

    const styleString = (node.style && ` style=\"${node.style}\"`) || ""
    const clasesString = (clases && ` class=\"${clases}\"`) || ""
    const idString = (node.id && ` id=\"${node.id}\"`) || ""

    let attrsString = `${styleString}${clasesString}${idString}`

    /* mucho BS
    const borderStyle = `border: ${node.border}px solid black; `
    const textStyle = `${node.textAlign ? "text-align: " + node.textAlign + "; " : ""}${
        node.marginLeft ? "margin-left: " + node.marginLeft + "px; " : ""
    }`
    const imageStyle = `margin: ${node.marginVertical} ${node.marginHorizontal}px; float: ${node.float}; ${borderStyle}`
    const tableStyle = `width: ${node.width}px; margin: ${node.margin}; float: ${node.float}; height: ${node.height}; ${borderStyle}`
    const cellStyle = `padding: ${node.padding}px; font-weight: ${node.isHeader ? "bolder" : "normal"}; ${borderStyle}`
    */

    switch (node.type) {
        case "equation":
            return `<span${attrsString}>${node.math}</span>`
        case "block-quote":
            return `<blockquote${attrsString}><p>${children}</p></blockquote>`
        case "bulleted-list":
            return `<ul${attrsString}>${children}</ul>`
        case "paragraph":
            return `<p${attrsString}>${children}</p>`
        case "span":
            return `<span${attrsString}>${children}</span>`
        case "numbered-list":
            return `<ol${attrsString}>${children}</ol>`
        case "heading-one":
            return `<h1${attrsString}>${children}</h1>`
        case "heading-two":
            return `<h2${attrsString}>${children}</h2>`
        case "heading-three":
            return `<h3${attrsString}>${children}</h3>`
        case "heading-four":
            return `<h4${attrsString}>${children}</h4>`
        case "heading-five":
            return `<h5${attrsString}>${children}</h5>`
        case "heading-six":
            return `<h6${attrsString}>${children}</h6>`
        case "list-item":
            return `<li${attrsString}>${children}</li>`
        case "link":
            return `<a${attrsString} href="${escapeHtml(node.url)}">${children}</a>`
        case "image":
            return `<img${attrsString} alt="${node.alt}" src="${escapeHtml(node.url)}" height="${node.height}" width="${
                node.width
            }"/>`
        case "table":
            return `<table${attrsString}>${children}</table>`
        case "caption":
            return `<caption${attrsString}>${children}</caption>`
        case "table-body":
            return `<tbody${attrsString}>${children}</tbody>`
        case "table-head":
            return `<thead${attrsString}>${children}</thead>`
        case "table-row":
            return `<tr${attrsString}>${children}</tr>`
        case "table-cell":
            return `<td${attrsString}>${children}</td>`
        case "table-cell-header":
            return `<th${attrsString}>${children}</th>`
        case "iframe":
            return `<iframe${attrsString}>${children}</iframe>`
        case "style":
            return `<style>${children}</style>`
        case "div":
            return `<div${attrsString}>${children}</div>`
        default:
            return children
    }
}

//Se ejecuta al pegar ya sea por comando y por botón.
/** @TODO (Ana) Esta función explota al pegar un texto demasiado grande dentro del editor
 * (Sandra) Explota un poco menos ahora, y recomiendo pegar con "Paste and match style"
 */
export const deserialize = (el, styleWrap?) => {
    if (el.nodeType === 3) {
        return el.textContent
    } else if (el.nodeType !== 1) {
        return null
    } else if (el.nodeName === "BR") {
        return "\n"
    }

    const { nodeName } = el
    let parent = el

    if (nodeName === "PRE" && el.childNodes[0] && el.childNodes[0].nodeName === "CODE") {
        parent = el.childNodes[0]
    }
    let children = Array.from(parent.childNodes)
        .map(node => deserialize(node, styleWrap))
        .flat()

    if (children.length === 0) {
        children = [{ text: "" }]
    }

    if (el.nodeName === "HTML") {
        const attrs = { type: "div", id: styleWrap || "styleWrap" }
        return jsx("element", attrs, children)
    }

    // Esto es para que no coloque 2 divs de styleWrap
    if (el.nodeName === "DIV" && el.id && el.id.includes("styleWrap")) {
        return jsx("fragment", {}, children)
    }

    if (el.nodeName === "HEAD") {
        if (children.length == 1 && children[0].text === "") {
            return null
        }
        return jsx("fragment", {}, children)
    }

    if (el.nodeName === "TITLE" || el.nodeName === "META" || el.nodeName === "LINK" || el.nodeName === "SCRIPT") {
        return null
    }
    if (el.nodeName === "STYLE") {
        const attrs = { type: "style" }

        if (children && children[0] && typeof children[0] === "string") {
            //tengo que ver si ya tiene el styleWrap para reemplazarlo
            if (children[0].includes("#styleWrap")) {
                // si está, reemplazarlo
                children[0] = children[0].replace(/^#styleWrap[a-zA-Z0-9]+/, `#${styleWrap}`)
            } else {
                // si no está, agregarlo
                children[0] = `#${styleWrap} { ${children[0]} }`
            }
        }
        return jsx("element", attrs, children)
    }

    if (ELEMENT_TAGS[nodeName]) {
        const attrs = ELEMENT_TAGS[nodeName](el)

        const elementAttrs = [
            "title",
            "width",
            "height",
            "alt",
            "id",
            "role",
            "border",
            "cellpadding",
            "cellspacing",
            "align"
        ]

        elementAttrs.forEach(attr => {
            el[attr] && (attrs[attr] = el[attr])
        })

        // clases
        if (el.classList) {
            attrs.class = el.classList.value
        }

        // estilos
        if (el.style) {
            attrs.style = el.style.cssText
        }

        // Solo se está agregando el body si tiene estilo, pero quizás a futuro se quiera que se
        // muestre si tiene algún otro atributo con valor
        if (el.nodeName === "BODY" && attrs.style === "") {
            return jsx("fragment", {}, children)
        }

        return jsx("element", attrs, children)
    }

    if (el.className === "inline-math-katex") {
        const attrs = { type: "equation", math: children[0] }

        return jsx("element", attrs, children)
    }

    if (el.className === "marker") {
        return children.map(child => jsx("text", { marker: true }, child))
    }

    if (TEXT_TAGS[nodeName]) {
        const attrs = TEXT_TAGS[nodeName](el)
        return children.map(child => jsx("text", attrs, child))
    }

    if (children.length > 1) {
        children = children.filter(item => typeof item === "object")
    }

    return children
}

export const copyRichText = (editor: CustomEditor) => {
    const content = serialize({ children: editor.getFragment() })
    const blob = new Blob([content], { type: "text/html" })
    const richText = new ClipboardItem({ "text/html": blob })
    navigator.clipboard.write([richText])
}
