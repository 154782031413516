import {
    Alert,
    Box,
    Divider,
    Grid,
    MenuItem,
    Paper,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material"
import {
    CUIButton,
    CUICheckbox,
    CUIFormInput,
    CUISelect,
    CUIText,
    CUITable,
    CUIAutocomplete
} from "@puntaje/react/shared/cui"
import StarHalfIcon from "@mui/icons-material/StarHalf"
import StarIcon from "@mui/icons-material/Star"
import StarOutlineIcon from "@mui/icons-material/StarOutline"
import { useFieldArray, useWatch } from "react-hook-form"
import React, { useState } from "react"
import { useNebulosaApi } from "@puntaje/react/nebulosa/api-services"
import { ExampleImgDialog } from "../../../util/components/ExampleImgDialog"
import { EvaluacionTipoMap } from "../../../types/configuration.types"
import { ClasificacionTipo } from "@puntaje/react/nebulosa/api-services"

interface ConfigTipoEvaluacionProps {
    /**
     * Valor que permite distinguir al componente si se está creando o editando un clon.
     * editing es true cuando estoy editando el clon, y false cuando estoy creando un clon nuevo
     */
    editing: boolean

    /**
     * Valor numérico que representa el índice del tipo de evaluación dentro del arreglo de configuraciones de tipo evaluación
     */
    index: number

    /**
     * Objeto con la configuración del tipo de evaluación
     */
    tipoEvaluacion: EvaluacionTipoMap

    /**
     * Arreglo con los clasificacion tipo
     */
    clasificacionTipos: ClasificacionTipo[]

    /**
     * Arreglo que contiene las configuraciones de los tipos de evaluación seleccionados.
     */
    evaluacionTipoConfigs: EvaluacionTipoMap[]

    /**
     * Función que permite cambiar el contenido de un elemento dentro del arreglo de evaluacionTipoConfigs.
     * Se utiliza para la función de copiar configuración de un tipo de evaluación a otro.
     */
    updateEvaluacionTipoConfig: Function
}

export default React.memo<any>(function ConfigTipoEvaluacion({
    editing,
    index,
    tipoEvaluacion,
    clasificacionTipos,
    evaluacionTipoConfigs,
    updateEvaluacionTipoConfig
}: ConfigTipoEvaluacionProps) {
    const { clasificacionesService } = useNebulosaApi()

    // REFERENCES TO
    const {
        fields: referencesTo,
        append: addReferencesTo,
        remove: removeReferencesTo
    } = useFieldArray({
        name: `configuracion.evaluaciones.${index}.clasificaciones.referencesTo`
    })
    const onClickAddReferencesTo = () => {
        addReferencesTo({
            key: "",
            value: ""
        })
    }
    const onClickRemoveReferencesTo = indexRemove => {
        removeReferencesTo(indexRemove)
    }

    // PLAN PERSONAL CLASIFICACION MATERIALES
    const {
        fields: planPersonalClasificacionMateriales,
        append: addPlanPersonalClasificacionMateriales,
        remove: removePlanPersonalClasificacionMateriales
    } = useFieldArray({
        name: `configuracion.evaluaciones.${index}.planPersonalClasificacionMateriales`
    })
    const onClickAddPlanPersonalClasificacionMateriales = () => {
        addPlanPersonalClasificacionMateriales({
            label: "",
            clasificacion: ""
        })
    }
    const onClickRemovePlanPersonalClasificacionMateriales = indexRemove => {
        removePlanPersonalClasificacionMateriales(indexRemove)
    }

    // CLASIFICACION TIPO ESTADISTICAS
    const {
        fields: clasificacionTiposEstadisticas,
        append: addClasificacionTiposEstadisticas,
        remove: removeClasificacionTiposEstadisticas
    } = useFieldArray({
        name: `configuracion.evaluaciones.${index}.clasificacionTiposEstadisticas`
    })
    const onClickAddClasificacionTipoEstadisticas = () => {
        addClasificacionTiposEstadisticas({
            nombre: "",
            compuesto: false,
            alumnos: false,
            profesores: false,
            informeAnual: false
        })
    }
    const onClickRemoveClasificacionTipoEstadisticas = indexRemove => {
        removeClasificacionTiposEstadisticas(indexRemove)
    }

    // CURSOS
    const {
        fields: cursos,
        append: addCurso,
        remove: removeCurso
    } = useFieldArray({
        name: `configuracion.evaluaciones.${index}.cursos`
    })

    const onClickAddCurso = () => {
        addCurso({
            clasificacion: "",
            label: ""
        })
    }
    const onClickRemoveCurso = indexRemove => {
        removeCurso(indexRemove)
    }

    // CURSOS INFORME FINAL
    const {
        fields: nivelTipoInstrumentoInformeFinal,
        append: addNivelTipoInstrumentoInformeFinal,
        remove: removeNivelTipoInstrumentoInformeFinal
    } = useFieldArray({
        name: `configuracion.evaluaciones.${index}.nivelTipoInstrumentoInformeFinal`
    })
    const onClickAddCursoInformeFinal = () => {
        addNivelTipoInstrumentoInformeFinal("")
    }
    const onClickRemoveCursoInformeFinal = indexRemove => {
        removeNivelTipoInstrumentoInformeFinal(indexRemove)
    }

    // ESTRELLAS
    const { fields: estrellas } = useFieldArray({
        name: `configuracion.evaluaciones.${index}.estrellas`
    })

    const [tipoEvaluacionCopiar, setTipoEvaluacionCopiar] = useState("")
    const onClickCopiarConfig = indexReemplazar => {
        const configAntigua = evaluacionTipoConfigs[indexReemplazar]
        const configNueva = {
            ...evaluacionTipoConfigs.find(et => et.evaluacionTipo == tipoEvaluacionCopiar),
            evaluacionTipo: configAntigua.evaluacionTipo,
            alias: configAntigua.alias
        }
        updateEvaluacionTipoConfig(indexReemplazar, configNueva)
        setTipoEvaluacionCopiar("")
    }

    const cursoTipo = useWatch({
        name: `configuracion.evaluaciones.${index}.cursoTipo`
    })

    return (
        <Paper sx={{ mt: 3, mb: 2, p: 2 }}>
            <Grid container spacing={1} mb={3}>
                <Grid item sm={8} xs={8}>
                    <Typography variant="h5" mb={2}>
                        Configuración para {tipoEvaluacion.evaluacionTipo as string}
                    </Typography>
                </Grid>
                <Grid item sm={4} xs={4}>
                    <div style={{ display: "inline-flex" }}>
                        <CUISelect
                            label="Copiar configuración desde..."
                            sx={{ minWidth: "250px" }}
                            value={tipoEvaluacionCopiar}
                            onChange={e => setTipoEvaluacionCopiar(e.target.value as string)}
                        >
                            <MenuItem value={""}></MenuItem>
                            {evaluacionTipoConfigs.map(a => (
                                <MenuItem key={a.evaluacionTipo as string} value={a.evaluacionTipo as string}>
                                    {a.evaluacionTipo as string}
                                </MenuItem>
                            ))}
                        </CUISelect>
                        &nbsp;
                        <CUIButton
                            size="small"
                            onClick={() => onClickCopiarConfig(index)}
                            disabled={tipoEvaluacionCopiar == undefined}
                        >
                            Copiar
                        </CUIButton>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={2} mb={1}>
                <Grid item sm={12} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIText,
                            componentProps: {
                                fullWidth: true
                            },
                            name: `configuracion.evaluaciones.${index}.alias`,
                            label: `Alias`,
                            tooltip:
                                "Alias para mostrar en el sitio. Hoy no se usa en favor del alias de las evaluaciones tipo en nebulosa."
                        }}
                    />
                </Grid>
            </Grid>

            <Typography variant="h6" mb={2}>
                Clasificación tipos
            </Typography>
            <Grid container spacing={2} mb={2}>
                <Grid item sm={12} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIAutocomplete,
                            componentProps: {
                                multiple: true,
                                fullWidth: true,
                                groupBy: clasificacionTipo => clasificacionTipo[0].toUpperCase()
                            },
                            name: `configuracion.evaluaciones.${index}.clasificaciones.estadisticasEvolutivas`,
                            label: "Clasificaciones gráfico de desempeño estadísticas evolutivas",
                            options: clasificacionTipos.map(a => a.clasificacion_tipo),
                            tooltip: (
                                <>
                                    Para el gráfico de desempeño 'nuevo'. Se tiene que seleccionar una clasificación
                                    tipo padre con una hijo en orden. Por cada tipo de clasificación, se muestra un
                                    gráfico con las estadisticas de las clasificaciones de ese tipo.
                                    <ExampleImgDialog
                                        title="Gráfico de desempeño"
                                        img="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/clonador/imagenes-ejemplo/estadisticas.png"
                                        description="Por ejemplo, en la imagen se muestra un gráfico de desempeño por cada eje temático."
                                        darkMode={true}
                                    />
                                </>
                            )
                        }}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIAutocomplete,
                            componentProps: {
                                multiple: true,
                                fullWidth: true,
                                groupBy: clasificacionTipo => clasificacionTipo[0].toUpperCase()
                            },
                            name: `configuracion.evaluaciones.${index}.clasificaciones.desempenoEvaluacion`,
                            label: "Para desempeño por clasificacion al ver el resultado de una evaluación",
                            options: clasificacionTipos.map(a => a.clasificacion_tipo),
                            tooltip: (
                                <>
                                    Cuando se ve el resultado de una evaluación se muestra el desempeño por cada
                                    clasificacion de los tipos seleccionados. Se tiene que seleccionar una clasificación
                                    tipo padre con una hijo en orden.
                                    <ExampleImgDialog
                                        title="Desempeño por clasificación"
                                        img="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/clonador/imagenes-ejemplo/desempenoEvaluacion.png"
                                        description="Por ejemplo, en la imagen se muestra el desempeño en una evaluación desglosado por eje temático."
                                        darkMode={true}
                                    />
                                </>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <Box mt={3} mb={4}>
                <CUIFormInput
                    inputBuilder={{
                        component: CUIAutocomplete,
                        componentProps: {
                            multiple: true,
                            fullWidth: true,
                            groupBy: clasificacionTipo => clasificacionTipo[0].toUpperCase()
                        },
                        name: `configuracion.evaluaciones.${index}.clasificaciones.clasificacionTiposTablaEspecificaciones`,
                        label: "Clasificacion Tipos para tabla de especificaciones al imprimir una evaluación",
                        options: clasificacionTipos.map(a => a.clasificacion_tipo),
                        tooltip: (
                            <>
                                Cuando se imprime una evaluación se muestra una tabla con las clasificaciones de los
                                tipos seleccionados de cada pregunta.
                                <ExampleImgDialog
                                    title="Clasificaciones tabla de especificaciones"
                                    img="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/clonador/imagenes-ejemplo/objetivosImprimir.png"
                                    description='Por ejemplo, en la imagen se muestran las clasificaciones "habilidad cognitiva LGE" y "objetivo de aprendizaje" para cada pregunta, en la evaluación impresa para el docente.'
                                    darkMode={true}
                                />
                            </>
                        )
                    }}
                />
            </Box>
            <Box mt={3} mb={4}>
                <Typography variant="h6">Clasificaciones hijas y padres</Typography>
                <Alert color="info" sx={{ mb: 1 }}>
                    Estas clasificaciones se muestran debajo de su padre en la tabla de desempeño por clasificación en
                    las estadísticas
                    <ExampleImgDialog
                        title="Clasificaciones padres e hijas tabla desempeño"
                        img="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/clonador/imagenes-ejemplo/referencesTo.png"
                        description="Por ejemplo, se muestran en la fila superior de la tabla las clasificaciones padre, de tipo eje temático, y debajo de ellas las clasificaciones hijas de tipo sub eje temático"
                    />
                </Alert>
                <CUIButton mb={1} onClick={onClickAddReferencesTo}>
                    Agregar
                </CUIButton>
                <Grid container spacing={2} mt={2} mb={2}>
                    {referencesTo.map((referencesTo, indexReferencesTo) => {
                        return (
                            <Grid item container spacing={2} mb={2} key={referencesTo.id}>
                                <Grid item sm={5} xs={5}>
                                    <CUIFormInput
                                        inputBuilder={{
                                            component: CUIAutocomplete,
                                            componentProps: {
                                                fullWidth: true,
                                                groupBy: clasificacionTipo => clasificacionTipo[0].toUpperCase()
                                            },
                                            name: `configuracion.evaluaciones.${index}.clasificaciones.referencesTo[${indexReferencesTo}].key`,
                                            label: "Clasificacion Tipo Hija",
                                            options: clasificacionTipos.map(a => a.clasificacion_tipo)
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={5} xs={5}>
                                    <CUIFormInput
                                        inputBuilder={{
                                            component: CUIAutocomplete,
                                            componentProps: {
                                                fullWidth: true,
                                                groupBy: clasificacionTipo => clasificacionTipo[0].toUpperCase()
                                            },
                                            name: `configuracion.evaluaciones.${index}.clasificaciones.referencesTo[${indexReferencesTo}].value`,
                                            label: "Clasificacion Tipo Padre",
                                            options: clasificacionTipos.map(a => a.clasificacion_tipo)
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={2} xs={2}>
                                    <CUIButton onClick={() => onClickRemoveReferencesTo(indexReferencesTo)}>
                                        Quitar
                                    </CUIButton>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>

            <Grid container spacing={2} mb={3}>
                <Grid item sm={12} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIAutocomplete,
                            componentProps: {
                                multiple: true,
                                fullWidth: true,
                                groupBy: clasificacionTipo => clasificacionTipo[0].toUpperCase()
                            },
                            name: `configuracion.evaluaciones.${index}.clasificaciones.cambiarPregunta`,
                            label: "Clasificación tipos que se mantienen al cambiar pregunta",
                            options: clasificacionTipos.map(a => a.clasificacion_tipo),
                            tooltip: (
                                <>
                                    Cuando se cambia una pregunta de una evaluación a traves del sitio, se va a buscar
                                    una en donde se mantengan las clasificaciones de los tipos seleccionados.
                                    <ExampleImgDialog
                                        title="Botón cambiar pregunta"
                                        img="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/clonador/imagenes-ejemplo/cambiarPregunta.png"
                                        description="Por ejemplo, si tengo seleccionado eje temático y clickeo cambiar pregunta en este caso, podría traerme en su lugar otra pregunta con el mismo eje temático."
                                        darkMode={true}
                                    />
                                </>
                            )
                        }}
                    />
                </Grid>
                <Grid item sm={12} xs={12}></Grid>
                <Grid item sm={12} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIAutocomplete,
                            componentProps: {
                                fullWidth: true,
                                groupBy: clasificacionTipo => clasificacionTipo[0].toUpperCase()
                            },
                            name: `configuracion.evaluaciones.${index}.clasificacionMaterial.clasificacion`,
                            label: "Clasificacion tipo de las clasificaciones que se muestran al ver materiales",
                            options: clasificacionTipos.map(a => a.clasificacion_tipo),
                            tooltip: (
                                <>
                                    Al ver materiales, se muestra inicialmente un listado de clasificaciones del tipo
                                    padre seleccionado. Cuando se despliega alguna clasificación, se muestran los
                                    materiales de esa clasificación además de las clasificaciones hijas de ese padre
                                    (del tipo seleccionado). Se tiene que seleccionar una clasificación tipo padre con
                                    una hijo en orden. Solo se usa si la asignatura no lo tiene configurado en el sitio
                                    como 'Clasificación Material'.
                                    <ExampleImgDialog
                                        title="Clasificaciones mostradas en materiales"
                                        img="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/clonador/imagenes-ejemplo/Materiales.png"
                                        description="Por ejemplo, los materiales de la imagen se encuentran organizados en ejes temáticos que se pueden desplegar para ver los materiales"
                                        darkMode={true}
                                    />
                                </>
                            )
                        }}
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <CUIFormInput
                        inputBuilder={{
                            component: CUIText,
                            componentProps: {
                                fullWidth: true
                            },
                            name: `configuracion.evaluaciones.${index}.clasificacionMaterial.label`,
                            label: "Nombre a mostrar de la clasificacion tipo de las clasificaciones que se muestran al ver materiales",
                            tooltip: (
                                <>
                                    Se muestra el tipo de la clasificación padre como título al ver materiales. Solo se
                                    usa si la asignatura no lo tiene configurado en el sitio como 'Clasificación
                                    Material'.
                                    <ExampleImgDialog
                                        title="Nombre de clasificaciones mostradas en materiales"
                                        img="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/clonador/imagenes-ejemplo/Materiales.png"
                                        description='Por ejemplo, en la imagen se muestra "Material por eje", ya que el nombre mostrado es "eje".'
                                        darkMode={true}
                                    />
                                </>
                            )
                        }}
                    />
                </Grid>
            </Grid>

            <Divider />
            <Box mt={3} mb={4}>
                <Typography variant="h6">Planes de profesor</Typography>
                <Alert color="info" sx={{ mb: 1 }}>
                    Seleccione las clasificaciones tipos de las clasificaciones que se muestran al agregar materiales en
                    los planes de profesor. Si son 2, la segunda clasificación tipo tiene que ser hija de la primera.
                    <ExampleImgDialog
                        title="Clasificaciones al agregar materiales"
                        img="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/clonador/imagenes-ejemplo/subirMaterial.png"
                        description='Por ejemplo, en la imagen se debe seleccionar "eje temático" y "sub eje temático" al subir un material'
                    />
                </Alert>
                <CUIButton onClick={onClickAddPlanPersonalClasificacionMateriales}>Agregar</CUIButton>
                {planPersonalClasificacionMateriales.map(
                    (planPersonalClasificacionMaterial, indexPlanPersonalClasificacionMateriales) => (
                        <Grid container spacing={2} mt={2} mb={2} key={planPersonalClasificacionMaterial.id}>
                            <Grid item sm={5} xs={5}>
                                <CUIFormInput
                                    inputBuilder={{
                                        component: CUISelect,
                                        componentProps: {
                                            formControlProps: {
                                                fullWidth: true
                                            }
                                        },
                                        name: `configuracion.evaluaciones.${index}.planPersonalClasificacionMateriales[${indexPlanPersonalClasificacionMateriales}].clasificacion`,
                                        label: "Clasificacion tipo",
                                        options: clasificacionTipos.map(a => a.clasificacion_tipo)
                                    }}
                                />
                            </Grid>
                            <Grid item sm={5} xs={5}>
                                <CUIFormInput
                                    inputBuilder={{
                                        component: CUIText,
                                        componentProps: {
                                            fullWidth: true
                                        },
                                        name: `configuracion.evaluaciones.${index}.planPersonalClasificacionMateriales[${indexPlanPersonalClasificacionMateriales}].label`,
                                        label: "Nombre a mostrar"
                                    }}
                                />
                            </Grid>
                            <Grid item sm={2} xs={2}>
                                <CUIButton
                                    onClick={() =>
                                        onClickRemovePlanPersonalClasificacionMateriales(
                                            indexPlanPersonalClasificacionMateriales
                                        )
                                    }
                                >
                                    Quitar
                                </CUIButton>
                            </Grid>
                        </Grid>
                    )
                )}
            </Box>
            <Divider />
            <Box mt={3} mb={4}>
                <Typography variant="h6">Estadísticas</Typography>
                <Alert color="info" sx={{ mb: 1 }}>
                    Seleccione las clasificación tipo de los gráficos que se muestran en estadísticas
                    <ExampleImgDialog
                        title="Gráfico y tabla estadísticas"
                        img="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/clonador/imagenes-ejemplo/estadisticasGraficoCompuesto.png"
                        description="Por ejemplo, se muestran en la imagen un gráfico y tabla de estadísticas por eje temático. Si seleccionara además sub eje temático, a continuación se mostraría otro gráfico y otra tabla iguales, pero que separen las estadísticas según sub eje temático."
                    />
                </Alert>
                <CUIButton sx={{ mb: 2 }} onClick={onClickAddClasificacionTipoEstadisticas}>
                    Agregar
                </CUIButton>
                <Grid container spacing={2} mb={2}>
                    {clasificacionTiposEstadisticas.map(
                        (clasificacionTipoEstadisticas, indexClasificacionTipoEstadisticas) => (
                            <Grid key={clasificacionTipoEstadisticas.id} item sm={4} xs={6}>
                                <Paper sx={{ mb: 2, p: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} xs={12}>
                                            <CUIFormInput
                                                inputBuilder={{
                                                    component: CUISelect,
                                                    componentProps: {
                                                        formControlProps: {
                                                            fullWidth: true
                                                        }
                                                    },
                                                    name: `configuracion.evaluaciones.${index}.clasificacionTiposEstadisticas[${indexClasificacionTipoEstadisticas}].nombre`,
                                                    label: "Clasificacion tipo",
                                                    options: clasificacionTipos.map(a => a.clasificacion_tipo)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <CUIFormInput
                                                inputBuilder={{
                                                    component: CUICheckbox,
                                                    componentProps: {},
                                                    name: `configuracion.evaluaciones.${index}.clasificacionTiposEstadisticas[${indexClasificacionTipoEstadisticas}].compuesto`,
                                                    label: "Compuesto",
                                                    tooltip:
                                                        "Si es compuesto, se muestra el desempeño considerando correctas/incorrectas/omitidas, si no se consideran solo las correctas."
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <CUIFormInput
                                                inputBuilder={{
                                                    component: CUICheckbox,
                                                    componentProps: {},
                                                    name: `configuracion.evaluaciones.${index}.clasificacionTiposEstadisticas[${indexClasificacionTipoEstadisticas}].alumnos`,
                                                    label: "Se muestra en alumnos",
                                                    tooltip: "En estadísticas de alumnos."
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <CUIFormInput
                                                inputBuilder={{
                                                    component: CUICheckbox,
                                                    componentProps: {},
                                                    name: `configuracion.evaluaciones.${index}.clasificacionTiposEstadisticas[${indexClasificacionTipoEstadisticas}].profesores`,
                                                    label: "Se muestra en profesores",
                                                    tooltip: "En estadísticas de profesores."
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <CUIFormInput
                                                inputBuilder={{
                                                    component: CUICheckbox,
                                                    componentProps: {},
                                                    name: `configuracion.evaluaciones.${index}.clasificacionTiposEstadisticas[${indexClasificacionTipoEstadisticas}].informeAnual`,
                                                    label: "Se muestra en informe anual"
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <div style={{ textAlign: "right" }}>
                                                <CUIButton
                                                    onClick={() =>
                                                        onClickRemoveClasificacionTipoEstadisticas(
                                                            indexClasificacionTipoEstadisticas
                                                        )
                                                    }
                                                >
                                                    Quitar
                                                </CUIButton>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )
                    )}
                </Grid>
            </Box>
            <Divider />
            <Box mt={3} mb={4}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                    Cursos o niveles
                </Typography>
                <CUIFormInput
                    inputBuilder={{
                        component: CUISelect,
                        componentProps: {
                            formControlProps: {
                                fullWidth: true
                            }
                        },
                        name: `configuracion.evaluaciones.${index}.cursoTipo`,
                        label: "Clasificacion tipo que representa al curso / nivel.",
                        options: clasificacionTipos.map(a => a.clasificacion_tipo),
                        tooltip:
                            "Ej: Nivel, Curso, Curso México, etc. Se usa para ir a buscar las clasificaciones de este tipo en diversos lugares de la aplicación."
                    }}
                />
                <br />
                <Typography variant="h6" sx={{ mb: 1 }}>
                    Clasificaciones curso o nivel
                </Typography>
                <Alert color="info" sx={{ mb: 1, mt: 1 }}>
                    Seleccione las clasificaciones de cursos que se muestran en la plataforma
                </Alert>
                <CUIButton sx={{ mb: 2 }} onClick={onClickAddCurso}>
                    Agregar
                </CUIButton>
                <Grid container spacing={2} mb={2}>
                    {cursos.map((curso, indexCurso) => (
                        <Grid key={curso.id} item sm={4} xs={6}>
                            <Paper sx={{ p: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item sm={12} xs={12}>
                                        <CUIFormInput
                                            inputBuilder={{
                                                component: CUISelect,
                                                componentProps: {
                                                    formControlProps: {
                                                        fullWidth: true
                                                    }
                                                },
                                                name: `configuracion.evaluaciones.${index}.cursos[${indexCurso}].clasificacion`,
                                                label: "Clasificación",
                                                optionsParams: {
                                                    queryMethod: () =>
                                                        clasificacionesService
                                                            .whereWithoutCount({
                                                                clasificacion_tipo: {
                                                                    clasificacion_tipo: cursoTipo
                                                                }
                                                            })
                                                            .then(clasificaciones =>
                                                                clasificaciones.map(c => c.clasificacion)
                                                            ),
                                                    queryName: `clasificaciones-${cursoTipo}`
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <CUIFormInput
                                            inputBuilder={{
                                                component: CUIText,
                                                componentProps: {
                                                    style: {
                                                        width: "100%"
                                                    }
                                                },
                                                name: `configuracion.evaluaciones.${index}.cursos[${indexCurso}].label`,
                                                label: "Nombre mostrado"
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <Box textAlign="right">
                                            <CUIButton onClick={() => onClickRemoveCurso(indexCurso)}>Quitar</CUIButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Divider />
            <Box mt={3} mb={4}>
                <Typography sx={{ mb: 1 }} variant="h6">
                    Niveles para el informe final
                </Typography>
                <Alert color="info" sx={{ mb: 1, mt: 1 }}>
                    Sólo válido para plataformas chilenas con cursos.
                    <ExampleImgDialog
                        title="Cursos Informe Final"
                        img="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/clonador/imagenes-ejemplo/cursosInformeFinal.png"
                        description="Los cursos mostrados en el informe anual generado por el administrador"
                    />
                </Alert>
                <CUIButton sx={{ mb: 2 }} onClick={onClickAddCursoInformeFinal}>
                    Agregar
                </CUIButton>
                <Grid container spacing={2} mb={3}>
                    {nivelTipoInstrumentoInformeFinal.map((cursoInformeFinal, indexCursoInformeFinal) => (
                        <Grid key={cursoInformeFinal.id} item sm={4} xs={6}>
                            <Paper sx={{ p: 2 }}>
                                <CUIFormInput
                                    inputBuilder={{
                                        component: CUIText,
                                        componentProps: {
                                            fullWidth: true,
                                            sx: {
                                                mb: 1
                                            }
                                        },
                                        name: `configuracion.evaluaciones.${index}.nivelTipoInstrumentoInformeFinal[${indexCursoInformeFinal}]`,
                                        label: "Nombre curso",
                                        tooltip: "El nombre solo puede ser de la forma 'Nº Básico' o 'Nº Medio'"
                                    }}
                                />
                                <Box textAlign="right">
                                    <CUIButton onClick={() => onClickRemoveCursoInformeFinal(indexCursoInformeFinal)}>
                                        Quitar
                                    </CUIButton>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Divider />
            <Box mt={3} mb={4}>
                <Typography variant="h6">Estrellas para los planes de estudio</Typography>
                <Alert color="info" sx={{ mb: 1, mt: 1 }}>
                    Sólo válido para plataformas que tengan planes de estudio antiguos (no planes de clase).
                </Alert>
                <CUITable>
                    <TableHead>
                        <TableRow>
                            <TableCell>Cantidad estrellas</TableCell>
                            <TableCell>Calificación mínima</TableCell>
                            <TableCell>Calificación máxima</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {estrellas.map((estrella, indexEstrellas) => (
                            <TableRow key={estrella.id}>
                                <TableCell align="center">
                                    {Array.from(Array(Math.floor((indexEstrellas + 1) / 2)).keys()).map((i, j) => (
                                        <StarIcon key={j} />
                                    ))}
                                    {(indexEstrellas + 1) % 2 ? <StarHalfIcon /> : null}
                                    {Array.from(Array(Math.floor((5 - indexEstrellas) / 2)).keys()).map((i, j) => (
                                        <StarOutlineIcon key={j} />
                                    ))}
                                </TableCell>
                                <TableCell align="right">
                                    <CUIFormInput
                                        inputBuilder={{
                                            component: CUIText,
                                            componentProps: {
                                                type: "number",
                                                style: {
                                                    width: "100%"
                                                }
                                            },
                                            name: `configuracion.evaluaciones.${index}.estrellas[${indexEstrellas}].min`,
                                            label: "Calificación mínima"
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <CUIFormInput
                                        inputBuilder={{
                                            component: CUIText,
                                            componentProps: {
                                                type: "number",
                                                style: {
                                                    width: "100%"
                                                }
                                            },
                                            name: `configuracion.evaluaciones.${index}.estrellas[${indexEstrellas}].max`,
                                            label: "Calificación mínima"
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </CUITable>
            </Box>
            <Divider />
            <Box mt={3} mb={4}>
                <Grid container spacing={2} mb={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Calificación del tipo de evaluación</Typography>
                        <Alert color="info" sx={{ mb: 1, mt: 1 }}>
                            Para el gráfico antiguo de desempeño, el gráfico de comparación de puntajes por curso y para
                            que el profesor pueda poner una calificación manual dentro del rango especificado.
                        </Alert>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "number",
                                    style: {
                                        width: "100%"
                                    }
                                },
                                name: `configuracion.evaluaciones.${index}.rangoCalificacion.min`,
                                label: "Calificación mínima"
                            }}
                        />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "number",
                                    style: {
                                        width: "100%"
                                    }
                                },
                                name: `configuracion.evaluaciones.${index}.rangoCalificacion.tickInterval`,
                                label: "Incremento (para mostrar en gráficos)"
                            }}
                        />
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    type: "number",
                                    style: {
                                        width: "100%"
                                    }
                                },
                                name: `configuracion.evaluaciones.${index}.rangoCalificacion.max`,
                                label: "Calificación máxima"
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box mt={3} mb={4}>
                <Grid container spacing={2} mb={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Otras configuraciones</Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    style: {
                                        width: "100%"
                                    }
                                },
                                name: `configuracion.evaluaciones.${index}.streamingChannelAlias`,
                                label: "Canal streaming (alias BD)",
                                tooltip: "Alias de la base de datos para el canal de streaming"
                            }}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    style: {
                                        width: "100%"
                                    }
                                },
                                name: `configuracion.evaluaciones.${index}.baseUrl`,
                                label: "URL base tipo evaluación",
                                tooltip:
                                    "Parte inicial de la ruta para hacer cosas con este tipo de evaluación. Debe partir con '/'. Ej: /ensayos. Si no se especifica, se usará el nombre del tipo de evaluación."
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box mt={3} mb={4}>
                <Grid container spacing={2} mb={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Configuraciones antiguas</Typography>
                        <Alert color="info" sx={{ mb: 1, mt: 1 }}>
                            Las configuraciones mostradas en esta sección solamente tienen efecto para clones que no estan en el monorepo o en un sprint muy antiguo.
                        </Alert>
                    </Grid>
                    <Grid item sm={3} xs={3}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: `configuracion.evaluaciones.${index}.cantidadPreguntas`,
                                label: `Cantidad alternativas`,
                                tooltip:
                                    "Cantidad de alternativas máximas que muestra el sistema para cada pregunta de este tipo de evaluación. Hoy no se usa en favor de la cantidad máxima de alternativas por generador instrumento, configurable en nebulosa."
                            }}
                        />
                    </Grid>
                    <Grid item sm={9} xs={9}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUISelect,
                                componentProps: {
                                    multiple: true,
                                    formControlProps: {
                                        fullWidth: true
                                    }
                                },
                                name: `configuracion.evaluaciones.${index}.clasificaciones.estadisticas`,
                                label: "Clasificaciones gráfico desempeño antiguo estadísticas",
                                options: clasificacionTipos.map(a => a.clasificacion_tipo),
                                tooltip:
                                    "Para el gráfico de desempeño antiguo. Por cada tipo de clasificación, se muestra un gráfico con las estadisticas de las clasificaciones de ese tipo. El gráfico antiguo no se usa en clones modernos."
                            }}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <CUIFormInput
                            inputBuilder={{
                                component: CUIText,
                                componentProps: {
                                    fullWidth: true
                                },
                                name: `configuracion.evaluaciones.${index}.clasificacionDistribucion`,
                                label: "Texto que se muestra cuando se ve el gráfico mi progreso del alumno antiguo, con respecto a la clasificación que ve estadísticas",
                                tooltip: "El gráfico mi progreso antiguo no se usa en clones modernos."
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
})
